import React, { useState } from 'react'
import DeleteDialog from 'components/common/delete.dialog/delete.dialog';
import { Typography } from '@mui/material';

const ShoppingCartHeader = (props) => {
    const { cartProducts } = props
    const [state, setstate] = useState({
        isDeleteCartDialogOpen: false
    })
    const hideDeleteDialog = () => {
        setstate({ ...state, isDeleteCartDialogOpen: false })
    }
    const showDeleteCartDialog = () => {
        setstate({ ...state, isDeleteCartDialogOpen: true })
    }
    const onDelete = (e) => {
        props.deleteAllCartProducts();
        e.stopPropagation()
        hideDeleteDialog();
    }
    return (
        <div>
            <div className='pb-2 pt-2 d-flex justify-content-between'>
                {/* <Typography className=" h-100 mr-2">
                            <div className="h-100 w-100 ">
                                <img className={`${classes.imgSize} rounded-circle`} src={`${SERVER_BASE_URL}/${cartProducts?.get('data')?.get('pictures')?.get(0)?.get('image_original_url')}`} />
                            </div>
                        </Typography> */}
                <Typography variant='body2' className='font-weight-bold'>
                    {cartProducts && cartProducts.get(0) && cartProducts.get(0).get('data') && cartProducts.get(0).get('data') && cartProducts.get(0).get('data').get('merchant_name') && cartProducts.get(0).get('data').get('merchant_name')}
                </Typography>
                <Typography variant='body3' className='cursor-pointer' onClick={showDeleteCartDialog}>
                    Clear all
                </Typography>
            </div>
            {
                state.isDeleteCartDialogOpen ?
                    <DeleteDialog open={state.isDeleteCartDialogOpen}
                        // alertMessage="Clear cart, are you sure you want to clear cart ?"
                        buttonText='Clear Cart'
                        onDelete={onDelete}
                        title="Are you sure you want to clear cart?"
                        onClose={hideDeleteDialog}>
                        If you clear cart, all items will be deleted from your cart.
                    </DeleteDialog> : null
            }
        </div>
    )
}

export default ShoppingCartHeader