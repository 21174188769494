export const ENV = "PROD"; // DEV , QA , UAT, PROD
const DEV_BASE_URL = 'https://zdev.cloudasset.com';
const DEV_URL = "https://zdev.cloudasset.com/api/p3_mwallet/v1";
const QA_BASE_URL = 'https://zqa.cloudasset.com';
const QA_URL = "https://zqa.cloudasset.com/api/p3_mwallet/v1";
const PROD_BASE_URL = 'https://m3.zapp.world';
const PROD_URL = "https://m3.zapp.world/api/p3_mwallet/v1";
const UAT_BASE_URL = 'https://m3uat.zapp.world';
const UAT_URL = "https://m3uat.zapp.world/api/p3_mwallet/v1";

const getServerUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return DEV_URL;
        case "QA":
            return QA_URL;
        case "UAT":
            return UAT_URL;
        case "PROD":
            return PROD_URL;
    }
}
const getBaseUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return DEV_BASE_URL;
        case "QA":
            return QA_BASE_URL;
        case "UAT":
            return UAT_BASE_URL;
        case "PROD":
            return PROD_BASE_URL;
    }
}

export const SERVER_URL = getServerUrl(ENV);
export const SERVER_BASE_URL = getBaseUrl(ENV);
export const SERVER_PORT = "8082";
export const GOOGLE_MAPS_API_KEY  = "AIzaSyBBILEr28gGiV7o6k7w0YBoNUXasm9ZRM0"// IT IS OF ZAPP
