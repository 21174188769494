import React from 'react'
import { Typography } from '@mui/material';
const ServiceBox = (props) => {
    const { icon, title, description } = props

    return (
        <div className="d-flex justify-content-center align-items-center w-100 p-2">
            <Typography className="w-15 h-100 material-icons p-2">
                {icon ? icon : ""}
            </Typography>
            <div className="w-85">
                <Typography variant="body2" component="div" className="text-ellipsis-clamp-1 text-capitalize font-weight-bold">
                    {title ? title : ""}
                </Typography>
                <Typography variant="body2" component="div" className="text-ellipsis-clamp-1 font-weight-light">
                    {description ? description : ""}
                </Typography>
            </div>
        </div>
    )
}

export default ServiceBox
