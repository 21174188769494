import './app.navbar.v2.scss';
import React from "react";
import { useStyle } from "./app.navbar.v2.style";
import { AppBar, Toolbar, Typography, Container, Button } from "@mui/material";
import clsx from 'clsx';
import { IsMobileWidth, scrollToTop } from 'components/common/util/util';
import { useLocation } from "react-router";

const AppbarDesktopV2 = (props) => {  //PRE_LOGIN_NAVBAR
  const classes = useStyle()
  const mobileWidth = IsMobileWidth()
  const redirect = (url) => {
    props.push(url);
    scrollToTop()
  }
  let location = useLocation();
  const isActive = (url) => (
    location.pathname === url ? true : false
  )

  return (
    <AppBar id='app-navbar' position="sticky" classes={{ root: classes.appNavbar }} className='m-auto'>
      <Container className={clsx(classes.container, 'p-0 position-relative')}>
        <Toolbar className={clsx(!mobileWidth && "d-flex flex-row justify-content-between align-items-center m-auto", mobileWidth && 'd-none')}>
          <div onClick={() => redirect("/home")} className="cursor-pointer mr-5 pr-5 pt-2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/zapp.web.logo.svg`}
              height='40px'
              width='75px'
            />
          </div>
          <Button
            classes={{ root: classes.btn }}
            variant="none"
            onClick={() => redirect("/about-us")}
            className={isActive('/about-us') ? 'active' : 'in-active'}
          >
            About us
          </Button>

          <Button
            classes={{ root: classes.btn }}
            variant="none"
            onClick={() => redirect("/riders")}
            className={isActive('/riders') ? 'active' : 'in-active'}
          >
            Riders
          </Button>
          <Button
            classes={{ root: classes.btn }}
            variant="none"
            onClick={() => redirect("/merchants")}
            className={isActive('/merchants') ? 'active' : 'in-active'}
          >
            Merchant partners
          </Button>
          <Button
            classes={{ root: classes.btn }}
            variant="none"
            onClick={() => redirect("/careers")}
            className={isActive('/careers') ? 'active' : 'in-active'}
          >
            Careers
          </Button>
          {/* <Button
            classes={{ root: classes.btn }}
            variant="none"
            onClick={() => redirect("/news")}
            className={isActive('/news') ? 'active' : 'in-active'}
          >
            News
          </Button> */}
          <Button
            classes={{ root: classes.btn2 }}
            variant="contained"
            onClick={props.showLoginPageDialog}
          >
            <Typography className="d-flex" classes={{ root: classes.btnText }}>
              <img
                src={process.env.PUBLIC_URL + '/assets/images/user.svg'}
                height='20px'
                width='20px'
              />
              <span className="pl-1">Sign in</span>
            </Typography>
          </Button>
        </Toolbar>
      </Container>
    </AppBar >
  );
};
export default AppbarDesktopV2;