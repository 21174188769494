import "./best.seller.carousel.scss";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  FreeMode,
  Pagination,
  Navigation,
  Mousewheel,
  Keyboard,
} from "swiper";
import BestSeller from "../best.seller/best.seller";

SwiperCore.use([Pagination, Navigation, Mousewheel, Keyboard]);

export const SwiperCarousel = (props) => {
  return (
    <div className="w-100 p-4" id="best-seller-carousel">
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        mousewheel={true}
        navigation={true}
        keyboard={true}
        freeMode={true}
        className="swiper w-100 h-100"
      >
        <SwiperSlide className="swiper-slide h-100">
          <BestSeller image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide h-100">
          <BestSeller image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide h-100">
          <BestSeller image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide h-100">
          <BestSeller image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide h-100">
          <BestSeller image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" />
        </SwiperSlide>
        <SwiperSlide className="swiper-slide">
          <BestSeller image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default SwiperCarousel;
