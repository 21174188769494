import { Paper, Typography } from "@mui/material";
import React from "react";
import { useStyle } from './checkout.delivery.time.style'
import { getDeliveryTime } from 'components/common/util/util';

const CheckoutDeliveryTime = (props) => {
  const { checkOutInvoiceState } = props;
  const deliveryTime = checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('merchant') && checkOutInvoiceState?.get('invoice')?.get('merchant')?.get('delivery_time');
  const classes = useStyle()
  return (
    <Paper elevation={0} className="d-flex justify-content-between w-100 flex-wrap p-2">
      <Typography classes={{ root: classes.text }} className="font-weight-bold">Delivery Time</Typography>
      <Typography classes={{ root: classes.text }}>{
        deliveryTime ?
          getDeliveryTime(deliveryTime?.get('day'), deliveryTime?.get('hour'), deliveryTime?.get('minute')) :
          null
      }</Typography>
    </Paper>
  );
};

export default CheckoutDeliveryTime;
