//VERSION_CHANGES
import { readTextFile } from "view.updater/middlewares/util/readTextFile";
import { isVersionDifferent } from 'components/common/util/util';

export const cacheClear = () => {
    readTextFile(
        `${process.env.PUBLIC_URL}/meta.json?nocache=${(new Date()).getTime()}`,
        (metaVersionFile) => {
            let metaFile = JSON.parse(metaVersionFile);
            const latestVersion = metaFile.version;
            if (window.localStorage.getItem('appVersion') || window.localStorage.getItem('visitorId')) {
                const currentVersion = window.localStorage.getItem('appVersion') || "0.0";
                const shouldForceRefresh = isVersionDifferent(latestVersion, currentVersion);
                window.localStorage.setItem('appVersion', latestVersion)
                if (shouldForceRefresh) {
                    window.sessionStorage.setItem('appVersion', latestVersion)
                    if (caches) {
                        // Service worker cache should be cleared with caches.delete()
                        caches.keys().then(function (names) {
                            for (let name of names) caches.delete(name);
                        });
                    }
                    // delete browser cache and hard reload
                    window.location.reload();
                } else {
                    const currentVersionSession = window.sessionStorage.getItem('appVersion') || "0.0";
                    const shouldForceRefreshSession = isVersionDifferent(latestVersion, currentVersionSession);
                    window.sessionStorage.setItem('appVersion', latestVersion)
                    if (shouldForceRefreshSession) {
                        if (caches) {
                            // Service worker cache should be cleared with caches.delete()
                            caches.keys().then(function (names) {
                                for (let name of names) caches.delete(name);
                            });
                        }
                        // delete browser cache and hard reload
                        window.location.reload();
                    } 
                }
            }
            else {
                window.localStorage.setItem('appVersion', latestVersion)
                window.sessionStorage.setItem('appVersion', latestVersion)
            }
        }
    );
}
export const isNeedToUpdateCache = (latestVersion) => {
    if (window.localStorage.getItem('appVersion') || window.localStorage.getItem('visitorId')) {
        const currentVersion = window.localStorage.getItem('appVersion') || "0.0";
        const shouldForceRefresh = isVersionDifferent(latestVersion, currentVersion);
        if (shouldForceRefresh) {
            return true
        } else {
            const currentVersionSession = window.sessionStorage.getItem('appVersion') || "0.0";
            const shouldForceRefreshSession = isVersionDifferent(latestVersion, currentVersionSession);
            if (shouldForceRefreshSession) {
               return true;
            }
            else {
                return false
            }
        }
    }
    else {
        return false
    }
}
