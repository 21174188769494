import { fromJS } from "immutable";
import { getActionName } from "./util";
import { FORGOT_PASSWORD, RESET_FORGOT_PASSWORD } from "../actions/forgot.password.actions";

const initialState = fromJS({
  forgotPasswordActiveStep: 1,
  forgotPasswordResp: {
    userId: ''
  }
});

function forgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FORGOT_PASSWORD:
      state = state.set('forgotPasswordActiveStep', 1);
      state = state.set('forgotPasswordResp', fromJS({
        userId: ''
      }))
      return state;
    case getActionName(FORGOT_PASSWORD):
      state = state.set('forgotPasswordActiveStep', state.get('forgotPasswordActiveStep') + 1);
      state = state.update('forgotPasswordResp', (user) => {
        return user.merge({
          ...action.response,
          ...action.payload
        })
      })
      return state;
    default:
      return state;
  }
}

export default forgotPasswordReducer;