import React, { useState } from 'react'
import { Tooltip, IconButton, Badge, } from "@mui/material";
//import { useStyle } from './checkout.basket.v2.style';
import { formatAmountV1 } from 'components/common/util/util';
import DeleteDialog from 'components/common/delete.dialog/delete.dialog';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';

const CheckoutBasketV2 = (props) => {
    //const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const { label, cartProducts } = props// count
    const [state, setstate] = useState({
        isDeleteCartDialogOpen: false
    })
    let totalQuantity = 0;
    for (let cartProd of cartProducts) {
        totalQuantity = totalQuantity + cartProd.get('data')?.get('quantity')
    }
    // const onClearCart = (e) => {
    //     props.deleteAllCartProducts();
    //     e.stopPropagation()
    // }
    const onClose = () => {
        setstate({ ...state, isDeleteCartDialogOpen: false })
    }
    // const showDeleteCartDialog = () => {
    //     setstate({ ...state, isDeleteCartDialogOpen: true })
    // }
    const onDelete = (e) => {
        props.deleteAllCartProducts();
        e.stopPropagation()
        onClose();
    }
    const onCheckOt = () => {
        if (cartProducts?.size > 0) {
            props.onCheckOut()
        }
    }
    const getTooltipTitle = () => {
        if (cartProducts?.size > 0 && label) {
            return `Total RS ${formatAmountV1(label)}`
        }
        else {
            return 'Cart is empty!'
        }
    }
    return (
        <div>
            <Tooltip title={getTooltipTitle()}
                placement="left-start" >
                <IconButton onClick={onCheckOt} className="mr-2" size="medium">
                    <Badge badgeContent={totalQuantity} color="secondary">
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/cart.png`}
                            height={clsx(!(mobileWidth || tabletWidth) && '32px', (mobileWidth || tabletWidth) && '24px')}
                            width={clsx(!(mobileWidth || tabletWidth) && '32px', (mobileWidth || tabletWidth) && '24px')}
                            alt='basket_image'
                        />
                    </Badge>
                </IconButton>
            </Tooltip>
            {state.isDeleteCartDialogOpen ?
                <DeleteDialog open={state.isDeleteCartDialogOpen}
                    // alertMessage="Clear cart, are you sure you want to clear cart ?"
                    buttonText='Clear Cart'
                    onDelete={onDelete}
                    title="Are you sure you want to clear cart?"
                    onClose={onClose}>
                    If you clear cart, all items will be deleted from your cart.
                </DeleteDialog> : null}
        </div>
    )
}

export default CheckoutBasketV2