import  React from 'react';
import { Box, Grid } from '@mui/material';
import SaleItemBox from 'components/common/sale.item.box/sale.item.box';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx'

const SaleItemBoxTemplateGrid1 = () => {
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    return (
        <Box className='h-100'>
            <Grid container className="h-100 p-1" >
                <Grid item lg={6} md={4} sm={12} xs={12} className={clsx(!(mobileWidth || tabletWidth) && "pb-2 pl-2", mobileWidth && ' pb-2 pl-2 h-50',tabletWidth && 'h-50 pb-2 pl-2')}>
                    <SaleItemBox
                        tag="new" title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale"
                    />
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12} className="h-100">
                    <Grid item lg={12} md={12} sm={12} className="h-50" >
                        <div className="h-100 pl-2 pb-2">
                            <SaleItemBox
                                title="mangolia Bag" image="https://media.istockphoto.com/photos/yellow-open-backpack-picture-id1141208525?k=20&m=1141208525&s=612x612&w=0&h=cOu7gNKtDi4FuGAzSDvn-Bm9PbB8tvWVgi7xY9lhcRw=" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} className="h-50" >
                        <div className="h-100 pl-2 pb-2">
                            <SaleItemBox
                                tag="Sale 20%" title="mangolia Bag" image="https://s18670.pcdn.co/wp-content/uploads/teacher-bags-taygeer.jpg" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale"
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid item lg={3} md={4} sm={6} xs={12} className="h-100 " >
                    <Grid item lg={12} md={12} sm={12} className="h-50" >
                        <div className="h-100 pl-2 pb-2">
                            <SaleItemBox
                                tag="sale 50%" title="mangolia Bag" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIH_8CS_NAeP2PjHmDsOUGm5PMN_s047bo8J3oxZZ5_UN76c0YjqvwyIKYe_cST9fBfl0&usqp=CAU" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} className="h-50" >
                        <div className="h-100 pl-2 pb-2">
                            <SaleItemBox
                                tag="new" title="mangolia Bag" image="https://images.modeherz.de/item/images/232741/full/COCCINELLE-Suede-Shoulder-Bag-New-Taupe-232741.jpg" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale"
                            />
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </Box >
    );
}
export default SaleItemBoxTemplateGrid1;