import React from "react";
import { Typography } from "@mui/material";
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import { formatAmountV1 } from 'components/common/util/util';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';

const AddVoucherDialogStep2 = (props) => {
    const tabletWidth = IsTabletWidth()
    const mobileWidth = IsMobileWidth()
    const { voucherInfo } = props
    return (
        <div className="h-100">
            <div className={clsx(!mobileWidth &&
                "m-auto w-100 d-flex justify-content-center align-items-center",
                mobileWidth && "w-100 d-flex justify-content-center align-items-center")}>
                <div className="mt-3 mb-3">
                    <div className="d-flex justify-content-center">
                        <img src={process.env.PUBLIC_URL + '/assets/images/voucher.applied.png'} width='150px' />
                    </div>
                    <Typography variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && "body1")} className="p-2 text-center">
                        Discount Amount
                    </Typography>
                    <Typography color='secondary' variant={clsx(!(mobileWidth || tabletWidth) && "h5", (mobileWidth || tabletWidth) && "body1")} className="p-2 text-center">
                        {
                            voucherInfo && voucherInfo?.get('voucher')?.get('discount_amount') ?
                                `PKR ${formatAmountV1(voucherInfo?.get('voucher')?.get('discount_amount'))}` :
                                null
                        }
                    </Typography>
                </div>
            </div>
        </div>
    )
};

export default AddVoucherDialogStep2;