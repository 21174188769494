import React from 'react'
import { Typography, Paper, IconButton, Box, CardMedia } from '@mui/material';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx'
import SimpleNavigationBoxCarousel from 'components/common/simple.navigation.box.carousel/simple.navigation.box.carousel';
import { withRouter } from "react-router";

const CategoryPageCarousel = (props) => {
    const { heading, image, titleHeading, title, bannerHeight, textColor } = props
    const mobileWidth = IsMobileWidth()
    const goBack = () => {
        props.history.goBack();
    }
    return (
        <div className="w-100 mt-2">
            <Box className='d-flex justify-content-between align-items-center mb-2 paper-root rounded' bgcolor="lightBackgroundColor.main">
                <IconButton className='d-flex material-icons' onClick={goBack}>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow_back_black.svg`} width='24px' />
                </IconButton>
                <Typography variant={clsx(!mobileWidth && "h6", mobileWidth && "h6")} component="div" className="text-ellipsis-clamp-1 font-weight-bold text-uppercase h-auto">
                    {heading ? heading : ""}
                </Typography>
                <IconButton className='material-icons'>
                    favorite_border
                </IconButton>
            </Box>
            <Paper
                elevation={0}
                className="w-100 m-auto d-flex"
                style={{ height: bannerHeight ? bannerHeight : "", }}
            >
                <div className={clsx(!mobileWidth && "w-70 h-100", mobileWidth && "w-60 p-1")} style={{ color: textColor ? textColor : "" }}>
                    <div className={clsx(!mobileWidth && "h-50 w-80 d-flex align-items-center pl-4", mobileWidth && "h-50 w-100 d-flex align-items-center pl-2")}>
                        <div>
                            <Typography variant={clsx(!mobileWidth && "h6", mobileWidth && "h6")} component="div" className="text-ellipsis-clamp-1 font-weight-bold text-uppercase h-auto">
                                {titleHeading ? titleHeading : ""}
                            </Typography>
                            <Typography variant={clsx(!mobileWidth && "h3", mobileWidth && "h4")} component="div" className="text-ellipsis-clamp-3 font-weight-bolder text-capitalize">
                                {title ? title : ""}
                            </Typography>
                        </div>
                    </div>
                    <div className="h-50 w-95 p-1">
                        <SimpleNavigationBoxCarousel showArrows="true" />
                    </div>
                </div>
                <div className={clsx(!mobileWidth && 'h-100 w-30 p-2', mobileWidth && 'h-100 w-40 p-1')}>
                    <CardMedia
                        className='h-100'
                        component="img"
                        image={image}
                        alt="zapp-app-logo"
                    />
                </div>
            </Paper>

        </div >
    )
}

export default (withRouter(CategoryPageCarousel));
