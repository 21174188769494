// The initial state of the App
import { fromJS } from 'immutable';
import { getActionName } from './util';
import {
    LOGIN, RESET_AUTH, LOGOUT, UPDATE_ADDRESS, UPDATE_USER_AUTH_DATA, CREATE_ADDRESS,
    RESET_CREATE_ADDRESS, RESET_DELETE_ADDRESS, DELETE_ADDRESS
} from '../actions/auth.actions';

const initialState = fromJS({
    auth: fromJS(JSON.parse(window.localStorage.getItem('auth'))) || {},
    addressUpdated: false,
    addressDeleted: false
});
const getSelectedAddressId = (response, payload) => {
    if (payload?.address?.type === "work") {
        return response?.work_address?.id
    }
    else if (payload?.address?.type === "home") {
        return response?.home_address?.id
    }
    else {
        return response?.other_addresses[0]?.id
    }
}
function authReducer(state = initialState, action) {
    switch (action.type) {
        case getActionName(LOGIN):
            window.localStorage.setItem("auth", JSON.stringify(action.response));
            window.localStorage.setItem("loginTime", new Date());
            return state.set('auth', fromJS(action.response));
        case LOGOUT:
            window.localStorage.removeItem("auth");
            window.localStorage.removeItem("loginTime");
            return state.set('auth', '');
        case RESET_AUTH:
            window.localStorage.removeItem("auth");
            return state.set('auth', '');
        case getActionName(CREATE_ADDRESS):
            state = state.updateIn(['auth', 'user'], (au) => {
                return au.merge(fromJS({ ...action.response }))
            })
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ selectedAddressId: getSelectedAddressId(action.response, action.payload) }))
            })
            state = state.set('addressUpdated', true)
            return state;
        case getActionName(UPDATE_ADDRESS):
            state = state.updateIn(['auth', 'user'], (au) => {
                return au.merge(fromJS({ ...action.response }))
            })
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ selectedAddressId: getSelectedAddressId(action.response, action.payload) }))
            })
            state = state.set('addressUpdated', true)
            return state;
        case getActionName(DELETE_ADDRESS):
            if (action.payload.addressDetails.type === "others") {
                state = state.updateIn(['auth', 'user', 'other_addresses'], (items) => {
                    return items.filterNot((item) => item.get('query_id') === action.payload.query_id)
                })
            }
            else if (action.payload.addressDetails.type === "home") {
                state = state.updateIn(['auth', 'user', 'home_address'], (au) => {
                    return fromJS({})
                })
            }
            else if (action.payload.addressDetails.type === "work") {
                state = state.updateIn(['auth', 'user', 'work_address'], (au) => {
                    return fromJS({})
                })
            }
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ selectedAddressId: action?.payload?.addressDetails?.id == au.get('selectedAddressId') ? '' : au.get('selectedAddressId') }))
            })
            state = state.set('addressDeleted', true)
            return state;
        case UPDATE_USER_AUTH_DATA:
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ ...action.payload }))
            })
            const newAuthData = { ...(state?.get("auth")?.toJS()), ...action.payload }
            window.localStorage.setItem("auth", JSON.stringify(newAuthData));
            return state
        case RESET_CREATE_ADDRESS:
            state = state.set('addressUpdated', false)
            return state;
        case RESET_DELETE_ADDRESS:
            state = state.set('addressDeleted', false)
            return state;
        default:
            return state;
    }
}
export default authReducer;
