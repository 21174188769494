import React, { useEffect } from "react";
import { IsMobileWidth } from "components/common/util/util";
import clsx from "clsx";
import PlaceOrderSuccessAlert from "./place.order.success.alert";
import PlaceOrderSuccessDetails from "./place.order.success.details";

const PlaceOrderSuccess = (props) => {
    const mobileWidth = IsMobileWidth();
    useEffect(() => {
        return () => {
            props.resetDownloadInvoiceReceipt();
        }
    }, [])
    return (
        <div>
            <div
                className={clsx(
                    !mobileWidth && "w-100 d-flex flex-row",
                    mobileWidth && "d-block w-100"
                )}>
                {/* <PlaceOrderSuccessDetails {...props} /> */}
                <PlaceOrderSuccessAlert {...props} />
            </div>
        </div>
    );
};

export default PlaceOrderSuccess;
