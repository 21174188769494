import './quickdeals.scss';
import React from 'react';
import Card from '@mui/material/Card';
import { CardContent, Paper, CardMedia, Typography } from '@mui/material';
import { useStyle } from './quickdeals.style';
import CountDownTimer from '../count.down.timer/count.down.timer';
const QuickDeal = (props) => {
    const classes = useStyle()
    const { discountTag, image, brandName, brandImageTag, brandNameTag, imageTagHeight, imageTagWidth, tagColor } = props

    return (
        <div
            className="h-100"
            id="quick-deals"
        >
            <Card className="w-100 h-100"
                classes={{ root: classes.card_style }}
            >
                <Paper elevation={1} square={true} className="h-90"
                    classes={{ root: classes.box_style }}
                >
                    <div className="position-absolute tag-style m-3">
                        {
                            brandImageTag ?
                                <img src={brandImageTag} height={imageTagHeight ? imageTagHeight : "100%"} width={imageTagWidth ? imageTagWidth : "100%"} />
                                :
                                brandNameTag ?
                                    <h6 style={{ color: tagColor ? tagColor : "#000" }} className="text-uppercase text-ellipsis-clamp-1">{brandNameTag}</h6>
                                    :
                                    null
                        }
                    </div>
                    <CardMedia
                        component="img"
                        image={image ? image : ""}
                        alt="items"
                        className="h-60 image-curve"
                    />
                    <CardContent className="h-40 content-style">
                        <div className="d-flex justify-content-center h-75">
                            <Typography gutterBottom variant="h2" component="div" className="font-weight-bold text-ellipsis-clamp-1 h-100">
                                {discountTag ? discountTag : ""}
                            </Typography>
                            {
                                discountTag &&
                                <Typography
                                    className="mt-1 h-75">
                                    <h2 className="h-50 font-weight-bold ">%</h2>
                                    <h5 className="h-50 font-weight-bold">off</h5>
                                </Typography>
                            }
                        </div>
                        <Typography variant="body2" color="text.secondary" className="text-center">
                            <CountDownTimer secondsRemaining="172800" />
                        </Typography>
                    </CardContent>
                </Paper>
                <Paper className="h-10" >
                    <div className="h-100 text-center p-2">
                        <Typography gutterBottom variant="subtitle1" component="div" className="text-capitalize text-ellipsis-clamp-1 content-style">
                            {brandName}
                        </Typography>
                    </div>
                </Paper>
            </Card>
        </div>
    )
}

export default QuickDeal
