import './quick.deals.carousel.scss';
import React from 'react'
import Slider from "react-slick";
import IconButton from '@mui/material/IconButton'
import QuickDeals from 'components/common/quick.deals/quick.deal'
const QuickdealsCarousel = () => {


    function NextArrow(props) {
        const { className, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-n2 carousel4">
                    <i className="fa fa-arrow-right p-1" id="left_arrow_carousel4"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-3 carousel4" >
                    <i className='fa fa-arrow-left p-1' id="left_arrow_carousel4"></i>
                </IconButton>
            </div>
        );
    }
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div
            className="h-100 p-1 w-100"
            id="quick-deals-carousel"
        >

            <Slider className="h-100 w-95 m-auto" {...settings}>
                <div>
                    <div className="p-3 carousel4-items">
                        <QuickDeals brandName="Manolia" discountTag="25" image="https://images.unsplash.com/photo-1607345366928-199ea26cfe3e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2hpcnR8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80" brandNameTag="mangolia" tagColor="#fff" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel4-items" >
                        <QuickDeals brandName="zara men" discountTag="50" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt4jjqg03-7zHCHx40vLxgDnWxgtDr-3ijdzhuDPMtKvwT5QcPj0QBXp7IXVNMmrv8TnI&usqp=CAU" brandImageTag="https://upload.wikimedia.org/wikipedia/commons/0/08/Magnolia_dairy_logo.png" imageTagWidth="60px" imageTagHeight="30px" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel4-items" >
                        <QuickDeals brandName="j." discountTag="10" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLDvaDdeCTbSSlKCODgg5Fgs7jvYEg17VERA&usqp=CAU" brandImageTag="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSi11ubymGeXBWwuSQSaJ_I9n2Yj7YebF0KvrUZq1kVWCbesu8s0x06EtZ61KCNRKk9iA&usqp=CAU" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel4-items" >
                        <QuickDeals brandName="khaadi" discountTag="45" image="https://ae01.alicdn.com/kf/HTB1D4CKizQnBKNjSZSgq6xHGXXao/Casual-Hawaiian-Shirts-Men-Cotton-Linen-Designer-Brand-Slim-Fit-Man-Shirts-Long-Sleeve-White-Shirts.jpg_Q90.jpg_.webp" brandImageTag="https://1000logos.net/wp-content/uploads/2017/05/zara-man-logo.jpg" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel4-items" >
                        <QuickDeals brandName="al karam" discountTag="70" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScnvmmcDMsJPrU291ltP4IRy9QoIEz2t93bQ&usqp=CAU" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel4-items" >
                        <QuickDeals brandName="levis" discountTag="75" image="https://images.unsplash.com/photo-1598033129183-c4f50c736f10?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fHNoaXJ0fGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel4-items">
                        <QuickDeals brandName="the designer's dress pant and shirt" discountTag="35" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR-M1YiuJHuDxJ8YglBnwc0PDhIFjZikxI4A&usqp=CAU" />
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default QuickdealsCarousel
