export function isValidAddress(data) {
    const isValidAddressTitle = data?.addressTitle !== undefined && data?.addressTitle !== "";
    const isValidFlatNo = data?.flatNo !== undefined && data?.flatNo !== "";
    const isValidStreet = data?.street !== undefined && data?.street !== "";
    const isValidArea = data?.area !== undefined && data?.area !== "";

    return {
        isValidAddressTitle: isValidAddressTitle,
        isValidFlatNo: isValidFlatNo,
        isValidStreet: isValidStreet,
        isValidArea: isValidArea,
        allValid: isValidAddressTitle && isValidFlatNo && isValidStreet && isValidArea
    }
}