import './sale.item.box.detail.carousel.scss';
import React from 'react'
import Slider from "react-slick";
import IconButton from '@mui/material/IconButton'
import SaleItemBoxDetail from 'components/common/sale.item.box.detail/sale.item.box.detail';

const DetailItemBoxCarousel = () => {

    function NextArrow(props) {
        const { className, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-n3 carousel3">
                    <i className="fa fa-arrow-right p-1" id="left_arrow_carousel3"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-3 carousel3" >
                    <i className='fa fa-arrow-left p-1' id="left_arrow_carousel3"></i>
                </IconButton>
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div id="sale-item-box-detail-carousel" className="h-100 w-100">

            <Slider className="h-100 w-95 m-auto" {...settings}>
                <div >
                    <div className="p-3">
                        <SaleItemBoxDetail stars="4" tag="new" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtH9UL6q7icS51VsCTh6X6SsqCj6uopn9mkQ&usqp=CAU"  showbuttons="true" brandName="snug" description="boys denim jeans-blue" price="Rs.4,360.15" oldPrice="6,1245" />
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail tag="sale 20%" image="https://images.unsplash.com/photo-1553062407-98eeb64c6a62?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmFnfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" stars="5" />
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail image="https://images.unsplash.com/photo-1547949003-9792a18a2601?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmFnc3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80"  showbuttons="true" />
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail brandName="snug" description="boys denim jeans-blue" price="Rs.4,360.15" oldPrice="6,1245"/>
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail brandName="snug" description="boys denim jeans-blue" price="Rs.4,360.15" oldPrice="6,1245"/>
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail brandName="snug" description="boys denim jeans-blue" price="Rs.4,360.15" oldPrice="6,1245"/>
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail brandName="snug" description="boys denim jeans-blue" price="Rs.4,360.15" oldPrice="6,1245"/>
                    </div>
                </div>
                <div>
                    <div className="p-3">
                        <SaleItemBoxDetail />
                    </div>
                </div>
            </Slider>
        </div >
    )
}

export default DetailItemBoxCarousel
