export const GET_ACTIVE_ORDERS = "GET_ACTIVE_ORDERS";
export const GET_PAST_ORDERS = "GET_PAST_ORDERS";
export const GET_CANCELLED_ORDERS = "GET_CANCELLED_ORDERS";
export const ORDER_HISTORY_PAGE_SIZE = 24;

export function getActiveOrders(data) {
    return {
        url : `users/orders?order_type=active&page=${data.page}&per_page=${ORDER_HISTORY_PAGE_SIZE}`,
        type : GET_ACTIVE_ORDERS,
        payload : data
    }
}

export function getPastOrders(data) {
    return {
        url : `users/orders?order_type=past&page=${data.page}&per_page=${ORDER_HISTORY_PAGE_SIZE}`,
        type : GET_PAST_ORDERS,
        payload : data
    }
}

export function getCancelledOrders(data) {
    return {
        url : `users/orders?order_type=cancelled&page=${data.page}&per_page=${ORDER_HISTORY_PAGE_SIZE}`,
        type : GET_CANCELLED_ORDERS,
        payload: data
    }
}