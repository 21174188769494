import App from './app';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import saga from 'view.updater/middlewares/index';
import reducer from 'view.updater/reducers/app.reducer';
const withReducer = injectReducer({ key: 'app', reducer });
const withMiddlewares = injectSaga({ key: 'app', saga });

export default compose(withReducer, withMiddlewares)(App);
