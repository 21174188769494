// The initial state of the App
import { fromJS } from 'immutable';
import { GET_ACTIVE_ORDERS, GET_PAST_ORDERS, GET_CANCELLED_ORDERS } from 'view.updater/actions/order.actions';
import { getActionName } from './util';

const initialState = fromJS({
    activeOrders: [],
    pastOrders: [],
    cancelledOrders: [],
    activeOrdersCount: ''
});

function orderReducer(state = initialState, action) {
    switch (action.type) {
        case getActionName(GET_ACTIVE_ORDERS):
            const orderIndex = state.get('activeOrders').findIndex((order) => order.get('page') == action.payload.page);
            if (orderIndex === -1) {
                state = state.updateIn(['activeOrders'], (orders) => {
                    return orders.push(fromJS({
                        orders: fromJS(action.response),
                        page: action.payload.page
                    }))
                })
            }
            else {
                state = state.updateIn(['activeOrders', orderIndex, 'orders'], (list) => {
                    return fromJS(action.response);
                })
            }
            if (action.response && action.response.pagination && action.response.pagination.total_items > 0)
                state = state.set('activeOrdersCount', action.response.pagination.total_items)
            else {
                state = state.set('activeOrdersCount','')
            }
            return state;
        case getActionName(GET_PAST_ORDERS):
            const pastOrderIndex = state.get('pastOrders').findIndex((order) => order.get('page') == action.payload.page);
            if (pastOrderIndex === -1) {
                state = state.updateIn(['pastOrders'], (orders) => {
                    return orders.push(fromJS({
                        orders: fromJS(action.response),
                        page: action.payload.page
                    }))
                })
            }
            else {
                state = state.updateIn(['pastOrders', pastOrderIndex, 'orders'], (list) => {
                    return fromJS(action.response);
                })
            }
            return state;
        case getActionName(GET_CANCELLED_ORDERS):
            const cancelOrderIndex = state.get('cancelledOrders').findIndex((order) => order.get('page') == action.payload.page);
            if (cancelOrderIndex === -1) {
                state = state.updateIn(['cancelledOrders'], (orders) => {
                    return orders.push(fromJS({
                        orders: fromJS(action.response),
                        page: action.payload.page
                    }))
                })
            }
            else {
                state = state.updateIn(['cancelledOrders', cancelOrderIndex, 'orders'], (list) => {
                    return fromJS(action.response);
                })
            }
            return state;
        default:
            return state;
    }
}
export default orderReducer;
