import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({

  muibutton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.dark.main,
    fontSize: "12px !important",
    outline: "none !important",
    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  },
  tagStyle: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.background.paper
  },
  titleStyle:{
    color: theme.palette.background.paper
  }
}))