import React from "react";
import { IconButton, Typography, Paper } from "@mui/material";
import { useStyle } from './checkout.delivery.address.style'

const CheckoutDeliveryAddress = (props) => {
  const classes = useStyle()
  const { address, inStore } = props;
  return (
    <Paper onClick={props.openAddressDialog} elevation={0} className="d-flex justify-content-between flex-wrap w-100 align-items-center pt-3">
      <div className="d-flex align-items-start">
        <IconButton>
          <i className="material-icons">place</i>
        </IconButton>
        <div>
          <Typography classes={{ root: classes.text }} className="font-weight-bold">{inStore ? inStore : "Deliver To"}</Typography>
          <Typography className="d-flex align-items-center" classes={{ root: classes.text }} variant="subtitle2">
            {address
              ? address
              : ""}
            <div>
              <IconButton>
                <span className="material-icons">chevron_right</span>
              </IconButton>
            </div>
          </Typography>

        </div>
      </div>
    </Paper>
  );
};

export default CheckoutDeliveryAddress;
