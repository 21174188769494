import { put } from 'redux-saga/effects';
import { doSignUp, DO_SIGN_UP } from '../actions/sign.up.actions';
import { getErrorActionName } from './util/util';

//import { makeSelectUsername } from 'view.updater/selectors/home.selectors'; 
export default function* allAfterErrorMiddleware(action) {
    switch (action.type) {
        case getErrorActionName(DO_SIGN_UP):
            if (action.data && action.data.user_status === "pending" &&
                action.data.user_query_id) {
                yield put(doSignUp({
                    ...action.data,
                    activeStep: 1,
                    sendOtp: true,
                    userId: action.data.user_query_id
                }));
            }
            else {
                if(action.data && action.data.user_status === "active" &&
                action.data.user_query_id) {
                    yield put(doSignUp({
                        ...action.data,
                        activeStep: -1,
                        userId: action.data.user_query_id
                    }));
                }
            }
            break;
        default:
            return;
    }
}

// DEMO CODE
  // if (action.response && action.response.status === "OTP_REQUIRED") {
            //     yield put(push('/login'));
            // }
            // else {
            //     if (action.data && action.data.otp) {
            //         // yield put(addDevice({ //DEVICE_AUTO_SAVE
            //         //     type: "WEB",
            //         //     device: `${action.data.username} Desktop ${window.navigator.platform} ${window.navigator.vendor} ${window.navigator.appCodeName}`,
            //         //     model: window.navigator.productSub,
            //         //     brand: window.navigator.vendor,
            //         //     manufacturer: window.navigator.product,
            //         //     osVersion: window.navigator.platform,
            //         //     serial: window.localStorage.getItem("visitorId")
            //         // }));
            //     }
            //     yield put(push('/dashboard'));
            // }