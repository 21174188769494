import React, { useState } from 'react'
import clsx from 'clsx'
import { Container, Grid, Paper, Typography } from '@mui/material'
import { useStyle } from './footer.v1.style'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import packageJson from "../../../../package.json";
import IframeDialog from 'components/common/iframe.dialog/iframe.dialog';

const FooterV1 = (props) => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const [state, setState] = useState({
        isIframeDialogOpen: false
    })
    const showIframeDialog = () => {
        setState({ ...state, isIframeDialogOpen: true })
    }
    const hideIframeDialog = () => {
        setState({ ...state, isIframeDialogOpen: false })
    }

    const redirect = (url) => {
        props.push(url);
    }
    return (
        <Paper elevation={0} className={clsx(classes.footer, 'm-auto cursor-pointer')}>
            <Container maxWidth='lg' className='pt-5 pb-5'>
                <div className={clsx(!(mobileWidth || tabletWidth) && 'd-none', tabletWidth && 'd-flex justify-content-center pb-3', mobileWidth && 'd-flex justify-content-between pb-3')}>
                    <div>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/google-playstore-logo.svg`}
                            height='48px'
                            width='159.78px'
                        />
                    </div>
                    <div className='ml-2'>
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/apple-store-logo.svg`}
                            height='48px'
                            width='144px'
                        />
                    </div>
                </div>
                <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <div className={clsx(classes.gridStyle, 'd-flex flex-column pt-2 pl-5 pb-5')}>
                            <Typography className={clsx(classes.textStyle, 'mt-2')}
                                variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle18M4', tabletWidth && 'subtitle12', mobileWidth && 'body18NL')}>
                                Discover Zapp
                            </Typography>
                            <Typography onClick={() => redirect("/about-us")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                About us
                            </Typography>
                            <Typography onClick={() => redirect("/riders")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Riders
                            </Typography>
                            <Typography onClick={() => redirect("/merchants")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Merchant partners
                            </Typography>
                            <Typography onClick={() => redirect("/careers")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Careers
                            </Typography>
                            {/* <Typography onClick={() => redirect("/news")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                News
                            </Typography> */}
                            <Typography onClick={showIframeDialog} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Subscribe to the newsletter
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <div className={clsx(classes.gridStyle, 'd-flex flex-column pt-2 pl-5 pb-5')}>
                            <Typography className={clsx(classes.textStyle, 'mt-2')}
                                variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle18M4', tabletWidth && 'subtitle12', mobileWidth && 'body18NL')}>
                                Legal
                            </Typography>
                            <Typography onClick={() => redirect("/terms-and-conditions")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Terms & conditions
                            </Typography>
                            <Typography onClick={() => redirect("/privacy-policy")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Privacy policy
                            </Typography>
                            <Typography onClick={() => redirect("/cookies-policy")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Cookies policy
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                        <div className={clsx(classes.gridStyle, 'd-flex flex-column pt-2 pl-5 pb-5')}>
                            <Typography className={clsx(classes.textStyle, 'mt-2')}
                                variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle18M4', tabletWidth && 'subtitle12', mobileWidth && 'body18NL')}>
                                Help
                            </Typography>
                            <Typography onClick={() => redirect("/contact")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                Contact
                            </Typography>
                            <Typography onClick={() => redirect("/faqs")} className={clsx(classes.textStyle, 'mt-4')} variant='subtitle14M6'>
                                FAQs
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} className={clsx((mobileWidth || tabletWidth) && 'd-none')}>
                        <div className={clsx(classes.gridStyle, 'd-flex flex-column pt-2 pb-5')}>
                            <Typography className={clsx(classes.textStyle, 'mt-2 pl-5')}
                                variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle18M4', tabletWidth && 'subtitle12', mobileWidth && 'body18NL')}>
                                Zapp, with you
                            </Typography>
                            <div className='mt-3'>
                                <div className='pl-4 ml-2'>
                                    <a href="https://play.google.com/store/apps/details?id=com.app.zapp" target='_blank'>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/google-playstore-logo.svg`}
                                            height='48.07px'
                                            width='160px'
                                        />
                                    </a>
                                </div>
                                <div className='mt-3 pl-4 ml-2'>
                                    <a href='https://apps.apple.com/pk/app/zapp-shop-anytime-anywhere/id1541819812' target='_blank'>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/apple-store-logo.svg`}
                                            height='53.33px'
                                            width='160px'
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className={clsx(!mobileWidth && 'd-flex flex-row justify-content-between align-items-start mt-5', mobileWidth && 'd-block mt-4 text-center mb-4')}>
                    <div className={clsx(!mobileWidth && 'd-flex', mobileWidth && 'd-flex justify-content-center pb-2 pt-3')}>
                        <div>
                            <a href='https://www.facebook.com/Zapp-PK-106014561255474' target='_blank'>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/facebook.png`}
                                    height='32px'
                                    width='32px'
                                />
                            </a>
                        </div>
                        <div className='ml-2'>
                            <a href='https://www.instagram.com/zapp.pk/' target='_blank'>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/instagram.png`}
                                    height='32px'
                                    width='32px'
                                />
                            </a>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <Typography className={clsx(classes.textStyle)} variant='body18NL'>
                            {`©${new Date().getFullYear()}. Zapworld Oy. all rights reserved.`}
                        </Typography>
                        <Typography className={clsx(classes.textStyle, 'text-center pt-2')} variant='body18NL'>Version {packageJson.version}</Typography>
                    </div>
                </div>
            </Container>
            {
                state.isIframeDialogOpen === true ?
                    <IframeDialog
                        iframeSrc="https://cdn.forms-content.sg-form.com/a50ec80f-f6d6-11ec-aa28-f60aaf7c888b"
                        isIframeDialogOpen={state.isIframeDialogOpen}
                        hideIframeDialog={hideIframeDialog}
                        {...props}
                    /> : null
            }
        </Paper>
    )
}

export default FooterV1