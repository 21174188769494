import React from 'react'
import { useStyle } from './order.detail.style';
import { Typography, Paper } from '@mui/material';
import { formatAmountV1, isB2bUser } from 'components/common/util/util';

const OrderDetail = (props) => {
    const classes = useStyle()
    const { label, cost, items, voucherInfo, itemQuantity, subTotal, voucher, tipZappMan, serviceFee, deliveryFee,
        checkOutInvoiceState, auth } = props;
    return (
        <div>
            <Paper classes={{ root: classes.lightBorder }} elevation={0} className='mb-2 p-2 pt-2 pb-3'>
                <div className='w-100'>
                    <Typography classes={{ root: classes.textColor }} className="pt-2 pb-2">
                        <b>
                            Order Details
                        </b>
                    </Typography>
                </div>
                <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold">
                    {itemQuantity ? itemQuantity : ''}
                </Typography>
                {
                    checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('invoice_items')?.map((item, index) => {
                        return <div key={index} className='d-flex w-100'>
                            <div className="mr-2">
                                <Typography className="font-weight-bold width-max-content" classes={{ root: classes.textColor }} >
                                    {`${item.get("quantity")}*`}
                                </Typography>
                            </div>
                            <div className="w-100 pr-2">
                                <Typography className="text-ellipsis-clamp-1" classes={{ root: classes.textColor }} >
                                    {item?.get("product_name") ? item?.get("product_name") : ""}
                                </Typography>
                            </div>
                            <div>
                                <Typography className='width-max-content' classes={{ root: classes.textColor }}>
                                    {
                                        item.get('discount') && item.get('discount').get('type') ?
                                            <div className="d-flex">
                                                <div className="pr-1">
                                                    {item?.get('currency')}
                                                </div>
                                                <div>
                                                    <Typography classes={{ root: classes.oldPriceStyle }} variant="caption">
                                                        {`${formatAmountV1(item.get('amount'))}`}
                                                    </Typography>
                                                </div>
                                                <div className="pl-2">
                                                    {item?.get('payble_amount') ? formatAmountV1(item?.get('payble_amount')) : ''}
                                                </div>
                                            </div> :
                                            item?.get('amount') ? formatAmountV1(item?.get('amount')) : ''
                                    }
                                </Typography>
                            </div>
                        </div>
                    })
                }
            </Paper>
            <Paper classes={{ root: classes.lightBorder }} elevation={0} className='mt-2 mb-2 p-2 pt-2 pb-3'>
                <div className='d-flex justify-content-between pt-2'>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize">
                        Sub Total
                    </Typography>
                    <Typography classes={{ root: classes.textColor }} className="font-weight-bold text-capitalize">
                        {subTotal ? subTotal : "0.00"}
                    </Typography>
                </div>
                <div className='d-flex justify-content-between'>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize">
                        Discount
                    </Typography>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold">
                        {`${checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice').get('items_discount') ? formatAmountV1(checkOutInvoiceState?.get('invoice').get('items_discount')) : "0.00"}`}
                    </Typography>
                </div>
                <div className='d-flex justify-content-between'>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize">
                        Voucher
                    </Typography>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold">
                        {`${voucherInfo && voucherInfo?.get('voucher') && voucherInfo?.get('voucher')?.get('discount_amount') ? formatAmountV1(voucherInfo?.get('voucher')?.get('discount_amount')) : "0.00"}`}
                    </Typography>
                </div>
                {
                    !isB2bUser(auth) ?
                        <div className='d-flex justify-content-between'>
                            <Typography classes={{ root: classes.textColor }} className="text-capitalize">
                                Zapp Man Tip
                            </Typography>
                            <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold">
                                {tipZappMan ? tipZappMan : "0.00"}
                            </Typography>
                        </div> : null
                }
                <div className='d-flex justify-content-between'>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize">
                        Service Fee
                    </Typography>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold">
                        {serviceFee ? serviceFee : "0.00"}
                    </Typography>
                </div>
                <div className='d-flex justify-content-between'>
                    <Typography classes={{ root: classes.textColor }} className="text-capitalize">
                        Delivery Fee
                    </Typography>
                    <Typography classes={{ root: classes.textColor }} className="font-weight-bold text-capitalize">
                        {deliveryFee ? deliveryFee : "0.00"}
                    </Typography>
                </div>
            </Paper>
        </div>
    )
}

export default OrderDetail
