import React from 'react'
import { Typography, Paper, Button } from '@mui/material';
import { IsMobileWidth } from 'components/common/util/util';
import { useStyle } from './category.page.advertisment.panel.style';
import clsx from 'clsx'
const CategoryPageAdvertismentPanel = (props) => {
    const { image, title1, title2, mainTitle, width, height, bannerHeight, buttonTitle } = props
    const mobileWidth = IsMobileWidth()
    const classes = useStyle()

    return (
        <div className="w-100 mt-2">
            <Paper
                elevation={2}
                className="w-90 m-auto d-flex align-items-center"
                style={{
                    backgroundImage: image ? `url(${image})` : "",
                    backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                    backgroundRepeat: "no-repeat",
                    height: bannerHeight ? bannerHeight : "",
                }}>
                <div className={clsx(!mobileWidth && "w-60 h-100 d-flex justify-content-start pl-5 align-items-center", mobileWidth && "w-80 h-75 d-flex justify-content-start p-2 align-items-center")}>
                    <Paper className={clsx(!mobileWidth && "h-75 w-60 d-flex justify-content-start align-items-center p-5 paper-root", mobileWidth && "h-100 w-80 d-flex justify-content-center align-items-center p-3 paper-root")}>
                        <div>
                            <Typography variant="h6" component="div" className="text-ellipsis-clamp-1 font-weight-normal text-lowercase h-auto">
                                {title1 ? title1 : ""}
                            </Typography>
                            <Typography variant="h4" component="div" className="text-ellipsis-clamp-1 font-weight-light text-uppercase h-auto">
                                {title2 ? title2 : ""}
                            </Typography>
                            <Typography gutterBottom variant="h4" component="div" className="text-ellipsis-clamp-3 font-weight-bolder text-uppercase">
                                {mainTitle ? mainTitle : ""}
                            </Typography>
                            <Typography variant="h6">
                                <Button classes={{ root: classes.btnStyling }} className={clsx(!mobileWidth && "font-weight-bold text-uppercase pl-5 pr-5 pt-2 pb-2", mobileWidth && "text-uppercase font-weight-light")}
                                >{buttonTitle ? buttonTitle : ""}
                                </Button>
                            </Typography>
                        </div>
                    </Paper>
                </div>
            </Paper>
        </div >
    )
}

export default CategoryPageAdvertismentPanel
