import React, { useState, useEffect } from "react";
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import { GOOGLE_MAPS_API_KEY } from "view.updater/middlewares/util/api.config";
import GoogleMapReact from "google-map-react";
import { IconButton, Button, Typography } from "@mui/material";
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';
import GoogleMapsPlacesAutoComplete from './google.maps.places.autocomplete';
import NewAddressForm from "./new.address.form";

let marker;
export default function NewAddressMapDialog(props) {
    const { addNewAddressDialogIsOpen, selectedAddress } = props;
    const mobileWidth = IsMobileWidth()
    const [state, setState] = useState({
        showAddAddressForm: false,
        newAddressLat: selectedAddress && selectedAddress.get('lat') ? selectedAddress.get('lat') : 24.8500938933977,
        newAddressLng: selectedAddress && selectedAddress.get('long') ? selectedAddress.get('long') : 67.0082072541118,
        map: undefined,
        maps: undefined
    })
    const handleAddressFormOpen = () => {
        props.resetCreateAddress();
        setState({ ...state, showAddAddressForm: true });
    }
    const handleAddressFormClose = (e, reason) => {
        if (reason !== "backdropClick") {
            props.resetCreateAddress();
            setState({ ...state, showAddAddressForm: false });
        }
    }
    // useEffect(() => {
    //     if (addressUpdated === true) {
    //         handleAddressFormClose();
    //     }
    // }, [addressUpdated])
    const onMapClick = (data) => {
        setState({ ...state, newAddressLat: data.lat, newAddressLng: data.lng });
        if (marker) {
            marker.setMap(null);
        }
        if (state.maps) {
            marker = new state.maps.Marker({
                position: { lat: data.lat, lng: data.lng },
                map: state.map,
                icon: {
                    url: `${process.env.PUBLIC_URL}/assets/icons/location-marker.png`,
                    size: new state.maps.Size(71, 71),
                    origin: new state.maps.Point(0, 0),
                    anchor: new state.maps.Point(17, 34),
                    scaledSize: new state.maps.Size(35, 35)
                },
                draggable: true
            })
        }
    };
    const initGoogleMaps = ({ map, maps }) => {
        if (map === null) {
            setState({ ...state, map: null })
        }
        else {
            setState({ ...state, map: map, maps: maps })
        }
    }
    useEffect(() => {
        return () => {
            marker = '' // to avoid memory leakage
        }
    }, [])

    useEffect(() => {
        if (marker) {
            marker.setMap(null);
        }
        if (state.maps) {
            marker = new state.maps.Marker({
                position: { lat: state.newAddressLat, lng: state.newAddressLng },
                map: state.map,
                icon: {
                    url: `${process.env.PUBLIC_URL}/assets/icons/location-marker.png`,
                    size: new state.maps.Size(71, 71),
                    origin: new state.maps.Point(0, 0),
                    anchor: new state.maps.Point(17, 34),
                    scaledSize: new state.maps.Size(35, 35)
                },
                draggable: true
            })
        }
    }, [state.maps])

    const onLocationSearch = (location) => {
        if (state.maps && location) {
            if (marker) {
                marker.setMap(null);
            }
            marker = new state.maps.Marker({
                position: { lat: location.lat(), lng: location.lng() },
                map: state.map,
                icon: {
                    url: `${process.env.PUBLIC_URL}/assets/icons/location-marker.png`,
                    size: new state.maps.Size(71, 71),
                    origin: new state.maps.Point(0, 0),
                    anchor: new state.maps.Point(17, 34),
                    scaledSize: new state.maps.Size(35, 35)
                },
                draggable: true
            })
            setState({ ...state, newAddressLat: location.lat(), newAddressLng: location.lng() });
        }
    }

    return <div>
        <ContentDialog
            handleClose={props.handleClose}
            setHeaderBackgroundAsPrimary={true}
            // fullScreen={true}
            maxWidth='lg'
            open={addNewAddressDialogIsOpen}
            title={<Typography variant='h5' className="text-white text-center m-auto font-weight-bold pl-3">
                {
                    selectedAddress && selectedAddress.get('lat') ?
                        `Update ${selectedAddress.get('name')} Address ` : "Please Select Address From Map"
                }
            </Typography>}>
            {
                <div>
                    <div className="w-100">
                        <form>
                            {
                                state.map ?
                                    <GoogleMapsPlacesAutoComplete
                                        onLocationSearch={onLocationSearch}
                                        {...state} /> : null
                            }
                        </form>
                    </div>
                    <div style={{ width: "100%", height: "60vh" }} className="mt-2 pl-3 pr-3">
                        <GoogleMapReact
                            onClick={onMapClick}
                            onGoogleApiLoaded={initGoogleMaps}
                            options={{
                                fullscreenControl: false
                            }}
                            yesIWantToUseGoogleMapApiInternals={true}
                            bootstrapURLKeys={{
                                key: `${GOOGLE_MAPS_API_KEY}&libraries=places`,
                                libraries: ['places']
                            }}
                            center={{
                                lat: state.newAddressLat,
                                lng: state.newAddressLng,
                            }}
                            defaultZoom={15}>
                        </GoogleMapReact>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <Button onClick={props.handleClose} color="secondary" variant="outlined" className={clsx("mr-3", mobileWidth && "w-49", !mobileWidth && "w-20")}>
                            Go Back
                        </Button>
                        <Button onClick={handleAddressFormOpen} className={clsx(mobileWidth && "w-49", !mobileWidth && "w-20")}>
                            {
                                selectedAddress && selectedAddress.get('lat') ?
                                    "Confirm & Update Address" : "Add Selected Address"
                            }
                        </Button>
                    </div>
                    {
                        state.showAddAddressForm === true ?
                            <NewAddressForm
                                isOpen={state.showAddAddressForm}
                                handleAddressFormClose={handleAddressFormClose}
                                {...state}
                                {...props} /> : null
                    }
                </div>
            }
        </ContentDialog>
    </div>;
}

