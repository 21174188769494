import './iframe.dialog.scss'
import React from 'react'
import IframeDialogV2 from 'components/common/iframe.dialog.v2/iframe.dialog.v2';

const IframeDialog = (props) => {
    const { isIframeDialogOpen, iframeSrc } = props
    return (
        <IframeDialogV2
            open={isIframeDialogOpen}
            handleClose={props.hideIframeDialog}
        >
            <div className='w-100'>
                <iframe title='iframe' src={iframeSrc} className='responsive-iframe' allow='fullScreen' />
            </div>
        </IframeDialogV2 >
    )
}

export default IframeDialog