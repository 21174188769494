import { connect } from 'react-redux';
import { makeSelectLocalizationMessages, makeSelectLocalizationLanguage } from 'view.updater/selectors/localization.selectors';
import { createStructuredSelector } from 'reselect';
import { setLocalizationMessages } from 'view.updater/actions/localization.actions';
import Container from './container';
import { changeLanguage } from 'view.updater/actions/app.actions';
import { languageSelector, executionsSelector } from 'view.updater/selectors/app.selectors'
import { setAppProperties } from 'view.updater/actions/app.actions';
import { makeSelectAuth, makeSelectAddressUpdated, makeSelectAddressDeleted } from 'view.updater/selectors/auth.selectors';
import { resetLogin } from 'view.updater/actions/auth.actions';
import {
  resetAuth, logout, updateUserAuthData, createAddress, deleteAddress,
  updateAddress, resetCreateAddress, resetDeleteAddress
} from 'view.updater/actions/auth.actions';
import { makeSelectCartProducts } from 'view.updater/selectors/products.selectors';
import { deleteAllCartProducts, setStoreListingSelectedPageIndex } from 'view.updater/actions/products.actions';
import { makeSelectIndustryStores, makeSelectActiveIndustry, makeStoreListingSelectedPageIndex } from 'view.updater/selectors/products.selectors';
import { getIndustryStores, resetIndustryStores, searchStores, resetSearchStores } from 'view.updater/actions/products.actions';

const mapStateToProps = createStructuredSelector({
  translations: makeSelectLocalizationMessages(),
  locale: makeSelectLocalizationLanguage(),
  language: languageSelector(),
  auth: makeSelectAuth(),
  executions: executionsSelector(),
  cartProducts: makeSelectCartProducts(),
  addressUpdated: makeSelectAddressUpdated(),
  addressDeleted: makeSelectAddressDeleted(),
  industryStores: makeSelectIndustryStores(),
  storeListingSelectedPageIndex: makeStoreListingSelectedPageIndex()
});

const mapDispatchToProps = (dispatch) => ({
  setLocalizationMessages: (messages, locale) => dispatch(setLocalizationMessages(messages, locale)),
  changeLanguage: (language) => dispatch(changeLanguage(language)),
  logout: () => dispatch(logout()),
  setAppProperties: (data) => dispatch(setAppProperties(data)),
  resetLogin: () => dispatch(resetLogin()),
  resetAuth: () => dispatch(resetAuth()),
  resetCreateAddress: () => dispatch(resetCreateAddress()),
  resetDeleteAddress: () => dispatch(resetDeleteAddress()),
  createAddress: (data) => dispatch(createAddress(data)),
  deleteAddress: (data, addressDetails) => dispatch(deleteAddress(data, addressDetails)),
  updateAddress: (data) => dispatch(updateAddress(data)),
  deleteAllCartProducts: () => dispatch(deleteAllCartProducts()),
  updateUserAuthData: (data) => dispatch(updateUserAuthData(data)),
  getIndustryStores: (data) => dispatch(getIndustryStores(data)),
  resetIndustryStores: () => dispatch(resetIndustryStores()),
  setStoreListingSelectedPageIndex: (data) => dispatch(setStoreListingSelectedPageIndex(data)),
  searchStores: (data) => dispatch(searchStores(data)),
  resetSearchStores: () => dispatch(resetSearchStores())
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);