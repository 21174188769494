import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#F2D0E9',
    borderRadius: "0px"
  },
  btn: {
    boxShadow : "none",
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.lightBackgroundColor.main
    }
  },
  textColor: {
    color: theme.palette.dark.main,
  }
}));
