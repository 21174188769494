import { createSelector } from 'reselect';

const selectAuthorization = (state) => {
      return state.authorization;
}
const makeSelectAuth = () => createSelector(
    selectAuthorization,
    (authorizationState) => authorizationState.get('auth')
);
const makeSelectAddressUpdated = () => createSelector(
    selectAuthorization,
    (authorizationState) => authorizationState.get('addressUpdated')
);
const makeSelectAddressDeleted = () => createSelector(
    selectAuthorization,
    (authorizationState) => authorizationState.get('addressDeleted')
);

export {
    selectAuthorization,
    makeSelectAuth,
    makeSelectAddressUpdated,
    makeSelectAddressDeleted
};
