import "./checkout.add.voucher.scss";
import { Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import AddVoucherDialog from "components/common/add.voucher.dialog/add.voucher.dialog";
import { useStyle } from "./checkout.add.voucher.style";

const CheckoutAddVoucher = (props) => {
  const { voucherInfo, execStatusValidateVoucherStatus } = props;
  const classes = useStyle()
  const [state, setState] = useState({
    voucherDialogOpen: false
  });

  const openVoucherDialog = () => {
    props.resetValidateVoucher();
    setState({ voucherDialogOpen: true })
  };

  const closeVoucherDialog = () => {
    if (execStatusValidateVoucherStatus.status === "PENDING" || execStatusValidateVoucherStatus.status === "ERROR") {
      props.resetValidateVoucher();
    }
    setState({ voucherDialogOpen: false })
  };

  return (
    <div id="voucher">
      <Paper classes={{ root: classes.paper }} elevation={0} className="d-flex justify-content-between align-items-center w-100 pl-3 pr-3 pt-2 pb-2">
        <Typography classes={{ root: classes.textColor }} className="font-weight-bold">Voucher</Typography>
        {
          voucherInfo && voucherInfo?.get('voucher') && voucherInfo?.get('voucher')?.get('discount_amount') ?
            <div className="d-flex justify-content-center align-items-center">
              {`Discount applied PKR ${voucherInfo?.get('voucher')?.get('discount_amount')}`}
              <div className="pl-3">
                <IconButton
                  onClick={props.resetValidateVoucher}
                  size="small"
                  style={{ color: "black" }}>
                  <i className="fa fa-times"></i>
                </IconButton>
              </div>
            </div> :
            <Button
              classes={{ root: classes.btn }}
              className="width-max-content font-weight-bold p-0 pl-1 pr-2"
              onClick={openVoucherDialog}
            >
              <IconButton className="voucher-btn">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/voucher.tag.svg`}
                  width={20}
                  height={25}
                />
              </IconButton>
              Add Voucher
            </Button>

        }

      </Paper>
      {
        state.voucherDialogOpen === true ?
          <AddVoucherDialog open={state.voucherDialogOpen}
            closeVoucherDialog={closeVoucherDialog}
            {...props} /> : null
      }
    </div>
  );
};

export default CheckoutAddVoucher;
