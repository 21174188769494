import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({

  avatorColor: {
    backgroundColor: theme.palette.primary.main,
  },
  iconBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  root: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    zIndex: 1,
    backdropFilter: "blur(50px)",
    WebkitBackdropFilter: "blur(50px)",
    height: 45,
    borderLeft: `1.5px solid ${theme.palette.darkBlueColor.main} !important`,
    borderRadius: '8px',
  },
  iconTextStyle: {
    width: "max-content",
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '14px',
    paddingTop:'4px'
  },
  iconButton: {
    padding: 12,
  },

  divider: {
    height: 28,
    // margin: 4,
  },
  textColor: {
    color: "#000000",
  },
  link: {
    cursor: "pointer",
    '&:hover': {
      fontWeight: 200,
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      transition: `all .35s ease`
    }
  },
  textColor: {
    color: '#666666'
  },
  iconSize: {
    fontSize: '15px'
  }
}));
