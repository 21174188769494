
import { addUpdateAppLoadersStatus, SET_APP_PROPERTIES, clearAppLoaderStatus } from 'view.updater/actions/app.actions';
import { put, takeEvery, select, call } from 'redux-saga/effects';
import { cancelAllRequest, cancelRequest } from '../actions/app.actions';
import { RESET_AUTH, LOGOUT } from '../actions/auth.actions';
import { deleteAllCartProducts, getProducts, resetCheckOutInvoice, resetProducts, resetStoreProductCategories, resetIndustryStores, SET_SELECTED_STORE_PRODUCT_CATEGORY } from '../actions/products.actions';
import processIndexedDbQuery from './process.indexed.db.query';
import allAfterLocalMiddleware from './all.after.local.middleware';
import { resetUserVouchers } from '../actions/user.actions';

export function* processBeforeMiddleware(action) {
    if (action.url) {
        yield put(addUpdateAppLoadersStatus(action.type, 'PENDING'));
    }
    if (action.useIndexedDb && !action.url) {
        yield put(addUpdateAppLoadersStatus(action.type, 'PENDING'));
        const indexedDbQueryResponse = yield call(processIndexedDbQuery, action);
        if (indexedDbQueryResponse) {
            if (indexedDbQueryResponse.error) {
                yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`, indexedDbQueryResponse.error));
            }
            else {
                yield put(addUpdateAppLoadersStatus(action.type, 'DONE', action.errorId));
                yield put(addUpdateAppLoadersStatus(action.type, 'FINNISH', action.errorId));
                yield allAfterLocalMiddleware(action);
                yield put({ ...action, useIndexedDb: false, ...indexedDbQueryResponse })
            }
        }
    }
    if (action.type === RESET_AUTH || action.type === LOGOUT) {
        yield put(cancelAllRequest());
        yield put(deleteAllCartProducts());
        yield put(resetCheckOutInvoice());
        yield put(resetUserVouchers());
        yield put(resetIndustryStores(true));
        yield put(resetProducts(true,{}));
        yield put(resetStoreProductCategories(true));
    }
    if (action.type === '@@router/LOCATION_CHANGE') { //ROUTE_CHANGES // ROUTE_CHANGE

    }
    if (action.type === SET_SELECTED_STORE_PRODUCT_CATEGORY) {
        yield put(resetProducts(false,{}));
        //action.payload.category_id
        //action.payload.storeQueryId
        if(action.payload.queryStringName) {// if search from subcategroyView
            yield put(getProducts({
                storeQueryId: action.payload.storeQueryId,
                categoryId: action.payload.id,
                name : action.payload.queryStringName,
                //subCategoryId: subCat.id,
                page: 1
            }));
        }
        else if(action.payload.storeProductsQueryStringName) {
            yield put(getProducts({
                storeQueryId: action.payload.storeQueryId,
                name : action.payload.storeProductsQueryStringName,
                //subCategoryId: subCat.id,
                page: 1
            }));
        }
        else if(action.payload && action.payload.sub_categories) {
            for (let subCat of action.payload.sub_categories) {
                yield put(getProducts({
                    storeQueryId: action.payload.storeQueryId,
                    categoryId: action.payload.id,
                    subCategoryId: subCat.id,
                    page: 1
                }));
                //subCat.id
            }
        }
    }
    if (action.type && action.type.includes("RESET")) {
        if (action.payload && action.payload.resetActionNames) {
            for (var i = 0; i < action.payload.resetActionNames.length; i++) {
                yield put(cancelRequest({
                    type: action.payload.resetActionNames[i]
                }));
                yield put(addUpdateAppLoadersStatus(action.payload.resetActionNames[i], 'REQUEST_CANCELLED', `${action.type}_ERROR_ID`, { description: "Connection Failed" }));
            }
            yield put(clearAppLoaderStatus(action.payload.resetActionNames));
        }
    }
    switch (action.type) {
        case SET_APP_PROPERTIES: {
            if (action.data && action.data.internetConnected === true) {
                // Here need to dispatch those actions that are failed due to lost of internet connection
            }
        }
            break;
        default:
            return;
    }
}
export default function* allbeforeMiddleware() {
    yield takeEvery('*', processBeforeMiddleware);
}
