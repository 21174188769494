import './circular.navigation.box.scss';
import React from 'react'
import { Box, Typography, CardMedia, CardContent } from '@mui/material';
import { useStyle } from './circular.navigation.box.style'
const CircularNavigationBox = (props) => {
    const classes = useStyle()
    const { title, image, brandName, bgColor, width, height } = props
    return (
        <div id="circular-navigation-box">
            <Box className="w-100 h-100 p-2 text-center">
                <CardContent className="w-100 m-auto rounded-circle paper-root h-100" style={{ backgroundColor: bgColor ? bgColor : "" }}>
                    {image ?
                        <CardMedia
                            component="img"
                            image={image ? image : ""}
                            alt="Brand Logo"
                            height={height ? height : "auto"}
                            className="rounded-circle"
                        />
                        :
                        <Typography variant="subtitle1" className="w-100 h-100 text-ellipsis-clamp-1 font-weight-bold text-capitalize" style={{ paddingTop: "35%", paddingBottom: "35%" }}>
                            {brandName ? brandName : ""}
                        </Typography>
                    }
                </CardContent>
                <Typography
                    classes={{
                        root: classes.titleStyle
                    }}
                    variant="subtitle2" className="text-ellipsis-clamp-1 font-weight-bold text-capitalize mt-4">
                    {title ? title : ""}
                </Typography>
            </Box >
        </div>
    )
}

export default CircularNavigationBox