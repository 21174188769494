import React, { useEffect, useState } from 'react'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import AppbarDesktopV2 from './app.navbar.desktop.v2';
import AppbarMobileV2 from './app.navbar.mobile.v2';
import Collapse from '@mui/material/Collapse';
import AppSidebarV2 from './components/app.sidebar/app.sidebar.v2';
import LoginPageDialog from 'components/common/login.page.dialog/login.page.dialog';

const AppNavbarV2 = (props) => { // PRE_LOGIN_NAVBAR
    const { auth } = props;

    useEffect(() => {
        if (auth) {
            hideLoginPageDialog()
        }
    }, [auth])

    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const [state, setState] = useState({
        isDrawerOpen: false,
        isLoginPageDialogOpen: false,
    })
    const showDrawer = () => {
        setState({ ...state, isDrawerOpen: true });
    };

    const hideDrawer = () => {
        setState({ ...state, isDrawerOpen: false });
    };

    const showLoginPageDialog = () => {
        setState({ ...state, isLoginPageDialogOpen: true });
    }

    const hideLoginPageDialog = () => {
        setState({ ...state, isLoginPageDialogOpen: false });
        props.resetLogin();
    }

    return (
        <div>
            {
                !(mobileWidth || tabletWidth) ? <Collapse in={true}>
                    <AppbarDesktopV2
                        showLoginPageDialog={showLoginPageDialog}
                        {...props}
                    />
                </Collapse> :
                    <AppbarMobileV2
                        showDrawer={showDrawer}
                        hideDrawer={hideDrawer}
                        isDrawerOpen={state.isDrawerOpen}
                        showLoginPageDialog={showLoginPageDialog}
                        {...props}
                    />
            }
            <AppSidebarV2 // Currently only for mobile || tablet view
                isDrawerOpen={state.isDrawerOpen}
                showDrawer={showDrawer}
                hideDrawer={hideDrawer}
                {...state} {...props}
            />
            {
                state.isLoginPageDialogOpen === true ?
                    <LoginPageDialog
                        isLoginPageDialogOpen={state.isLoginPageDialogOpen}
                        hideLoginPageDialog={hideLoginPageDialog}
                        {...props}
                    /> : null
            }
        </div>
    )
}

export default AppNavbarV2