import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  header: {
    // backgroundColor: theme.palette.primary.main,
    // borderBottomLeftRadius: '25px',
    // borderBottomRightRadius: '25px',
    background: 'transparent'

  },
  headerText: {
    color: theme.palette.background.paper,
    // background: 'transparent'
  },
  textColor: {
    color: theme.palette.dark.main,
  },
  oldPriceStyle: {
    color: theme.palette.greyColor.main,
    textDecoration: "line-through"
  },
  textGray: {
    color: "gray",
  },
  textPurple: {
    color: theme.palette.purpleColor.main,
  },
  backgroundColor: {
    background: theme.palette.purpleColor.main,
  },
  btn2: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  lightBorder: {
    border: `0.7px solid ${theme.palette.lightBackgroundColor.main}`,
  },
  paper: {
    background: theme.palette.gradientColor.main,
    height: "fit-content"
  },
}));
