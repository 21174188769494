import { Button, Paper } from "@mui/material";
import { useStyle } from "./custom.button.style";
import React from "react";

const CustomButton = (props) => {
  const classes = useStyle()
  const { title, selected } = props;
  return (
    <Paper onClick={props.onClick} classes={{ root: selected ? classes.selectedBtn : classes.btn }} className="w-100 p-2">
      {title}
    </Paper>

  );
};

export default CustomButton;
