import './add.product.scss';
import React, { useState } from 'react'
import { validateProductName } from './validator'
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';
import BoxGroup from 'components/common/material.components/box.group/box.group';
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';

export default function AddProduct() {

    const [productName, setProductName] = useState("");
    const [state, setState] = useState({
        showAddProductDialog: false
    })
    const [validator, setValidator] = useState({ isProductNameInValid: false })

    const onTextChange = (event) => {
        setValidator({ ...validator, isProductNameInValid: false })
        setProductName(event.target.value)
    }

    const addProductName = (event) => {
        event.preventDefault()
        let validation = validateProductName(productName)
        setValidator({ ...validator, isProductNameInValid: validation.isProductNameInValid })
        setProductName("")
    }
    const toggleAddProductDialog = () => {
        setState({ ...state, showAddProductDialog: !state.showAddProductDialog })
    }
    const getProducts = () => {
        const groupContent = [];
        groupContent.push({
            content: <div onClick={toggleAddProductDialog} className="draggable h-100"
                type="images">
                <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    + Add
                    </span>
            </div>
        })
        groupContent.push({
            content: <div className="draggable h-100 selected-box"
                type="images">
                <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div>
                        Product 1
                    </div>
                </span>
            </div>,
            actions: [
                <IconButton
                    size="small"
                    style={{ color: "black" }}
                >
                    <i className="fa fa-trash"></i>
                </IconButton>,
                <IconButton
                    size="small"
                    style={{ color: "black" }}
                >
                    <i className="fa fa-edit"></i>
                </IconButton>
            ]
        })
        groupContent.push({
            content: <div className="draggable h-100"
                type="images">
                <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    Product 2
                    </span>
            </div>,
            actions: [
                <IconButton
                    size="small"
                    style={{ color: "black" }}
                >
                    <i className="fa fa-trash"></i>
                </IconButton>,
                <IconButton
                    size="small"
                    style={{ color: "black" }}
                >
                    <i className="fa fa-edit"></i>
                </IconButton>
            ]
        })
        return groupContent;
    }
    return (
        <div className='d-flex ml-4'>
            <div>
                <BoxGroup
                    height="100px"
                    width="100px"
                    groupContent={getProducts()}
                />
            </div>
            <ContentDialog
                handleClose={toggleAddProductDialog}
                open={state.showAddProductDialog}
                title="Add Product"
                actions={
                    <span>
                        <Button
                            fullWidth={false}
                            className="p-2 mr-2"
                            size="lg"
                            type="submit"
                            onClick={() => toggleAddProductDialog()}>
                            Close
                        </Button>
                        <Button
                            fullWidth={false}
                            className="p-2"
                            size="lg"
                            type="submit"
                            onClick={(event) => addProductName(event)}
                        >
                            Save & Add Next
                        </Button>
                    </span>
                } >
                <div id="add-product">
                    <Typography variant="h5" align="left">Add Product Name</Typography>
                    <form>
                        <TextField
                            id="outlined-search"
                            label="Enter Product Name"
                            type="search"
                            value={productName}
                            error={validator.isProductNameInValid}
                            helperText={validator.isProductNameInValid ? "Required" : null}
                            className="mt-4"
                            onChange={(event) => onTextChange(event)}
                        />
                    </form>
                    <Typography
                        align="left"
                        className="mt-4">Press Enter to save and add next product name </Typography>
                </div>
            </ContentDialog>
        </div>
    );
}
