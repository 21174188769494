import { useStyle } from "./place.order.dialog.style";
import React from "react";
import { IsMobileWidth } from "components/common/util/util";
import {
    Divider,
    Paper,
    Typography
} from "@mui/material";
import clsx from "clsx";
import { formatAmountV1, formatDate, format12HrTime } from "components/common/util/util";
import { isB2bUser } from 'components/common/util/util';
import { getAddressDescription } from 'components/common/util/util';

const PlaceOrderSuccessDetails = (props) => {
    const mobileWidth = IsMobileWidth();
    const classes = useStyle();
    const {
        checkOutInvoiceState, auth
    } = props;
    return (
        <div className={clsx("w-100")}>
            <Paper
                sx={{ width: "100%", bgcolor: "background.paper" }}
                className="paper-root border p-2">
                <Typography
                    variant="h6"
                    component="div"
                    className="font-weight-bold d-flex align-items-center p-2 pl-2 h-auto text-ellipsis-clamp-1"
                    classes={{ root: classes.textPurple }}>
                    Your Bill
                </Typography>
                <div className="d-flex justify-content-between">
                    <div>
                        <Typography
                            variant="subtitle2"
                            className="text-ellipsis-clamp-1 h-auto p-2"
                            classes={{ root: classes.textColor }}>
                            Sub Total
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className="text-ellipsis-clamp-1 h-auto p-2"
                            classes={{ root: classes.textColor }}>
                            Discount
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className="text-ellipsis-clamp-1 h-auto p-2"
                            classes={{ root: classes.textColor }}>
                            Delivery Fee
                        </Typography>
                        {
                            !isB2bUser(auth) ?
                                <Typography
                                    variant="subtitle2"
                                    className="text-ellipsis-clamp-1 h-auto p-2"
                                    classes={{ root: classes.textColor }}>
                                    Thankyou Tip
                                </Typography> : null
                        }
                        <Typography
                            variant="subtitle2"
                            className="text-ellipsis-clamp-1 h-auto p-2"
                            classes={{ root: classes.textColor }}
                        >
                            Voucher
                        </Typography>
                        {/* <Typography
                                    variant="subtitle2"
                                    className="text-ellipsis-clamp-1 h-auto p-2"
                                    classes={{ root: classes.textColor }}
                                >
                                    TAX
                                </Typography> */}
                    </div>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className="text-right h-auto p-2"
                            classes={{ root: classes.textColor }}
                        >
                            <div className="d-flex justify-content-end">
                                <div className="pr-1">
                                    {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("currency")}
                                </div>
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("amount_without_discount") &&
                                    checkOutInvoiceState?.get("invoice")?.get("sub_total_amount") !== checkOutInvoiceState?.get("invoice")?.get("amount_without_discount") ?
                                    checkOutInvoiceState?.get("invoice")?.get("amount_without_discount") :
                                    checkOutInvoiceState?.get("invoice")?.get("sub_total_amount")}
                                {/* {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("sub_total_amount") ? checkOutInvoiceState?.get("invoice")?.get("sub_total_amount") : "0.00"} */}
                            </div>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className="text-right h-auto p-2"
                            classes={{ root: classes.textColor }}>
                            <div className="d-flex justify-content-end">
                                <div className="pr-1">
                                    {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("currency")}
                                </div>
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("items_discount") ? `-${checkOutInvoiceState?.get("invoice")?.get("items_discount")}` : "0.00"}
                            </div>
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className="text-right h-auto p-2"
                            classes={{ root: classes.textColor }}>
                            <div className="d-flex text-right justify-content-end">
                                <div className="pr-1">
                                    {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("currency")}
                                </div>
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("delivery_fee") ? checkOutInvoiceState?.get("invoice")?.get("delivery_fee") : "0.00"}
                            </div>
                        </Typography>
                        {
                            !isB2bUser(auth) ?
                                <Typography
                                    variant="subtitle2"
                                    className="text-right h-auto p-2"
                                    classes={{ root: classes.textColor }}>
                                    <div className="d-flex justify-content-end">
                                        <div className="pr-1">
                                            {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("currency")}
                                        </div>
                                        {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("driver_tip") ? checkOutInvoiceState?.get("invoice")?.get("driver_tip") : "0.00"}
                                    </div>
                                </Typography> : null
                        }
                        <Typography
                            variant="subtitle2"
                            className="text-right h-auto p-2 text-right"
                            classes={{ root: classes.textColor }}>
                            <div className="d-flex justify-content-end">
                                <div className="pr-1">
                                    {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("currency")}
                                </div>
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("voucher_discount") ? checkOutInvoiceState?.get("invoice")?.get("voucher_discount") : "0.00"}
                            </div>
                        </Typography>
                        {/* <Typography
                                    variant="subtitle2"
                                    className="text-right h-auto p-2"
                                    classes={{ root: classes.textColor }}
                                >
                                    PKR 0.00
                                </Typography> */}
                    </div>
                </div>
                <Divider />
                <div className="d-flex justify-content-between">
                    <div>
                        <Typography
                            variant="h6"
                            className="text-ellipsis-clamp-1 h-auto p-2"
                            classes={{ root: classes.textColor }}
                        >
                            Total
                        </Typography>
                    </div>
                    <div>
                        <Typography
                            variant="h6"
                            className=" text-right text-ellipsis-clamp-1 h-auto p-2"
                            classes={{ root: classes.textColor }}
                        >
                            <div className="d-flex justify-content-end">
                                <div className="pr-1">
                                    {checkOutInvoiceState &&
                                        checkOutInvoiceState?.get("invoice")?.get("currency")}
                                </div>
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("payble_amount")}
                            </div>
                        </Typography>
                    </div>
                </div>
            </Paper>
            <div
                className={clsx(
                    !mobileWidth && "d-flex w-100 mt-1",
                    mobileWidth && "d-block  w-100 mt-1"
                )}>
                <Paper
                    sx={{ bgcolor: "background.paper" }}
                    className={clsx(
                        !mobileWidth && "paper-root border w-50 mr-2",
                        mobileWidth && "w-100 paper-root border"
                    )}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        className="font-weight-bold d-flex align-items-center p-2 pl-2 h-auto text-ellipsis-clamp-1"
                        classes={{ root: classes.textPurple }}
                    >
                        Summary
                    </Typography>
                    <div className="d-flex justify-content-between">
                        <div>
                            <Typography
                                variant="subtitle2"
                                className="text-ellipsis-clamp-1 h-auto p-2"
                                classes={{ root: classes.textColor }}
                            >
                                Order #
                            </Typography>

                            <Typography
                                variant="subtitle2"
                                className="text-ellipsis-clamp-1 h-auto p-2"
                                classes={{ root: classes.textColor }}
                            >
                                Order Date
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className="text-ellipsis-clamp-1 h-auto p-2"
                                classes={{ root: classes.textColor }}
                            >
                                Order Time
                            </Typography>
                            {/* <Typography
                                    variant="subtitle2"
                                    className="text-ellipsis-clamp-1 h-auto p-2"
                                    classes={{ root: classes.textColor }}
                                >
                                    Payment Type
                                </Typography> */}
                        </div>
                        <div>
                            <Typography
                                variant="subtitle2"
                                className="text-right text-ellipsis-clamp-1 h-auto p-2"
                                classes={{ root: classes.textColor }}
                            >
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("short_order_id")}
                            </Typography>

                            <Typography
                                variant="subtitle2"
                                className="text-right text-ellipsis-clamp-1 h-auto p-2"
                                classes={{ root: classes.textColor }}
                            >
                                {checkOutInvoiceState && checkOutInvoiceState.get('invoice') && checkOutInvoiceState.get('invoice').get("created_date") && formatDate(checkOutInvoiceState?.get("invoice")?.get("created_date"))}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className="text-right text-ellipsis-clamp-1 h-auto p-2"
                                classes={{ root: classes.textColor }}
                            >
                                {checkOutInvoiceState && checkOutInvoiceState?.get("invoice") && checkOutInvoiceState.get("invoice").get("created_time") && format12HrTime(checkOutInvoiceState.get("invoice").get("created_time"))}
                            </Typography>
                            {/* <Typography
                                    variant="subtitle2"
                                    className="text-right text-ellipsis-clamp-1 h-auto p-2"
                                    classes={{ root: classes.textColor }}
                                >
                                    {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("payments")?.get(0)?.get("payment_type")}
                                </Typography> */}
                        </div>
                    </div>
                </Paper>
                <Paper
                    sx={{ width: "50%", bgcolor: "background.paper" }}
                    className={clsx(
                        !mobileWidth && "paper-root border w-50",
                        mobileWidth && "w-100 paper-root border mt-3"
                    )}>
                    <Typography
                        variant="h6"
                        component="div"
                        className="font-weight-bold d-flex align-items-center p-2 pl-2 h-auto text-ellipsis-clamp-1"
                        classes={{ root: classes.textPurple }}>
                        Delivery Address
                    </Typography>
                    <div>
                        <Typography
                            variant="subtitle2"
                            className="h-auto p-2"
                            classes={{ root: classes.textColor }}>
                            {getAddressDescription(checkOutInvoiceState &&
                                checkOutInvoiceState?.get("invoice")?.get("delivery_address"))}
                        </Typography>
                    </div>
                </Paper>
            </div>
            <div>
                <Paper
                    sx={{ width: "100%", bgcolor: "background.paper" }}
                    className="paper-root border p-2 mt-2"
                >
                    <div className="w-100">
                        <Typography
                            variant="h6"
                            component="div"
                            className="font-weight-bold d-flex align-items-center p-2 pl-2 h-auto text-ellipsis-clamp-1"
                            classes={{ root: classes.textPurple }}
                        >
                            Order Details
                        </Typography>
                    </div>
                    <Typography
                        variant="body1"
                        className="pl-2"
                        classes={{ root: classes.textGray }}
                    >
                        <div className="d-flex">
                            <div className="pr-1">
                                {
                                    checkOutInvoiceState?.get("invoice")?.get("invoice_items")?.size
                                }
                            </div>
                            Items
                        </div>
                    </Typography>
                    {checkOutInvoiceState && checkOutInvoiceState?.get("invoice")?.get("invoice_items")?.map((item, index) => {
                        return (
                            <div key={index} className="d-flex justify-content-between">
                                <Typography
                                    variant="subtitle2"
                                    className="d-flex h-auto p-2"
                                    classes={{ root: classes.textColor }}>
                                    <div className="font-weight-bold pr-2 width-max-content">
                                        {`${item.get("quantity")}*`}
                                    </div>
                                    {item?.get("product_name") ? item?.get("product_name") : ""}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className="h-auto p-2"
                                    classes={{ root: classes.textColor }}>
                                    {item?.get("amount") ? formatAmountV1(item?.get("amount")) : ""}
                                </Typography>
                            </div>
                        );
                    })}
                </Paper>

            </div>
        </div>
    )
}

export default PlaceOrderSuccessDetails