import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";
import "./confirmation.dialog.scss";
import LoaderButton from '../material.components/loader.button/loader.button'

export default function ConfirmationDialog(props) {
  const { open, title, successButtonText, deleteButtonText, loading } = props;

  return (
    <div className="popup">
      <Dialog
        disableBackdropClick={false}
        open={open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root: "dialog" }}
      >
        <DialogTitle
          className="mt-4 font-weight-bold"
          classes={{ root: "dialogTitle" }}
          id="alert-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent classes={{ root: "dialogContent" }}>
          <DialogContentText dividers id="alert-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>

        <DialogActions classes={{ root: "actions" }}>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <Divider />
            {
              deleteButtonText ?
                <div className="w-35">
                  <LoaderButton
                    fullWidth
                    onClick={props.handleClose}
                    color="primary"
                    variant="contained"

                  >
                    {deleteButtonText}
                  </LoaderButton></div>
                : null
            }
            {
              successButtonText ?
                <div className="w-35 pl-3">
                  <LoaderButton
                    loading={loading}
                    onClick={props.onSuccess}
                    color="secondary"
                    fullWidth
                    variant="contained"

                  >
                    {successButtonText}
                  </LoaderButton></div> : null
            }
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// <ConfirmationDialog
// open={true}
// title="Are you sure you want to delete"
// successButtonText="Ok"
// deleteButtonText="Delete"
// >
// Are you Sure you want to delete
// </ConfirmationDialog>