import React from 'react'
import { useStyle } from './discount.display.box.style';
import { Typography, Paper } from '@mui/material'
import clsx from 'clsx'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';

const DiscountDisplayBox = (props) => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const { title, children, description, selected} = props// subHeading
    return (
        <Paper onClick={props.onClick} className="mt-3 mb-3" elevation={0} classes={{ root: selected ? classes.selectedVoucher : classes.voucher }} >
            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body1", (mobileWidth || tabletWidth) && 'body2')} className='p-0 pt-1 d-flex justify-content-center'>
                {title ? title : ""}
            </Typography>
            {
                children ?
                    <div className='d-flex justify-content-center'>
                        {/* <Typography classes={{ root: classes.children1 }} variant="body2" className='p-0 font-weight-normal d-flex text-center'>
                            {subHeading}
                        </Typography> */}
                        <Typography classes={{ root: classes.children2 }} variant={clsx(!(mobileWidth || tabletWidth) && "subtitle", (mobileWidth || tabletWidth) && 'body1')}
                            className='p-0 font-weight-bold d-flex align-items-center'>
                            {children ? children : ''}
                        </Typography>
                    </div> : null
            }
            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body1", (mobileWidth || tabletWidth) && 'body2')} className='p-1 pb-1 d-flex justify-content-center'>
                {description ? description : ""}
            </Typography>
        </Paper>
    )
}

export default DiscountDisplayBox