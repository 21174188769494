import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    checkIcon: {
        backgroundColor: "white !important",
        border: `3px solid ${theme.palette.primary.main}`,
        position: "absolute !important",
        right: -10,
        top: -10,
        fontSize: '12px !important',
        zIndex : 1,
        color: `${theme.palette.primary.main} !important`,
    },
    selectedImage : {
        border: `2px solid ${theme.palette.primary.main}`,
    }
}));