import './category.box.panel.carousel.scss'
import React from 'react';
import Slider from "react-slick";
import IconButton from '@mui/material/IconButton';
import Categorybox from 'components/common/category.box.panel/category.box.panel';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx'
const CategoryBoxPannelCarousel = (props) => {
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    function NextArrow(props) {
        const { className, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-n5 carousel2">
                    <i className="fa fa-arrow-right p-1" id="left_arrow_carousel2"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-5 carousel2" >
                    <i className='fa fa-arrow-left p-1' id="left_arrow_carousel2"></i>
                </IconButton>
            </div>
        );
    }

    const settings = {
        dots: false,
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        variableWidth: true

    };


    return (
        <div className="w-100"
            style={{ height: "90vh" }}
            id="category-box-panel-carousel"
        >
            <Slider className="h-100 w-100 m-auto" {...settings}>

                <div className="p-3 h-100" style={{ width: clsx(!(mobileWidth || tabletWidth) && "1000px", tabletWidth && "700px", mobileWidth && "300px") }}>
                    <Categorybox title="men" image="" buttonTitle="Shop Now" />
                </div>

                <div className="p-3 h-100" style={{ width: clsx(!(mobileWidth || tabletWidth) && "1000px", tabletWidth && "700px", mobileWidth && "300px") }}>
                    <Categorybox tag="sale 20%" image="https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F5e205a1ba854780006e8a7b0%2F0x0.jpg" />
                </div>

                <div className="p-3 h-100" style={{ width: clsx(!(mobileWidth || tabletWidth) && "1000px", tabletWidth && "700px", mobileWidth && "300px") }}>
                    <Categorybox tag="new" image="https://i.pinimg.com/236x/c7/25/37/c72537491cbb3783775a98c0b455b0cd--casual-fridays-dapper-gentleman.jpg" buttonTitle="add to cart" />
                </div>

            </Slider>
        </div>
    )
}

export default CategoryBoxPannelCarousel
