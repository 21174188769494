import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    semiAppNavbar: {
        height: 'auto',
        left: '0px',
        boxShadow: 'none',
    },
    toolbar: {
        padding: "0px"
    },
    container: {
        padding: "4px 12px 4px 12px"
    },
    textStyle1: {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper
    },
    textStyle2: {
        color: theme.palette.background.paper
    }
}))
