import React, { useState, useEffect } from "react";
import { GOOGLE_MAPS_API_KEY } from "view.updater/middlewares/util/api.config";
import GoogleMapReact from "google-map-react";
import { IconButton, Grid, Button } from "@mui/material";
import TabButton from "components/common/tab.button/tab.button";
let marker;
export default function AddressMap(props) {
  const { auth, addNewAddressDialogIsOpen } = props;

  const [state, setState] = useState({
    map: '',
    maps: ''
  })

  const getSelectedAddress = () => {
    if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('home_address')?.get('id') == auth.get("selectedAddressId")) {
      return {
        lat: auth?.get('user')?.get('home_address')?.get("lat"),
        lng: auth?.get('user')?.get('home_address')?.get("long"),
        address: auth?.get('user')?.get('home_address')?.get("description"),
      }

    }
    else if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('work_address')?.get('id') == auth.get("selectedAddressId")) {
      return {
        lat: auth?.get('user')?.get('work_address')?.get("lat"),
        lng: auth?.get('user')?.get('work_address')?.get("long"),
        address: auth?.get('user')?.get('work_address')?.get("description")
      }
    }
    else if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('other_addresses')) {
      let add = auth?.get('user')?.get('other_addresses').find((address) => address.get('id') == auth.get("selectedAddressId"));
      if (add) {
        return {
          lat: add?.get("lat"),
          lng: add?.get("long"),
          address: add?.get("description")
        }
      }
      else {
        return {
          lat: 24.8500938933977,
          lng: 67.0082072541118,
          address: ""
        }
      }
    }
    else {
      return {
        lat: 24.8500938933977,
        lng: 67.0082072541118,
        address: ""
      }
    }
  }

  const initGoogleMaps = ({ map, maps }) => {
    if (map === null) {
      setState({ ...state, map: null })
    }
    else {
      setState({ ...state, map: map, maps: maps })
    }
  }

  useEffect(() => {
    if (state.map) {
      if (marker) {
        marker.setMap(null);
      }
      marker = new state.maps.Marker({
        position: { lat: getSelectedAddress()?.lat, lng: getSelectedAddress()?.lng },
        map: state.map,
        icon: {
          url: `${process.env.PUBLIC_URL}/assets/icons/location-marker.png`,
          size: new state.maps.Size(71, 71),
          origin: new state.maps.Point(0, 0),
          anchor: new state.maps.Point(17, 34),
          scaledSize: new state.maps.Size(35, 35)
        },
        //animation: state.maps.Animation.BOUNCE, // AVAILABLE ARE BOUNCE,DROP
      })
    }
  }, [state.map, auth && auth.get("selectedAddressId")])

  useEffect(() => {
    return () => {
      marker = ''
    }
  }, [])

  return (
    <div>
      {/* <Grid container spacing={1} className="d-flex justify-content-center"> */}
      {/* <Grid item
          onClick={() => props.onAddressChange(auth && auth?.get('user')?.get('home_address')?.get('id'))}
        >
          <TabButton title="Home"
            selected={!addNewAddressDialogIsOpen && auth && auth.get("selectedAddressId") && auth?.get('user')?.get('home_address')?.get('id') == auth.get("selectedAddressId")}
          >
          </TabButton>
        </Grid>
        <Grid item
          onClick={() => props.onAddressChange(auth && auth?.get('user')?.get('work_address')?.get('id'))}>
          <TabButton title="Work"
            selected={!addNewAddressDialogIsOpen && auth && auth.get("selectedAddressId") && auth?.get('user')?.get('work_address')?.get('id') == auth.get("selectedAddressId")}>
          </TabButton>
        </Grid> */}
      {/* <Grid item
          className="pb-2 pt-2"
          onClick={() => props.handleChange("addNewAddressDialogIsOpen", true)}>
          <TabButton title="Add New Address"
            selected={addNewAddressDialogIsOpen}>
          </TabButton>
        </Grid> */}
      {/* </Grid> */}

      <div style={{ width: "100%", height: "60vh" }} className="mt-2">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${GOOGLE_MAPS_API_KEY}&libraries=places`,
            libraries: ['places']
          }}
          center={{ lat: getSelectedAddress()?.lat, lng: getSelectedAddress()?.lng }}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={initGoogleMaps}>
        </GoogleMapReact>
      </div>
    </div>
  );
}