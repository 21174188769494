import CartSummary from './checkout.cart.summary';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectCartProducts, makeSelectCheckOutInvoice, makeSelectVoucherInfo } from 'view.updater/selectors/products.selectors';
import {
    getCartProducts, addUpdateProductToCart, createInvoice, resetCreateInvoice,
    resetCheckOutInvoice, resetPlaceOrder, placeOrder, deleteProductFromCart,
    downloadInvoiceReceipt, resetDownloadInvoiceReceipt, validateVoucher,
    resetValidateVoucher, deleteAllCartProducts
} from 'view.updater/actions/products.actions';
import { updateUserAuthData, createAddress, deleteAddress, updateAddress, resetCreateAddress, resetDeleteAddress } from 'view.updater/actions/auth.actions';
import { makeSelectAuth, makeSelectAddressUpdated, makeSelectAddressDeleted } from 'view.updater/selectors/auth.selectors';
import { getUserVouchers } from 'view.updater/actions/user.actions';
import { makeSelectUserVouchers } from 'view.updater/selectors/user.selectors';

const mapDispatchToProps = (dispatch) => ({
    getCartProducts: (data) => dispatch(getCartProducts(data)),
    addUpdateProductToCart: (data) => dispatch(addUpdateProductToCart(data)),
    createInvoice: (data) => dispatch(createInvoice(data)),
    resetCheckOutInvoice: () => dispatch(resetCheckOutInvoice()),
    resetPlaceOrder: () => dispatch(resetPlaceOrder()),
    resetDownloadInvoiceReceipt: () => dispatch(resetDownloadInvoiceReceipt()),
    resetCreateInvoice: () => dispatch(resetCreateInvoice()),
    deleteProductFromCart: (data) => dispatch(deleteProductFromCart(data)),
    placeOrder: (data, invoiceQueryId) => dispatch(placeOrder(data, invoiceQueryId)),
    updateUserAuthData: (data) => dispatch(updateUserAuthData(data)),
    getUserVouchers: (data) => dispatch(getUserVouchers(data)),
    downloadInvoiceReceipt: (data) => dispatch(downloadInvoiceReceipt(data)),
    createAddress: (data) => dispatch(createAddress(data)),
    deleteAddress: (data, addressDetails) => dispatch(deleteAddress(data, addressDetails)),
    updateAddress: (data) => dispatch(updateAddress(data)),
    resetCreateAddress: () => dispatch(resetCreateAddress()),
    resetDeleteAddress: () => dispatch(resetDeleteAddress()),
    validateVoucher: (data, invoiceQueryId) => dispatch(validateVoucher(data, invoiceQueryId)),
    resetValidateVoucher: () => dispatch(resetValidateVoucher()),
    deleteAllCartProducts: () => dispatch(deleteAllCartProducts())
});

const mapStateToProps = createStructuredSelector({
    cartProducts: makeSelectCartProducts(),
    checkOutInvoiceState: makeSelectCheckOutInvoice(),
    auth: makeSelectAuth(),
    voucherInfo: makeSelectVoucherInfo(),
    userVouchers: makeSelectUserVouchers(),
    addressUpdated: makeSelectAddressUpdated(),
    addressDeleted: makeSelectAddressDeleted()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(CartSummary);