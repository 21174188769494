import "./site.label.textfield.scss";
import React from "react";
import ReactInputMask from "react-input-mask";
import { TextField, Typography } from "@mui/material";
import { useStyles } from "./site.label.textfield.style";
import { Paper, InputAdornment, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { regex } from "components/common/util/regex";
import { IsMobileWidth, IsTabletWidth } from "components/common/util/util";

export default function SiteLabelTextField(props) {
  const {
    classNames,
    maxLength,
    pattern,
    startAdornment,
    responsiveStartAdornment,
    endAdornment,
    label,
    topAdornment,
    execStatus,
    error,
    maskChar,
    value,
    mask,
    placeholder,
    validationType,
    responsiveEndAdornment,
    type,
  } = props;
  const tabletWidth = IsTabletWidth();
  const mobileWidth = IsMobileWidth();
  const classes = useStyles();
  const onChange = (event) => {
    if (validationType === "ALPHANUMERIC") {
      if (
        regex.alphanumericWithOutSpace.test(event.target.value) ||
        event.target.value === ""
      ) {
        props.onChange(event);
      }
    } else if (validationType === "ALPHANUMERIC_WITH_SPACE") {
      if (
        regex.alphanumericWithSpace.test(event.target.value) ||
        event.target.value === ""
      ) {
        props.onChange(event);
      }
    } else if (validationType === "NUMERIC") {
      if (
        regex.wholeNumber.test(event.target.value) ||
        event.target.value === ""
      ) {
        props.onChange(event);
      }
    } else {
      props.onChange(event);
    }
  };
  return (
    <React.Fragment id="site-label-text-field">
      {mask && !props.disabled ? (
        <ReactInputMask
          mask={mask}
          maskChar={maskChar}
          placeholder={placeholder}
          onChange={onChange}
          alwaysShowMask={false}
          value={value}
        >
          {(inputProps) => (
            <div className="w-100 mt-2">
              {topAdornment ? (
                <Typography className="pt-2">
                  <label htmlFor="text-field" classname="form-label">
                    {topAdornment}
                  </label>
                </Typography>
              ) : (
                ""
              )}
              <Paper
                elevation={0}
                className={clsx(
                  classes.paperRoot,
                  "w-100 textfield-border-gray",
                  mobileWidth && responsiveEndAdornment && "d-flex flex-column"
                )}
              >
                {execStatus && execStatus.status === "PENDING" ? (
                  <div>
                    <CircularProgress size={50} className="p-3" />
                  </div>
                ) : (
                  <TextField
                    {...inputProps}
                    label={null}
                    disabled={props.disabled ? props.disabled : null}
                    variant="outlined"
                    inputProps={{
                      maxLength: maxLength,
                      pattern: pattern,
                    }}
                    InputProps={{
                      classes: {
                        inputAdornedEnd: classes.endAdn,
                        notchedOutline: classes.noBorder,
                        input: clsx(!(mobileWidth || tabletWidth) && classes.input, (mobileWidth || tabletWidth) && classes.mobileInput),
                        disabled: classes.disabled,
                      },
                      startAdornment: startAdornment || responsiveStartAdornment ? (
                        <InputAdornment
                          color="secondary"
                          className="h-100"
                          position="start"
                        >
                          {startAdornment && !mobileWidth
                            ? startAdornment
                            : responsiveStartAdornment
                              ? null
                              : startAdornment}
                        </InputAdornment>
                      ) : null,
                      endAdornment: (
                        <InputAdornment className="h-100" position="end">
                          {endAdornment && !mobileWidth
                            ? endAdornment
                            : responsiveEndAdornment
                              ? ""
                              : endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {mobileWidth ? responsiveEndAdornment : null}
              </Paper>
            </div>
          )}
        </ReactInputMask>
      ) : (
        <div className="w-100 mt-2">
          {topAdornment ? (
            <Typography className="pt-2">
              <label htmlFor="text-field" classname="form-label">
                {topAdornment}
              </label>
            </Typography>
          ) : (
            ""
          )}
          <Paper
            elevation={0}
            className={clsx(
              "w-100 textfield-border-gray",
              mobileWidth && responsiveEndAdornment && "d-flex flex-column"
            )}
          >
            {
              <div className="d-flex align-items-center w-100">
                {execStatus && execStatus.status === "PENDING" ? (
                  <div className="pr-4">
                    <CircularProgress size={45} className="p-3" />
                  </div>
                ) : null}
                <div className="flex-grow-1">
                  {props.disabled === true ? (
                    <div
                      className={clsx(
                        "d-flex align-items-center  pl-1 pt-1 pb-2",
                        classNames,
                        endAdornment && "justify-content-between"
                      )}
                      style={{
                        minHeight: "45px",
                      }}
                    >
                      <div>{value}</div>
                      <div>
                        {endAdornment && !mobileWidth
                          ? endAdornment
                          : responsiveEndAdornment
                            ? null
                            : endAdornment
                              ? endAdornment
                              : null}
                      </div>
                    </div>
                  ) : (
                    <TextField
                      id={props.id}
                      onBlur={props.onBlur}
                      value={value}
                      placeholder={placeholder}
                      label={null}
                      disabled={props.disabled ? props.disabled : null}
                      variant="outlined"
                      inputProps={{
                        maxLength: maxLength,
                        pattern: pattern,
                      }}
                      onChange={onChange}
                      InputProps={{
                        classes: {
                          //inputAdornedStart : classes.startAdn,
                          inputAdornedEnd: classes.endAdn,
                          //inputAdornedStart: classes.paddingLeft,
                          notchedOutline: classes.noBorder,
                          input: clsx(!(mobileWidth || tabletWidth) && classes.input, (mobileWidth || tabletWidth) && classes.mobileInput),
                          disabled: classes.disabled,
                        },
                        startAdornment:
                          startAdornment || responsiveStartAdornment ? (
                            <InputAdornment
                              color="secondary"
                              className="h-100"
                              position="start"
                            >
                              {startAdornment && !mobileWidth
                                ? startAdornment
                                : responsiveStartAdornment
                                  ? null
                                  : startAdornment}
                            </InputAdornment>
                          ) : null,
                        endAdornment: (
                          <InputAdornment className="h-100" position="end">
                            {endAdornment && !mobileWidth
                              ? endAdornment
                              : responsiveEndAdornment
                                ? null
                                : endAdornment}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  {mobileWidth ? responsiveEndAdornment : null}
                </div>
              </div>
            }
          </Paper>
        </div>
      )}
      {error ? <div className="text-danger w-100 p-1">{error}</div> : null}
    </React.Fragment>
  );
}
