import React, { useEffect } from 'react';
// import Header from './components.main/header/header'
//import SideBar from './components.main/sidebar/sidebar'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import AppNavbar from 'components/common/app.navbar/app.navbar'
import Footer from './components.main/footer/footer';
import Container from '@mui/material/Container';

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        // height: '100vh',
        // overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar
}));

const Main = props => {
    const { appProperties, location } = props;
    const classes = useStyles();
    useEffect(() => {
        if (props.auth && !props.auth.get('user')) {
            props.push('/home');
        }
        else if (!props.auth) {
            props.push('/home');
        }
    }, [props.auth]);

    const onCheckOut = () => {
        props.push('/checkout/cart-summary');
    }
    return (
        <div className="app">
            {/* <AppNavbar {...props} onCheckOut={onCheckOut} /> */}
            <div className="app-body d-flex flex-column">
                <div>
                    {/* <SideBar location={location} setAppProperties={props.setAppProperties} appProperties={appProperties} /> */}
                    {/* <Header setAppProperties={props.setAppProperties} appProperties={appProperties} /> */}
                    <Container maxWidth="xl" className="app">
                        <main className={clsx(classes.content, 'pb-3 min-h-100-vh')}>
                            {/* <div className={classes.appBarSpacer} /> */}
                            {props.children}
                        </main>
                    </Container>
                    {/* <div className='pt-5'>
                        <Footer {...props} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
export default connect(null, { push })(Main);