import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    subAdvertismentPanel: {
        backgroundColor: theme.palette.primary.main
    },
    textStyling: {
        color: theme.palette.background.paper
    },
    btnStyling: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.dark.main,
        '&:hover': {
            backgroundColor: "#D9D9D9",
        }
    }
}))