import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
  endAdn: {
    paddingLeft: "3.5px",
  },
  input: {
    height: 9,
    minWidth: "13%",
    textOverflow: "ellipsis",
  },

  selectedTip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    boxShadow: "none !important",
    cursor: 'pointer',
},
Tip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none !important",
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color : '#ffffff'
    }
},
  title: {
    "&:hover": {
      color: "white",
    },
  },
  selectedTitle: {
    color: "white",
  },
  text:{
    color: theme.palette.dark.main,
  }
}));
