import React from 'react'
import { IconButton, Typography, Box } from '@mui/material';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import Skeleton from '@mui/material/Skeleton';

export default function PageHeading(props) {
    const { name, subText, loading, endText } = props;
    const history = useHistory()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const goBack = () => {
        if (props.redirect) {
            props.redirect()
        }
        else
            history.goBack()
    }

    return (
        <Box onClick={goBack} className='d-flex justify-content-start width-max-content pr-3 align-items-center cursor-pointer pb-1 pt-1 rounded '>
            <IconButton className={clsx(!(mobileWidth || tabletWidth) && 'mr-3', (mobileWidth || tabletWidth) && 'mr-1', 'd-flex material-icons p-0')} >
                <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow_back_black.svg`} width='24px' />
            </IconButton>
            {
                loading ?
                    <Typography style={{
                        minWidth: '200px'
                    }} component="div" variant={'h6'} className='w-25'>
                        <Skeleton />
                    </Typography>
                    :
                    <div className='d-flex align-items-center flex-wrap'>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && "body2")} component="div" className="font-weight-bold text-capitalize h-auto">
                            {name}
                        </Typography>
                        {
                            subText &&
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && "body2")} component="div" className="font-weight-bold text-capitalize h-auto">
                                {subText ? `, ${subText}` : ''}
                            </Typography>
                        }
                        {
                            endText &&
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && "body2")} component="div"
                                className="font-weight-bold text-capitalize h-auto ml-2">
                                {endText}
                            </Typography>
                        }
                    </div>
            }
        </Box>
    )
}
