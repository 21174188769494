
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const RESET_PRODUCTS = 'RESET_PRODUCTS';
export const GET_INDUSTRY_STORES = "GET_INDUSTRY_STORES";
export const RESET_INDUSTRY_STORES = "RESET_INDUSTRY_STORES";
export const GET_STORE_PRODUCTS_CATEGORIES = "GET_STORE_PRODUCTS_CATEGORIES";
export const RESET_STORE_PRODUCT_CATEGORIES = "RESET_STORE_PRODUCT_CATEGORIES";
export const GET_STORE_DETAIL_INFO = "GET_STORE_DETAIL_INFO"
export const RESET_STORE_DETAIL_INFO = "RESET_STORE_DETAIL_INFO"
export const SET_SELECTED_STORE_PRODUCT_CATEGORY = "SET_SELECTED_STORE_PRODUCT_CATEGORY";
export const RESET_SELECTED_STORE_PRODUCT_CATEGORY = "RESET_SELECTED_STORE_PRODUCT_CATEGORY";
export const ADD_UPDATE_PRODUCT_TO_CART = "ADD_UPDATE_PRODUCT_TO_CART";
export const DELETE_PRODUCT_FROM_CART = "DELETE_PRODUCT_FROM_CART";
export const GET_CART_PRODUCTS = "GET_CART_PRODUCTS";
export const CREATE_INVOICE = "CREATE_INVOICE";
export const RESET_CREATE_INVOICE = "RESET_CREATE_INVOICE";
export const CHECKOUT_INVOICE = "CHECKOUT_INVOICE";
export const RESET_CHECKOUT_INVOICE = "RESET_CHECKOUT_INVOICE";
export const PLACE_ORDER = "PLACE_ORDER";
export const RESET_PLACE_ORDER = "RESET_PLACE_ORDER";
export const DELETE_ALL_CART_PRODUCTS = "DELETE_ALL_CART_PRODUCTS";
export const CLEAR_CART_AND_ADD_NEW_ITEM = "CLEAR_CART_AND_ADD_NEW_ITEM"
export const CLEAR_CART_AND_ADD_NEW_ITEMS = "CLEAR_CART_AND_ADD_NEW_ITEMS"
export const DOWNLOAD_INVOICE_RECEIPT = "DOWNLOAD_INVOICE_RECEIPT"
export const RESET_DOWNLOAD_INVOICE_RECEIPT = "RESET_DOWNLOAD_INVOICE_RECEIPT"
export const VALIDATE_VOUCHER = "VALIDATE_VOUCHER";
export const RESET_VALIDATE_VOUCHER = "RESET_VALIDATE_VOUCHER";
export const SEARCH_APP = "SEARCH_APP";
export const RESET_SEARCH_APP = "RESET_SEARCH_APP";
export const PRODUCT_PAGE_SIZE = 25;
export const INDUSTRY_STORE_PAGE_SIZE = 25;
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const RESET_GET_SINGLE_PRODUCT = "RESET_GET_SINGLE_PRODUCT";
export const GET_PRODUCTS_BY_ORDER_ID = "GET_PRODUCTS_BY_ORDER_ID";
export const RESET_GET_PRODUCTS_BY_ORDER_ID = "RESET_GET_PRODUCTS_BY_ORDER_ID";
export const SET_STORE_LISTING_SELECTED_PAGE_INDEX = 'SET_STORE_LISTING_SELECTED_PAGE_INDEX';
export const SEARCH_STORES = "SEARCH_STORES";
export const RESET_SEARCH_STORES = "RESET_SEARCH_STORES";

export function setStoreListingSelectedPageIndex(data) {
    return {
        type: SET_STORE_LISTING_SELECTED_PAGE_INDEX,
        payload: data
    }
}
export function getProductsByOrderId(data) { // implemented for reordering purpose
    return {
        url: `products?order_id=${data.orderId}`,
        type: GET_PRODUCTS_BY_ORDER_ID,
        payload: { ...data }
    };
}
export function resetGetProductsByOrderId() {
    return {
        type: RESET_GET_PRODUCTS_BY_ORDER_ID,
        payload: {
            resetActionNames: ["GET_PRODUCTS_BY_ORDER_ID"]
        }
    }
}

export function validateVoucher(data, invoiceQueryId) {
    return {
        method: 'PUT',
        url: `invoices/${invoiceQueryId}/validate_voucher`,
        type: VALIDATE_VOUCHER,
        payload: { ...data },
        data
    };
}
export function resetValidateVoucher() {
    return {
        type: RESET_VALIDATE_VOUCHER,
        payload: {
            resetActionNames: ["VALIDATE_VOUCHER"]
        }
    }
}
export function searchApp(data) {
    return {
        url: `users/app_search?query=${data.query}&per_page=${PRODUCT_PAGE_SIZE}&page=${data.page}&store_id=${data.storeQueryId}&industry_id=${data.industryId}&autocomplete=false`,
        type: SEARCH_APP,
        payload: { ...data }
    };
}
export function resetSearchApp() {
    return {
        type: RESET_SEARCH_APP,
        payload: {
            resetActionNames: ["SEARCH_APP"]
        }
    }
}

export function getProducts(data) {
    let url = '';
    if (data.categoryId && data.subCategoryId && data.name) {
        url = `products?organization_query_id=${data.storeQueryId}&name=${data.name}&category_id=${data.categoryId}&sub_category_id=${data.subCategoryId}&page=${data.page}&per_page=${PRODUCT_PAGE_SIZE}`
    }
    else if (data.categoryId && data.name) {
        url = `products?organization_query_id=${data.storeQueryId}&name=${data.name}&category_id=${data.categoryId}&page=${data.page}&per_page=${PRODUCT_PAGE_SIZE}`
    }
    else if (data.subCategoryId && data.name) {
        url = `products?organization_query_id=${data.storeQueryId}&name=${data.name}&sub_category_id=${data.subCategoryId}&page=${data.page}&per_page=${PRODUCT_PAGE_SIZE}`
    }
    else if (data.name) {
        url = `products?organization_query_id=${data.storeQueryId}&name=${data.name}&page=${data.page}&per_page=${PRODUCT_PAGE_SIZE}`
    }
    else {
        url = `products?organization_query_id=${data.storeQueryId}&category_id=${data.categoryId}&sub_category_id=${data.subCategoryId}&page=${data.page}&per_page=${PRODUCT_PAGE_SIZE}`
    }
    return {
        url: url,
        type: GET_PRODUCTS,
        payload: { ...data }
    };
}
export function downloadInvoiceReceipt(data) {
    return {
        url: `invoices/${data.invoiceQueryId}/receipt`,
        type: DOWNLOAD_INVOICE_RECEIPT,
        payload: { ...data }
    };
}
export function resetDownloadInvoiceReceipt() {
    return {
        type: RESET_DOWNLOAD_INVOICE_RECEIPT,
        payload: {
            resetActionNames: ["DOWNLOAD_INVOICE_RECEIPT"]
        }
    }
}
export function resetProducts(resetState = false, data) {
    return {
        type: RESET_PRODUCTS,
        payload: {
            resetActionNames: ["GET_PRODUCTS"],
            resetState: resetState,
            ...data
        }
    }
}
export function setSelectedStoreProductCategory(data) {
    return {
        type: SET_SELECTED_STORE_PRODUCT_CATEGORY,
        payload: { ...data }
    };
}
export function resetSelectedStoreProductCategory() {
    return {
        type: RESET_SELECTED_STORE_PRODUCT_CATEGORY
    };
}
export function searchStores(data) {
    return {
        type: SEARCH_STORES,
        url: `users/app_search?query=${data.query}&per_page=50&page=${data.page}&store_id=${data.storeId}&industry_id=${data.industryId}&autocomplete=${data.autocomplete}`,
        payload: { ...data }
    };
}
export function resetSearchStores() {
    return {
        type: RESET_SEARCH_STORES,
        payload: {
            resetActionNames: ["SEARCH_STORES","GET_INDUSTRY_STORES"]
        }
    }
}
export function getIndustryStores(data) {
    return {
        type: GET_INDUSTRY_STORES,
        url: data.lat && data.long ? `companies?industry_id=${data.industryId}&page_size=${INDUSTRY_STORE_PAGE_SIZE}&page=${data.page}&sort=default&lat=${data.lat}&long=${data.long}` :
            `companies?industry_id=${data.industryId}&page_size=${INDUSTRY_STORE_PAGE_SIZE}&page=${data.page}&sort=default`,
        payload: { ...data }
    };
}

export function resetIndustryStores(resetState = false) {
    return {
        type: RESET_INDUSTRY_STORES,
        payload: {
            resetActionNames: ["GET_INDUSTRY_STORES","SEARCH_STORES"],
            resetState: resetState
        }
    }
}

export function getStoreProductCategories(data) {
    return {
        type: GET_STORE_PRODUCTS_CATEGORIES,
        url: `companies/${data.storeQueryId}/categories`,
        payload: { ...data }
    };
}

export function getStoreDetailInfo(data) {
    return {
        type: GET_STORE_DETAIL_INFO,
        url: `companies/${data.storeQueryId}`,
        payload: { ...data }
    };
}

export function resetStoreDetailInfo() {
    return {
        type: RESET_STORE_DETAIL_INFO,
        payload: {
            resetActionNames: ["GET_STORE_DETAIL_INFO"]
        }
    }
}

export function resetStoreProductCategories(resetState = false) {
    return {
        type: RESET_STORE_PRODUCT_CATEGORIES,
        payload: {
            resetActionNames: ["GET_STORE_PRODUCTS_CATEGORIES"],
            resetState: resetState
        }
    }
}

export function addUpdateProductToCart(data) {
    return {
        type: ADD_UPDATE_PRODUCT_TO_CART,
        useIndexedDb: true,
        indexedDbOperationMode: "INSERT_UPDATE",
        payload: { ...data }
    };
}

export function deleteProductFromCart(data) {
    return {
        type: DELETE_PRODUCT_FROM_CART,
        useIndexedDb: true,
        indexedDbOperationMode: "DELETE",
        payload: { ...data }
    };
}

export function getCartProducts(data) {
    return {
        type: GET_CART_PRODUCTS,
        useIndexedDb: true,
        indexedDbOperationMode: "GET_ALL",
        payload: { ...data }
    };
}

export function deleteAllCartProducts(data) {
    return {
        type: DELETE_ALL_CART_PRODUCTS,
        useIndexedDb: true,
        indexedDbOperationMode: "DELETE_ALL",
        payload: { ...data }
    };
}

export function clearCartAndAddNewItem(data) {
    return {
        type: CLEAR_CART_AND_ADD_NEW_ITEM,
        useIndexedDb: true,
        indexedDbOperationMode: "DELETE_ALL",
        payload: { ...data }
    };
}
export function clearCartAndAddNewItems(data) {
    return {
        type: CLEAR_CART_AND_ADD_NEW_ITEMS,
        useIndexedDb: true,
        indexedDbOperationMode: "DELETE_ALL",
        payload: { ...data }
    };
}

export function resetCreateInvoice() {
    return {
        type: RESET_CREATE_INVOICE,
        payload: {
            resetActionNames: ["CREATE_INVOICE"]
        }
    }
}
export function createInvoice(data) {
    return {
        type: CREATE_INVOICE,
        data,
        method: 'POST',
        url: `invoices`,
        payload: { ...data }
    };
}

export function resetCheckOutInvoice() {
    return {
        type: RESET_CHECKOUT_INVOICE,
        payload: {
            resetActionNames: ["CHECKOUT_INVOICE", "DOWNLOAD_INVOICE_RECEIPT", "VALIDATE_VOUCHER"]
        }
    }
}
export function checkOutInvoice(data) {
    return {
        type: CHECKOUT_INVOICE,
        url: `invoices/${data.invoiceId}/checkout`,
        payload: { ...data }
    };
}

export function resetPlaceOrder() {
    return {
        type: RESET_PLACE_ORDER,
        payload: {
            resetActionNames: ["PLACE_ORDER"]
        }
    }
}
export function placeOrder(data, invoiceQueryId) {
    return {
        type: PLACE_ORDER,
        data,
        method: 'PUT',
        url: `invoices/${invoiceQueryId}/place_order`,
        payload: { ...data }
    };
}

export function getSingleProduct(data) {
    return {
        type: GET_SINGLE_PRODUCT,
        data,
        method: "GET",
        url: `products/${data.queryId}`,
    }
}
export function resetGetSingleProduct() {
    return {
        type: RESET_GET_SINGLE_PRODUCT,
        payload: {
            resetActionNames: ["GET_SINGLE_PRODUCT"]
        }
    }
}

