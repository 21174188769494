import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
    btnColor: {
        backgroundColor: theme.palette.purpleColor.normal,
        textTransform: 'none',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        height: "38px",
        '&:hover': {
            backgroundColor: theme.palette.purpleColor.normal,
            // border: `1px solid ${theme.palette.purpleColor.normal}`
        }
    }
}))