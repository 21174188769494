import React, { useState } from 'react'
import { Box, Grid, Typography, Paper, Button, IconButton } from '@mui/material';
import SummaryBox from '../summary.box/summary.box'
import ShoppingCartItem from 'components/common/shopping.cart.item/shopping.cart.item';
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { SERVER_BASE_URL } from "view.updater/middlewares/util/api.config";
import { renderHtml } from 'components/common/util/util';
import DeleteDialog from 'components/common/delete.dialog/delete.dialog';
import { formatAmountV1 } from 'components/common/util/util';
import PageHeading from 'components/common/page.heading/page.heading';
import { IsMobileWidth, IsTabletWidth } from "components/common/util/util";
import clsx from "clsx";
import { useStyle } from './shopping.cart.style';
import ShoppingCartHeader from './components/shopping.cart.header/shopping.cart.header';

const ShoppingCart = (props) => {
    const [state, setstate] = useState({
        deleteDialogOpen: false,
        selectedProduct: ''
    })
    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const classes = useStyle()
    const { cartProducts } = props;
    let totalPrice = 0;
    for (let cartProd of cartProducts) {
        if (cartProd && cartProd?.get('data')?.get('promotion') && cartProd?.get('data')?.get('promotion').get('promotion_amount')) {
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost_after_discount') * cartProd.get('data')?.get('quantity'))
        }
        else
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost') * cartProd.get('data')?.get('quantity'))
    }


    const onClose = () => {
        setstate({ ...state, deleteDialogOpen: false, selectedProduct: "" })
    }
    const openDeleteDialog = (product) => {
        setstate({ ...state, deleteDialogOpen: true, selectedProduct: product })
    }
    const onDelete = () => {
        props.setAddProductToCartId(state.selectedProduct?.get("data")?.get('query_id'))
        props.deleteProductFromCart({
            uniqueId: state.selectedProduct && state.selectedProduct?.get("data")?.get('query_id')
        })
        onClose();
    }
    const goBack = () => {
        props.history.goBack();
        //props.history.push(route)
    }
    const redirectTo = (route) => {
        props.history.push(route)
    }
    const onQuantityChange = (product, quantity) => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        if (quantity === 0) {
            setstate({ ...state, deleteDialogOpen: true, selectedProduct: product })
        }
        else {
            props.setAddProductToCartId(product?.get("data").get('query_id'))
            props.addUpdateProductToCart({
                ...product?.get('data')?.toJS(),
                quantity: quantity,
                storeQueryId: product?.get("data").get('storeQueryId'),
                uniqueId: product?.get("data").get('query_id')
            })
        }
    }
    const getTotalPrice = (prod) => {
        if (prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount')) {
            return formatAmountV1(prod?.get('data')?.get('quantity') * prod?.get('data')?.get('cost_after_discount'))
        }
        else if (prod?.get('data')?.get('quantity') && prod?.get('data')?.get('cost')) {
            return formatAmountV1(prod?.get('data')?.get('quantity') * prod?.get('data')?.get('cost'))
        }
        else
            return ''

    }
    return (
        <Box className='w-100'>
            {state.deleteDialogOpen ?
                <DeleteDialog open={state.deleteDialogOpen}
                    alertMessage="Are you sure you want to delete?"
                    buttonText='Delete'
                    onDelete={onDelete}
                    title={`Delete ${state.selectedProduct && state.selectedProduct?.get('data')?.get('name')} from cart`}
                    onClose={onClose}>
                    If you choose to delete this item,it will no longer part of your cart.
                </DeleteDialog>
                : null}
            <Box className='d-flex justify-content-start width-max-content align-items-center cursor-pointer pb-1 pt-1 rounded '>
                <PageHeading redirect={() => redirectTo('/products')} name="Shopping Cart" />
            </Box>
            <Grid container spacing={2}>
                {
                    cartProducts && cartProducts?.size === 0 ?
                        <div className={clsx(mobileWidth && 'mt-5', "w-100")}>
                            <Typography variant='h6' className="d-flex pt-4 justify-content-center font-weight-bold  h-auto">
                                Shopping cart is Empty!
                            </Typography>
                            <div className="d-flex justify-content-center pt-5">
                                <div>
                                    <Button onClick={() => goBack('/products')} variant="outlined" className="text-capitalize mr-2" >Continue Shopping</Button>
                                </div>
                                <div>
                                    <Button onClick={() => redirectTo('/order/history')} variant="contained" className="text-capitalize ml-2" >Go to Order History</Button>
                                </div>
                            </div>
                        </div> : null
                }
                <Grid item xs={12} sm={12} lg={9} md={8.7} >
                    {/* {
                        cartProducts 
                    } */}
                    {cartProducts && cartProducts.size && cartProducts?.size > 0 ?
                        < div >
                            <ShoppingCartHeader {...props} />
                        </div> : null
                    }
                    {
                        cartProducts && cartProducts?.map((prod, index) => {
                            return <div key={index} className="mb-3">
                                <ShoppingCartItem
                                    onItemDelete={() => openDeleteDialog(prod)}
                                    image={`${SERVER_BASE_URL}/${prod?.get('data')?.get('pictures')?.get(0)?.get('image_original_url')}`}
                                    productName={prod?.get('data')?.get('name')}
                                    discountAmount={prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount') ? prod?.get('data').get('cost_after_discount') : ''}
                                    price={prod?.get('data')?.get('cost') && formatAmountV1(prod?.get('data')?.get('cost'))}
                                    description={renderHtml(prod?.get('data')?.get('description'))}
                                    quantity={prod?.get('data')?.get('quantity')}
                                    onQuantityChange={(qty) => onQuantityChange(prod, qty)}
                                    {...props}
                                    redirectTo={redirectTo}
                                    product={prod?.get('data')}
                                    totalPrice={getTotalPrice(prod)} />
                            </div>
                        })
                    }
                </Grid>
                {
                    <Grid item xs={12} sm={12} lg={3} md={3.3} >
                        <div className="pt-4 mt-3">
                            <SummaryBox redirecto={redirectTo}
                                totalItems={cartProducts?.size}
                                orderTotalPrice={totalPrice}
                                buttonTitle="Continue Shopping"
                                buttonTitle2="Proceed to Checkout"
                                {...props} />
                        </div>
                    </Grid>
                }
            </Grid>
        </Box >
    )
}
export default ShoppingCart