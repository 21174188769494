import './best.deals.scss';
import React from 'react';
import Card from '@mui/material/Card';
import {  Paper,  Typography } from '@mui/material';
import { useStyle } from './best.deals.style';
import CountDownTimer from '../count.down.timer/count.down.timer';
const BestDeal = (props) => {
    const classes = useStyle()
    const { discountTag, image, brandName, brandImageTag, brandNameTag, imageTagHeight, imageTagWidth, tagColor, width, height } = props

    return (
        <div
            className="h-100 w-100"
            id="best-deals"
        >
            <Card className="w-100 h-100"
                classes={{ root: classes.card }}
            >
                <div className="d-flex w-100 justify-content-end h-15">
                    <Paper classes={{ root: classes.tag }} className='d-flex rounded-circle h-100 p-2 mt-4'>
                        <Typography classes={{ root: classes.tagColor }} variant="h5" component="div" className="font-weight-bold text-ellipsis-clamp-1 h-100 d-flex align-items-center">
                            {discountTag ? ` ${discountTag}` : ""}
                        </Typography>
                        {
                            discountTag &&
                            <Typography component="div" classes={{ root: classes.tagColor }}
                                className="h-50 pb-2">
                                <h4 className="h-100">%</h4>
                                <h6 className="h-100">off</h6>
                            </Typography>
                        }
                    </Paper>
                </div>
                <Paper elevation={1} square={true} className="h-75 mr-4"
                    classes={{ root: classes.box }}
                    style={{
                        backgroundImage: image ? `url(${image})` : "",
                        backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <div className='d-flex w-100 justify-content-start h-25'>
                        <div className="m-3">
                            {
                                brandImageTag ?
                                    <img src={brandImageTag} height={imageTagHeight ? imageTagHeight : "100%"} width={imageTagWidth ? imageTagWidth : "100%"} />
                                    :
                                    brandNameTag ?
                                        <h6 style={{ color: tagColor ? tagColor : "#000" }} className="text-uppercase text-ellipsis-clamp-1">{brandNameTag}</h6>
                                        :
                                        null
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-end h-90' >
                        <Typography variant="body2" color="text.secondary" className="text-center h-25 bg-white">
                            <CountDownTimer  secondsRemaining="172800" />
                        </Typography>
                    </div>
                </Paper>
                <Paper className="h-10" >
                    <div className="h-100 text-center p-2">
                        <Typography gutterBottom variant="subtitle1" component="div" className="text-capitalize text-ellipsis-clamp-1 content-style">
                            {brandName}
                        </Typography>
                    </div>
                </Paper>
            </Card>
        </div>
    )
}

export default BestDeal
