import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { readTextFile } from 'view.updater/middlewares/util/readTextFile';
// import LoadingIndicator from 'components/common/loaders/loading.indicator';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { appTheme } from './material.theme/app.theme/app.material.theme';
import CssBaseline from '@mui/material/CssBaseline';
// import { Detector } from "react-detect-offline";
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { cacheClear } from 'components/common/util/cache.clear';
import { initializeIndexedDb } from 'components/common/util/indexed.db.util';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import AppNavbarV2 from 'components/common/app.navbar.v2/app.navbar.v2'
import FooterV1 from 'components/common/footer.v1/footer.v1';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import AppNavbar from 'components/common/app.navbar/app.navbar'
export const AppContext = React.createContext();

const Container = (props) => {
    const { translations, locale, language, auth } = props;
    const useStyles = makeStyles(theme => ({
        content: {
            flexGrow: 1,
            // height: '100vh',
            // overflow: 'auto',
        },
        appBarSpacer: appTheme.mixins.toolbar,
    }))
    const onCheckOut = () => {
        props.push('/checkout/cart-summary');
    }
    const classes = useStyles();
    const intlProps = {
        defaultLocale: 'en',
        messages: translations,
        key: locale,
        locale: locale || 'en'
    };
    const intls = Object.keys(translations).length > 0 ? intlProps : { locale: 'en' };

    useEffect(() => {
        initializeIndexedDb();
        window.sessionStorage.setItem('appVersion', window.localStorage.getItem('appVersion')) //VERSION_CHANGES
        cacheClear();
        readTextFile(`${process.env.PUBLIC_URL}/localization/localization.${language}.json`, (localizationContent) => {
            let localization = JSON.parse(localizationContent);
            FingerprintJS.load().then((fp) => {
                fp.get().then((result) => {
                    const visitorId = result.visitorId;
                    window.localStorage.setItem("visitorId", visitorId);
                });
            })
            props.setLocalizationMessages(localization.messages, language);
        });
        // if(props.auth && !props.auth.get('user')){
        //     props.push('/login');
        // }
    }, []);

    useEffect(() => {
        readTextFile(`${process.env.PUBLIC_URL}/localization/localization.${language}.json`, (localizationContent) => {
            let localization = JSON.parse(localizationContent);
            props.setLocalizationMessages(localization.messages, language);
        });
    }, [language]);
    return (
        <IntlProvider {...intls}>
            {
                // Object.keys(translations).length > 0 && translations['home'] !== undefined ?
                <AppContext.Provider value={{
                    executions: props.executions
                }} >
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={appTheme}>
                            <CssBaseline />
                            {auth && auth.size > 0 ?
                                <div className='sticky top-0 z-index-1200'>
                                    <AppNavbar {...props} onCheckOut={onCheckOut} />
                                </div>
                                :
                                <div className='sticky top-0 z-index-1200'>
                                    <AppNavbarV2  {...props} />
                                </div>
                            }
                            {/* <div className={classes.appBarSpacer} /> */}
                            <main className={clsx(classes.content, 'min-h-100-vh')}>
                                {props.children}
                            </main>
                            <FooterV1  {...props} />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </AppContext.Provider>
                // :< LoadingIndicator />
            }
        </IntlProvider>
    );
}
export default connect(null, { push })(Container);

