import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme=>({
    card: {
        border: "none !important",
        borderRadius: "none !important",
        boxShadow: "none !important"
    },
    box: {
        backgroundColor: theme.palette.bannerColor.main,
        boxShadow: "none !important"
    },
    tag:{
       backgroundColor:theme.palette.secondary.main
    },
    tagColor :{
        color:theme.palette.background.paper,
    }

}))
