import './shopping.cart.item.scss'
import React from 'react'
import { Card, CardContent, CardMedia, Typography, IconButton, Button } from '@mui/material';
import QuantityButtonCounter from 'components/common/quantity.button.counter/quantity.button.counter';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx';
import { useStyle } from './shopping.cart.item.style'

const ShoppingCartItem = (props) => {
    const mobileWidth = IsMobileWidth()
    const { image, productName, description,
        price, totalPrice, product, discountAmount, quantity, addProductToCartId, execStatusAddUpdateProductToCart, execStatusGetCartProducts } = props

    const isAddingToCartInProgress = () => {
        if (execStatusAddUpdateProductToCart.status === "PENDING" &&
            addProductToCartId === product.get('query_id')) {
            return true;
        }
        else if (execStatusGetCartProducts.status === "PENDING" &&
            addProductToCartId === product.get('query_id')) {
            return true
        }
        else
            return false
    }
    const showProductDetail = () => {
        props.redirectTo(`/products/details/${product.get('merchant_query_id')}/${product.get('category_id')}/${product.get('sub_category_id')}/1/${product.get('query_id')}/1`)
    }
    const classes = useStyle()
    return (
        <div id="shopping-cart-item">
            <Card className={clsx(!mobileWidth && "w-100 h-100 d-flex paper-root border", mobileWidth && 'w-100 h-100 d-flex paper-root')}>
                <CardMedia
                    component="img"
                    sx={{ width: 110, height: 110 }}
                    image={image ? image : ""}
                    alt="shopping cart Item"
                    className='p-3 rounded cart-img'
                />
                <CardContent className={clsx(!mobileWidth && 'h-100 w-100 p-1 d-flex', mobileWidth && 'h-100 w-100 p-0 d-block')}>
                    <div className={clsx(!mobileWidth && 'w-35', mobileWidth && 'w-100 p-0  pl-2')}>
                        <Typography gutterBottom variant="h6" className='pt-2 font-weight-bold text-ellipsis-clamp-1 h-auto'>
                            {productName ? productName : ""}
                        </Typography>
                        <div className="d-flex flex-column">
                            {
                                description ?
                                    <div>
                                        <Typography variant="body1" className="text-ellipsis-clamp-2  font-weight-normal text-capitalize">
                                            {description ? description : ""}
                                        </Typography>

                                    </div> : ''
                            }

                            <div className="pb-2 cursor-pointer" onClick={showProductDetail}>
                                <div className="text-primary cursor-pointer">
                                    View Details
                                </div>
                            </div>
                        </div>
                        {/* <div className='d-flex align-items-end mt-4 ml-n2'>
                            <Typography variant="subtitle2" className=' text-ellipsis-clamp-1 h-auto'>
                                <IconButton size="small" className='material-icons'>
                                    favorite_border
                                </IconButton>
                                Save for later .
                            </Typography>

                        </div> */}
                    </div>
                    <div className={clsx("pl-2", !mobileWidth && 'd-flex justify-content-between mt-5 w-65', mobileWidth && 'd-flex w-100 p-2 flex-column')}>
                        {price ? <Typography classes={{
                            root: discountAmount ? classes.oldPriceStyle : ''
                        }} variant="subtitle1" component="div" className={clsx(!mobileWidth && 'font-weight-bold text-style', mobileWidth && 'font-weight-bold pb-1')}>
                            {price}
                        </Typography> : ''}
                        <Typography variant="subtitle1" component="div" className={clsx(!mobileWidth && 'font-weight-bold text-style', mobileWidth && 'font-weight-bold pb-1')}>
                            {discountAmount ? discountAmount : ''}
                        </Typography>
                        <Typography variant="subtitle1" component="div" className={clsx(!mobileWidth && 'font-weight-light', mobileWidth && 'pb-1')}>
                            <QuantityButtonCounter
                                maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                isAddingToCartInProgress={isAddingToCartInProgress}
                                onQuantityChange={props.onQuantityChange}
                                value={quantity} />
                        </Typography>
                        <Typography variant="subtitle1" component="div"
                            className={clsx(!mobileWidth && 'font-weight-bold text-style pl-2', mobileWidth && 'font-weight-bold')} >
                            {totalPrice ? totalPrice : ""}
                        </Typography>
                        {/* <Typography variant="subtitle1" component="div"
                            className='font-weight-light material-icons pr-2 cursor-pointer' onClick={() => props.openDialog()}>
                            close
                        </Typography> */}
                    </div>
                </CardContent>
                <IconButton
                    onClick={props.onItemDelete}
                    style={{
                        width: "30px",
                        height: "30px",
                        display: "flex"
                    }} size="small" className='material-icons'>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`} width='24px' />
                </IconButton>
            </Card>
        </div>
    )
}
export default ShoppingCartItem

