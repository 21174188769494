import React from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import Login from 'containers/login/index';

const LoginPageDialog = (props) => {
    const { isLoginPageDialogOpen } = props
    return (
        <div>
            <ContentDialog
                open={isLoginPageDialogOpen}
                maxWidth='xs'
                handleClose={props.hideLoginPageDialog}
                dividers={false}
            >
                <Login {...props} />
            </ContentDialog>
        </div>
    )
}

export default LoginPageDialog