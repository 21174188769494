import './app.sidebar.v2.scss';
import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { MenuItem, Typography } from '@mui/material';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import { useStyle } from "./app.sidebar.v2.style";
import clsx from 'clsx';
import { useLocation } from 'react-router';

export default function AppSidebarV2(props) {
    const { isDrawerOpen } = props
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    let location = useLocation();
    const isActive = (url) => (
        location.pathname === url ? true : false
    )
    const redirect = (url) => {
        props.push(url);
        props.hideDrawer();
    }

    return (
        <div >
            <React.Fragment>
                <Drawer
                    id='sidebar'
                    anchor='left'
                    open={isDrawerOpen}
                    onClose={props.hideDrawer}
                    className={(classes.drawer)}
                >
                    <Box
                        role="presentation"
                        className={clsx(tabletWidth ? classes.contentOnTablet : mobileWidth ? classes.contentOnMobile : classes.contentOnTablet, 'h-100')}
                    >
                        <MenuItem>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')} onClick={() => redirect('/about-us')}
                                className={clsx(classes.textStyle, `cursor-pointer text-center ${isActive('/about-us') ? 'active' : 'in-active'}`)}>
                                About us
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/riders')} className='mt-3'>
                            <Typography variant={clsx((!mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')} className={clsx(classes.textStyle, `cursor-pointer text-center  ${isActive('/riders') ? 'active' : 'in-active'}`)}>
                                Riders
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/merchants')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')} className={clsx(classes.textStyle, `cursor-pointer text-center  ${isActive('/merchants') ? 'active' : 'in-active'}`)}>
                                Merchant partners
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/careers')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')} className={clsx(classes.textStyle, `cursor-pointer text-center  ${isActive('/careers') ? 'active' : 'in-active'}`)}>
                                Careers
                            </Typography>
                        </MenuItem>
                        {/* <MenuItem onClick={() => redirect('/news')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')} className={clsx(classes.textStyle, `cursor-pointer text-center  ${isActive('/news') ? 'active' : 'in-active'}`)}>
                                News
                            </Typography>
                        </MenuItem> */}
                    </Box>
                </Drawer>
            </React.Fragment>
        </div >
    );
}
