import { connect } from 'react-redux';
import { makeSelectLocalizationMessages, makeSelectLocalizationLanguage }
  from 'view.updater/selectors/localization.selectors';
import { createStructuredSelector } from 'reselect';
import Main from './main';
import { changeLanguage, setAppProperties } from 'view.updater/actions/app.actions';
import { languageSelector, appPropertiesSelector } from 'view.updater/selectors/app.selectors'
import { makeSelectAuth, makeSelectAddressUpdated, makeSelectAddressDeleted } from 'view.updater/selectors/auth.selectors';
import {
  resetAuth, logout, updateUserAuthData, createAddress, deleteAddress,
  updateAddress, resetCreateAddress, resetDeleteAddress
} from 'view.updater/actions/auth.actions';
import { makeSelectCartProducts } from 'view.updater/selectors/products.selectors';
import { deleteAllCartProducts } from 'view.updater/actions/products.actions';

// LOGIN_CHANGES

const mapStateToProps = createStructuredSelector({
  translations: makeSelectLocalizationMessages(),
  locale: makeSelectLocalizationLanguage(),
  language: languageSelector(),
  appProperties: appPropertiesSelector(),
  auth: makeSelectAuth(),
  cartProducts: makeSelectCartProducts(),
  addressUpdated: makeSelectAddressUpdated(),
  addressDeleted: makeSelectAddressDeleted()
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (language) => dispatch(changeLanguage(language)),
  setAppProperties: (data) => dispatch(setAppProperties(data)),
  resetAuth: () => dispatch(resetAuth()),
  resetCreateAddress: () => dispatch(resetCreateAddress()),
  resetDeleteAddress: () => dispatch(resetDeleteAddress()),
  logout: () => dispatch(logout()),
  createAddress: (data) => dispatch(createAddress(data)),
  deleteAddress: (data,addressDetails) => dispatch(deleteAddress(data,addressDetails)),
  updateAddress: (data) => dispatch(updateAddress(data)),
  deleteAllCartProducts: () => dispatch(deleteAllCartProducts()),
  updateUserAuthData: (data) => dispatch(updateUserAuthData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);