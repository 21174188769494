import './stepper.scss'
import React from 'react'
import { Stepper, Step, StepLabel, Button } from '@mui/material';

export default function MaterialStepper(props) {
    const { steps, activeStep } = props;
    return (
        <div id="stepper">
            <Stepper activeStep={activeStep} className="stepper">
                {
                    steps && steps.map((data, index) => {
                        return (
                            <Step key={index}>
                                <StepLabel
                                    StepIconProps={{
                                        classes: {
                                            root: 'icon'
                                        }
                                    }}>
                                    <Button
                                        onClick={() => props.onStepChange(index)}
                                        variant={activeStep === index ? "contained" : "outlined"}
                                        color="primary">
                                    {data}
                                    </Button>
                                </StepLabel>
                            </Step>
                        )
                    })
                }
            </Stepper>
        </div>
    )
}
