import './app.navbar.v2.scss';
import React from 'react'
import { AppBar, Box, Toolbar, IconButton, Typography, Container, Button, } from "@mui/material";
import { useStyle } from "./app.navbar.v2.style";
import { IsMobileWidth, IsTabletWidth, scrollToTop } from 'components/common/util/util';
import clsx from 'clsx';

const AppNavbarMobileV2 = (props) => { //PRE_LOGIN_NAVBAR
    const { isDrawerOpen } = props
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const classes = useStyle()
    const redirect = (url) => {
        props.push(url);
        scrollToTop()
    }
    return (
        <AppBar id='app-navbar' position="sticky" className={clsx((!mobileWidth || tabletWidth) && classes.appNavbarTablet, mobileWidth && classes.appNavbarMobile)}>
            <Container maxWidth="lg" classes={{ root: classes.container }}>
                <Toolbar className="d-flex flex-row justify-content-between align-items-center m-auto">
                    {isDrawerOpen ?
                        <IconButton size="medium" onClick={props.hideDrawer} className='cursor-pointer'>
                            <img
                                src={process.env.PUBLIC_URL + '/assets/icons/close-icon.svg'}
                                width='32px'
                                height='32px'
                            />
                        </IconButton>
                        :
                        <IconButton size="medium" onClick={props.showDrawer} >
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/navbar-menu-icon.svg`}
                                width='32px'
                                height='32px'
                            />
                        </IconButton>
                    }
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ display: { md: "flex" } }}>
                        <div onClick={() => redirect("/home")} className="cursor-pointer">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/zapp.web.logo.svg`}
                                width='52.5px'
                                height='28px'
                            />
                        </div>
                    </Typography>
                    {!tabletWidth ?
                        (<IconButton
                            onClick={props.showLoginPageDialog}
                            // className={clsx(classes.iconStyle)}
                            size="medium">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/user.login.icon-1.png`}
                            // width='15px'
                            // height='15px'
                            />
                        </IconButton>) :
                        (<Button
                            classes={{ root: classes.btn2 }}
                            variant="contained"
                            onClick={props.showLoginPageDialog}
                        >
                            <Typography className="d-flex" classes={{ root: classes.btnText }}>
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/user.svg'}
                                    height='20px'
                                    width='20px'
                                />
                                <span className="pl-1">Sign in</span>
                            </Typography>
                        </Button>)
                    }
                </Toolbar>
            </Container>
        </AppBar >
    )
}

export default AppNavbarMobileV2