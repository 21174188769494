import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    drawer: {
        // backgroundColor: '#E5E5E5',
        marginTop: '55px'
    },
    contentOnTablet: {
        maxWidth: '254px',
        background: theme.palette.purpleColor.normal,
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.15)',
        padding: '24px 42px',
    },
    contentOnMobile: {
        width: '100%',
        background: theme.palette.purpleColor.normal,
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.15)',
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        color: theme.palette.background.paper
    },
    textStyle2: {
        color: theme.palette.dark.main
    },
}))