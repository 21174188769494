import './google.maps.places.scss';
import React, { useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Typography } from '@mui/material';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx';

const GoogleMapsPlacesAutoComplete = (props) => {
    const mobileWidth = IsMobileWidth()
    const [state, setState] = useState({
        address: ''
    })
    const handleChange = address => {
        setState({ ...state, address: address })
    };

    const handleSelect = (address) => {
        setState({ ...state, address: address })
        const request = {
            query: address,
            fields: ["name", "geometry"],
        };
        let service;
        if (props.map)
            service = new props.maps.places.PlacesService(props.map);
        service && service.findPlaceFromQuery(request, (results, status) => {
            if (props.maps && props.map) {
                if (status === props.maps.places.PlacesServiceStatus.OK) {
                    props.onLocationSearch(results[0] && results[0].geometry && results[0].geometry.location);
                    props.map.setCenter(results[0] && results[0].geometry && results[0].geometry.location);
                    props.map.setZoom(15);
                }
            }
        });
        // geocodeByAddress(address)
        //     .then(results => getLatLng(results[0]))
        //     .then(latLng => {
        //         props.map.setCenter(latLng);
        //         props.map.setZoom(15);
        //     })
        //     .catch(error => console.error('Error', error));
    };
    const searchOptions = {
        location: new props.maps.LatLng(24.8608, 67.0104),
        radius: 20,
        types: ['address']
    }
    return (
        <div id="google-maps-places-autocomplete">
            <PlacesAutocomplete
                searchOptions={searchOptions}
                value={state.address}
                onChange={handleChange}
                onSelect={handleSelect}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={clsx(!mobileWidth && 'ml-3 mr-3 position-relative', mobileWidth && 'ml-2 mr-2 position-relative')}>
                        <input
                            {...getInputProps({
                                placeholder: 'Search Places ...',
                                className: 'location-search-input search-input w-100 pt-2 pb-2 pl-3 mt-2 border rounded font-weight-normal',
                            })}
                        />
                        <div className="autocomplete-dropdown-container w-100 position-absolute search-dropdown font-weight-normal">
                            {loading && <Typography className='p-2'>Loading...</Typography>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active pt-2 pb-2 pl-3'
                                    : 'suggestion-item pt-2 pb-2 pl-3';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#CB1C91', cursor: 'pointer', color: '#fff' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}

export default GoogleMapsPlacesAutoComplete