import React from 'react';
import TextField from '@mui/material/TextField'

export default function ProductDescription(props) {
  return (
    <TextField
      label="Enter Description"
      className = "textbox-width"
      multiline = {true}
    />
  )
}

