import { regex } from "components/common/util/regex";

export const validateProductName = (data) => {
    let validationResult = {
        isProductNameInValid: false,
    };
    if (regex.isNotEmpty.test(data) === false) {
        validationResult.isProductNameInValid = true;
    }
    return validationResult
}






