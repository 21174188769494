import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme=>({

  btn: {
    background: theme.palette.secondary.main,
  },

  textColor:{
    color:'lightGray'
  }

}))