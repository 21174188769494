import React, { forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function IframeDialogV2(props) {
    const { open } = props
    return (
        <div className='w-100'>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                maxWidth='lg'
            >
                <DialogContent>
                    {props.children}
                </DialogContent>
            </Dialog>
        </div>
    );
}
