import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    accordion: {
        boxShadow: "none !important",
    },
    btn2: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
}));