import React from 'react'
import SimpleNavigationBox from 'components/common/simple.navigation.box/simple.navigation.box';
import SimpleNavigationBoxCarosel from 'components/common/simple.navigation.box.carousel/simple.navigation.box.carousel';
import CategoryPageCarousel from 'components/common/category.page.carousel/category.page.carousel';
import BestDeals from 'components/common/best.deals/best.deals';
import CategoryPageAdvertismentPanel from 'components/common/category.page.advertisment.panel/category.page.advertisment.panel';
import CategoryPageAdvertismentPanel2 from 'components/common/category.page.advertisment.panel2/category.page.advertisment.panel2';
import BestDealsCarousel from 'components/common/best.deals.carousel.js/best.deals.carousel';
import BestDealsSwipperCarousel from 'components/common/best.deals.swipper.carousel/best.deals.swipper.carousel';
// import ShoppingCart from 'components/common/shopping.cart/shopping.cart';
import SubCategoryBar from '../../components/common/sub.category.bar/sub.category.bar';
const TestCategoryPage = () => {
    return (
        <div>
            <div className='w-100 d-flex justify-content-center'>
                {/* <CheckoutDelivery/> */}
            </div>
            <div>
                <SubCategoryBar />
            </div>
            <div className='w-90 m-auto'>
                <CategoryPageCarousel heading="store name" title="browse collections" titleHeading="men's clothing" bannerHeight="70vh" image="https://lh3.googleusercontent.com/proxy/Zkr2H3d5ZDIX4ZWGQocrtgOAxAahjYaYMjDnuRuNGkJF-Hm_MhaTLFPmnRFRE5YevsWgnidbtuoIqDDgxKW-f4Kfl8OiRtE" />
            </div>
            <div>
                {/* <ShoppingCart /> */}
            </div>
            <div className='mt-5 w-30' style={{ height: "400px" }}>
                <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
            </div>
            <div className='mt-5'>
                <BestDealsCarousel />
            </div>
            <div className='mt-5'>
                <BestDealsSwipperCarousel />
            </div>
            <div className='mt-5'>
                <CategoryPageAdvertismentPanel2 />
            </div>
            <div className='mt-5'>
                <CategoryPageAdvertismentPanel title1="explore" title2="new" mainTitle="Casual Shirts" bannerHeight="60vh" buttonTitle="shop now" image="https://img4.goodfon.com/wallpaper/nbig/6/2b/nikolas-kholt-nicholas-hoult-akter-futbolka-dzhinsy-kurtka-k.jpg" />
            </div>


            <div>
                <SimpleNavigationBoxCarosel showArrows="false" />
            </div>
            <div>
                <SimpleNavigationBox title="Alkaram" image="https://freepngimg.com/thumb/hat/149789-hat-cowboy-png-free-photo.png" />
            </div>

        </div>
    )
}

export default TestCategoryPage
