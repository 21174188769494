import React from "react";
import Appbar from "components/common/app.navbar/app.navbar";
import AppbarDesktop from "components/common/app.navbar/app.navbar.desktop";
import AppbarMobile from "components/common/app.navbar/app.navbar.mobile";
import AdvertismentPannel from "components/common/advertisment.panel/advertisment.panel";
import AdvertismentPannelCarousel from "components/common/advertisment.panel.carousel/advertisment.panel.carousel";
import SubAdvertismentPanel from "components/common/sub.advertisment.panel/sub.advertisment.panel";
import SaleItemBox from "components/common/sale.item.box/sale.item.box";
import SaleItemBoxGrid from "components/common/sale.item.box.grid/sale.item.box.grid";
import CategoryBox from "components/common/category.box.panel/category.box.panel";
import CategoryBoxCarousel from "components/common/category.box.panel.carousel/category.box.panel.carousel";
import CircularNavigationBox from "components/common/circular.navigation.box/circular.navigation.box";
import CircularNavigationBoxCarousel from "components/common/circular.navigation.box.carousel/circular.navigation.box.carousel";
import CircularNavigationBoxGrid from "components/common/circular.navigation.box.grid/circular.navigation.box.grid";
import SaleItemBoxCarousel from "components/common/sale.item.box.carousel/sale.item.box.carousel";
import QuickDeal from "components/common/quick.deals/quick.deal";
import QuickDealsCarousel from "components/common/quick.deals.carousel/quick.deals.carousel";
import SaleItemBoxDetail from "components/common/sale.item.box.detail/sale.item.box.detail";
import SaleItemBoxDetailCarousel from "components/common/sale.item.box.detail.carousel/sale.item.box.detail.carousel";
import ServiceBox from "components/common/service.box/service.box";
import ServiceBoxGrid from "components/common/service.box.grid/service.box.grid";
import InstagramFeed from "components/common/instagram.feed/instagram.feed";
import InstagramFeedGrid from "components/common/instagram.feed.grid/instagram.feed.grid";
import InstagramFeedCarousel from "components/common/instagram.feed.carousel/instagram.feed.carousel";
import BestSellerCarousel from "components/common/best.seller.carousel/best.seller.carousel";
import Footer from "components/common/footer/footer";
import SearchBox from "components/common/search.box/search.box";
import SubAdvertisementPanel2 from "components/common/sub.advertisement.panel.2/sub.advertisement.panel.2";

const TestHomePage = () => {
  return (
    <div className="mt-2">
      <div>
        <Appbar />
      </div>
      <div className="mt-2">
        <AppbarDesktop />
      </div>

      <div className="mt-2">
        <AppbarMobile />
      </div>

      <div className="mt-2">
        <h3 className="text-center p-2">search box</h3>
        <SearchBox />
      </div>

      <div>
        <h3 className="text-center p-2">Advertisment Pannel</h3>
        <AdvertismentPannel />
      </div>
    

      <div>
        <h3 className="text-center p-2">Advertisment Pannel Carousel</h3>
        <AdvertismentPannelCarousel />
      </div>

      <div>
        <h3 className="text-center p-2">SubAdvertisment Panel</h3>
        <SubAdvertismentPanel />
      </div>
      <div>
        <h3 className="text-center p-2">Advertisement Panel 2</h3>
        <SubAdvertisementPanel2 />
      </div>
      <div>
        <h3 className="text-center p-2">SaleItem Box</h3>
        <SaleItemBox
          image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6"
          title="mangolia"
          description="the bag is for sale"
          tag="25%"
        />
      </div>
      <div>
        <h3 className="text-center p-2">SaleItem Box Grid</h3>
        <SaleItemBoxGrid />
      </div>

      <div>
        <h3 className="text-center p-2">Category Box</h3>
        <CategoryBox />
      </div>

      <div>
        <h3 className="text-center p-2">CategoryBoxCarousel</h3>
        <CategoryBoxCarousel />
      </div>

      <div>
        <h3 className="text-center p-2"> CircularNavigationBox </h3>
        <CircularNavigationBox />
      </div>

      <div>
        <h3 className="text-center p-2">Circular Navigation Box Carousel</h3>
        <CircularNavigationBoxCarousel />
      </div>

      <div>
        <h3 className="text-center p-2">Circular Navigation Box Grid</h3>
        <CircularNavigationBoxGrid />
      </div>

      <div>
        <h3 className="text-center p-2">SaleItemBoxCarousel</h3>
        <SaleItemBoxCarousel />
      </div>

      <div>
        <h3 className="text-center p-2">QuickDeal</h3>
        <QuickDealsCarousel />
      </div>

      <div>
        <h3 className="text-center p-2">QuickDeal</h3>
        <QuickDeal image="https://images.unsplash.com/photo-1607345366928-199ea26cfe3e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2hpcnR8ZW58MHx8MHx8&ixlib=rb-1.2.1&w=1000&q=80" />
      </div>

      <div>
        <h3 className="text-center p-2">DetailSaleItemBox</h3>
        <SaleItemBoxDetailCarousel />
      </div>

      <div>
        <h3 className="text-center p-2">SaleItemBoxDetail</h3>
        <SaleItemBoxDetail image="https://www.pngall.com/wp-content/uploads/4/Leather-Bag-PNG-HD-Image.png" />
      </div>

      <div>
        <h3 className="text-center p-2">ServiceBox</h3>
        <ServiceBoxGrid />
      </div>

      <div>
        <h3 className="text-center p-2">ServiceBox</h3>
        <ServiceBox
          icon="event_available"
          title="free returns"
          description="return are free within 9 days"
        />
      </div>

      <div>
        <h3 className="text-center p-2">InstagramFeed</h3>
        <InstagramFeed />
      </div>
      <div>
        <h3 className="text-center p-2">InstagramFeedGrid</h3>
        <InstagramFeedGrid />
      </div>
      <div>
        <h3 className="text-center p-2">InstagramFeedCarousel</h3>
        <InstagramFeedCarousel />
      </div>

      <div>
        <h3 className="text-center p-2">Best Seller Carousel</h3>
        <BestSellerCarousel />
      </div>
      <div>
        <h3 className="text-center p-2">Footer</h3>
        <Footer />
      </div>
    </div>
  );
};

export default TestHomePage;
