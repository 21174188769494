import React, { useEffect, useState } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { Paper, Typography } from '@mui/material';
import { useStyle } from './place.order.dialog.style'
import OrderDetails from './order.details';
import PlaceOrderSuccess from './place.order.success';
import { isValidContactPhone } from './place.order.dialog.validations';
import { IsMobileWidth, formatAmountV1, IsTabletWidth } from 'components/common/util/util'
import clsx from 'clsx';
import { isB2bUser } from 'components/common/util/util';
import FooterV1 from 'components/common/footer.v1/footer.v1';
import PlaceOrderWarningDialog from './place.order.warning.dialog';
import { storeIsInRadius, getSelectedAddressLatLng } from 'components/common/util/util';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const PlaceOrderDialog = (props) => {
    const tabletWidth = IsTabletWidth();
    const mobileWidth = IsMobileWidth()
    const { checkOutInvoiceState, auth, execStatusPlaceOrder, voucherInfo } = props;
    const [state, setState] = useState({
        tipZappMan: "0",
        notesZappMan: "",
        receiverName: auth && auth.get('user')?.get('full_name'),
        contactPhone: auth && auth.get('user')?.get('mobile_number'),
        isValidContactPhoneNo: true,
        showPlaceOrderWarningDialog: false,
        warningMessage: '',
    })
    const showPlaceOrderWarningDialog = (message) => {
        setState({ ...state, showPlaceOrderWarningDialog: true, warningMessage: message })
    }
    const handleClosePlaceOrderWarningDialog = () => {
        setState({ ...state, showPlaceOrderWarningDialog: false, warningMessage: '' })
    }

    useEffect(() => {
        props.getUserVouchers({
            userId: auth && auth.get('user').get('id'),
            merchantQueryId: checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('merchant') && checkOutInvoiceState?.get('invoice')?.get('merchant').get('query_id')
        })
    }, [])
    const classes = useStyle()
    const { isOpen } = props;
    const placeTheOrder = () => {
        let invoiceItemAttributes = [];
        if (checkOutInvoiceState) {
            if (checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('invoice_items')) {
                for (let invItems of checkOutInvoiceState.get('invoice').get('invoice_items')) {
                    invoiceItemAttributes.push({
                        id: invItems.get('id'),
                        product_query_id: invItems.get('product_query_id'),
                        quantity: invItems.get('quantity'),
                        size: invItems.get('size') ? invItems.get('size') : {},
                        color: invItems.get('color') ? invItems.get('color') : {},
                        item_description: invItems.get('item_description'),
                        //instruction: state.notesZappMan
                    })
                }
            }
            const cashChannel = auth && auth?.get('user')?.get('channels')?.find((chn) => chn.get('type') === "Channel::Cash");
            const validationResult = isValidContactPhone({
                ...state,
            })
            setState({
                ...state, ...validationResult,
            })
            if (validationResult.isValidContactPhoneNo) {
                if (auth && auth.get("selectedAddressId")) {
                    if (checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('merchant') &&
                        checkOutInvoiceState?.get('invoice')?.get('merchant').get('address') && checkOutInvoiceState?.get('invoice')?.get('merchant').get('address').get('lat')
                        && checkOutInvoiceState?.get('invoice')?.get('merchant').get('address').get('long') && checkOutInvoiceState?.get('invoice')?.get('merchant').get('service_area_radius') &&
                        !checkStoreInRadius(checkOutInvoiceState?.get('invoice')?.get('merchant').get('address').get('lat'),
                            checkOutInvoiceState?.get('invoice')?.get('merchant').get('address').get('long'),
                            checkOutInvoiceState?.get('invoice')?.get('merchant').get('service_area_radius'))) {
                        showPlaceOrderWarningDialog("Sorry, the store is not delivering in your area")
                    }
                    else {
                        props.placeOrder({
                            invoice: {
                                voucher_id: voucherInfo && voucherInfo?.get('voucher') && voucherInfo?.get('voucher')?.get('id'),
                                invoice_items_attributes: invoiceItemAttributes,
                                checkout_type: checkOutInvoiceState?.get('invoice')?.get('checkout_type'),
                                invoice_note: state.notesZappMan,
                                invoice_tip: state.tipZappMan,
                                address_id: auth && auth.get("selectedAddressId"),
                                recipient_name: state.receiverName ? state.receiverName : auth?.get('user')?.get('full_name'),
                                recipient_phone_number: state.contactPhone ? state.contactPhone : auth?.get('user')?.get('mobile_number')
                            },
                            channel_query_id: cashChannel && cashChannel?.get('query_id')
                        }, checkOutInvoiceState?.get('invoice')?.get('query_id'))
                    }
                }
                else {
                    showPlaceOrderWarningDialog("Please select address")
                }
            }
        }
    }
    const onChange = (name, value) => {
        if (name === "contactPhone")
            setState({ ...state, [name]: value?.replace(/\s/g, '') })
        else
            setState({ ...state, [name]: value })
    }
    const getTotalAmount = () => {
        if (checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('payble_amount') &&
            checkOutInvoiceState?.get('invoice')?.get('payble_amount')) {
            let discountAmount = voucherInfo && voucherInfo?.get('voucher') && voucherInfo?.get('voucher')?.get('discount_amount') ? voucherInfo?.get('voucher')?.get('discount_amount') : 0;
            if (state.tipZappMan) {
                return formatAmountV1(Number(checkOutInvoiceState?.get('invoice')?.get('payble_amount')) + Number(state.tipZappMan) - Number(discountAmount))
            }
            else {
                return formatAmountV1(Number(checkOutInvoiceState?.get('invoice')?.get('payble_amount')) - Number(discountAmount))
            }
        }
    }
    const isOrderPlaced = () => {
        if (checkOutInvoiceState && checkOutInvoiceState?.get('isOrderSuccessfullyPlaced') === true)
            return true;
        else
            return false;
    }
    const trackOrder = () => {
        props.history.push('/order/history');
    }
    const checkStoreInRadius = (storeLat, storeLng, serviceAreaRadius) => {
        let location = getSelectedAddressLatLng(auth);
        if (location.lat && location.long && storeLat && storeLng && serviceAreaRadius) {
            return storeIsInRadius(location.lat, location.long, storeLat, storeLng, serviceAreaRadius)
        }
        else {
            return true;
        }
    }

    return (
        <ContentDialog
            fullScreen={!isOrderPlaced() ? true : false}
            maxWidth={isOrderPlaced() ? 'md' : ""}
            setHeaderBackgroundAsPrimary={true}
            handleClose={props.handleClose}
            actions={
                !isOrderPlaced() ?
                    <div className={clsx("p-2 pt-3 pb-3", !mobileWidth && 'w-100 m-auto d-flex', mobileWidth && 'w-100 m-auto d-block')}>
                        <div className="d-flex align-items-center">
                            {
                                isB2bUser(auth) ?
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/images/zapp-b2b-logo.png'}
                                        width="120"
                                        height="45"
                                    /> :
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/images/zapp-logo.svg'}
                                        width="60"
                                        height="60"
                                    />
                            }
                        </div>
                        <div className='w-100 mr-4 pr-1'>
                            {
                                isOrderPlaced() ?
                                    null :
                                    <div className='d-flex justify-content-end p-2 rounded w-100 m-auto'>
                                        <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold mr-2">
                                            Total
                                        </Typography>
                                        <Typography classes={{ root: classes.textColor }} className="text-capitalize font-weight-bold">
                                            {getTotalAmount()}
                                        </Typography>
                                    </div>
                            }
                            {
                                isOrderPlaced() ?
                                    <div className='d-flex justify-content-end'>
                                        {/* <div className={clsx(!mobileWidth && 'w-25 mr-2', mobileWidth && 'w-40 mr-1')}>
                                        <LoaderButton
                                            classNames='cancel-btn'
                                            type="submit"
                                            variant='outlined'
                                            onClick={props.handleClose}>
                                            Close
                                        </LoaderButton>
                                    </div>
                                    <div className={clsx(!mobileWidth && 'w-25', mobileWidth && 'w-55')}>
                                        <LoaderButton
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={trackOrder}
                                        // loaderPosition="top"
                                        >
                                            Track Order
                                        </LoaderButton>
                                    </div> */}
                                    </div>
                                    :
                                    <div className='d-flex justify-content-end'>
                                        <div className={clsx(!(mobileWidth || tabletWidth) && 'w-10 mr-2', tabletWidth && 'w-20 mr-2', mobileWidth && 'w-35 mr-1')}>
                                            <LoaderButton
                                                classNames='cancel-btn p-2'
                                                type="submit"
                                                variant='outlined'
                                                onClick={props.handleClose}
                                            >
                                                Cancel
                                            </LoaderButton>
                                        </div>
                                        <div className={clsx(!(mobileWidth || tabletWidth) && 'w-25', tabletWidth && 'w-35', mobileWidth && 'w-65')}>
                                            <LoaderButton
                                                classNames='p-2'
                                                type="submit"
                                                onClick={placeTheOrder}
                                                loading={execStatusPlaceOrder.status == "PENDING" ? true : false}
                                                color='primary'
                                            // loaderPosition="top"
                                            >
                                                Place Your Order
                                            </LoaderButton>
                                        </div>
                                    </div>
                            }
                            {
                                execStatusPlaceOrder.status === "ERROR" ?
                                    <div className="w-100">
                                        <div className="d-flex pt-2 font-weight-bold text-danger justify-content-end w-100">
                                            {execStatusPlaceOrder?.errorDetail?.get('message')}
                                        </div>
                                    </div> : null
                            }
                        </div>
                    </div> : null
            }
            open={isOpen}
            title={<Paper elevation={0} classes={{ root: classes.header }} className="w-90 m-auto d-flex align-items-center rounded-3">
                <div>
                    {/* <img
                        width={52}
                        height={48}
                        src={`${process.env.PUBLIC_URL}/assets/icons/download-icon.svg`}
                    /> */}
                </div>
                {isOrderPlaced() ?
                    <div className="d-flex align-items-center">
                        {
                            isB2bUser(auth) ?
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/zapp-b2b-logo-white.png'}
                                    width="120"
                                    height="45"
                                /> :
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/zapp.web.logo.svg'}
                                    width="60"
                                    height="60"
                                />
                        }
                    </div>
                    : null}
                <Typography classes={{ root: classes.headerText }} className="pt-2 pb-2 font-weight-bolder w-90 text-center">
                    <b>
                        {
                            isOrderPlaced() ?
                                "Order Placed" : "Place Your Order"
                        }
                    </b>
                </Typography>
            </Paper>}>
            {execStatusPlaceOrder.status == "PENDING" ?
                <div>
                    <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: '#cdcdcde3' }}
                        open={execStatusPlaceOrder.status == "PENDING" ? true : false}
                        className='flex-column'
                    >
                        <Typography component='div' variant={clsx(!(mobileWidth || tabletWidth) && "h5", (mobileWidth || tabletWidth) && "body1")} color="text.primary" className='mb-5'>
                            We are placing your order, please wait
                        </Typography>
                        <CircularProgress color="primary" />
                    </Backdrop>
                </div>
                : null}
            {
                isOrderPlaced() ?
                    <PlaceOrderSuccess
                        {...props}
                        getTotalAmount={getTotalAmount} /> :
                    <OrderDetails onChange={onChange}
                        {...state}
                        {...props}
                        selectedAddressId={auth && auth.get("selectedAddressId")} />
            }
            {
                !isOrderPlaced() ?
                    <div className='mt-2'>
                        <FooterV1 {...props} />
                    </div> : null
            }
            {
                state.showPlaceOrderWarningDialog === true ?
                    <PlaceOrderWarningDialog
                        open={state.showPlaceOrderWarningDialog}
                        handleClose={handleClosePlaceOrderWarningDialog}>
                        <div className="pb-3 font-weight-bold text-center">
                            <div>
                                <div className='pt-3'>
                                    {state.warningMessage}
                                </div>
                            </div>
                        </div>
                    </PlaceOrderWarningDialog> : null
            }
        </ContentDialog>
    )
}
export default PlaceOrderDialog