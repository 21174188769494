import { useStyle } from "./review.order.style";
import "./review.order.scss";
import React from "react";
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Radio,
} from "@mui/material";
// import CommonInput from "components/common/common.input/common.input";
import OrderPanel from "components/common/order.panel/order.panel";
import SiteLabelTextField from "components/common/site.label.textfield/site.label.textfield";

const ReviewOrder = () => {
  const classes = useStyle();

  return (
    <Grid container id="review-order">
      <Grid item sm={12} lg={6} className="p-3">
        <Typography variant="h5" className="mb-3">
          Review Order
        </Typography>
        <div className="p-3 border-outline">
          <Typography variant="h6">General Information</Typography>

          <div className="mb-4">
            <SiteLabelTextField
              startAdornment="Email:"
              placeholder="khaismah.ilyas@cloudasset.com"
              endAdornment="change"
            />
          </div>
          <div className="mb-4">
            <SiteLabelTextField
              startAdornment="Ship to:"
              placeholder="Address"
              endAdornment="change"
            />
          </div>

          <div className="mb-4">
            <SiteLabelTextField
              startAdornment="Method:"
              placeholder="Free Delivery"
            />
          </div>
          <div className="mb-4">
            <SiteLabelTextField startAdornment="Payment:" placeholder="Cash" />
          </div>
        </div>
        <div className="p-3 mt-5 border-outline">
          <Typography className="mb-3" variant="h6">
            Billing Address
          </Typography>
          <div className="mb-4 unique">
            <SiteLabelTextField
              startAdornment={
                <FormControlLabel
                  control={<Radio />}
                  label="Same as shipping address"
                  className="radio"
                />
              }
            />
          </div>
          <div className="mb-4 unique">
            <SiteLabelTextField
              startAdornment={
                <FormControlLabel
                  control={<Radio />}
                  label="Use a different billing address"
                  className="radio"
                />
              }
            />
          </div>
        </div>

        <Button
          className="w-100 mt-4"
          classes={{
            root: classes.btn,
          }}
        >
          Place Your Order
        </Button>
      </Grid>
      <Grid item sm={12} lg={6} className="p-3">
        <OrderPanel />
      </Grid>
    </Grid>
  );
};

export default ReviewOrder;
