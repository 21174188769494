import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    hover:{
        '&:hover':{
            backgroundColor:theme.palette.primary.main,  
        }
    },
    hoverOpacity : {
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            opacity:0.7
        }
    },
    border: {
        //border: `1.2px solid ${theme.palette.primary.light}`,
    },
    boxContainer: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down('lg')]: {
            justifyContent: "center",
            alignItems: "center"
        }
    },
    checkIcon: {
        backgroundColor: "white !important",
        border: `3px solid ${theme.palette.primary.main}`,
        position: "absolute !important",
        right: -10,
        top: -10,
        fontSize: '12px !important',
        zIndex : 1,
        color: `${theme.palette.primary.main} !important`,
    }
}));