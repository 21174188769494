export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_FORGOT_PASSWORD = "RESET_FORGOT_PASSWORD";
export const RESET_FORGOT_PASSWORD_CALL = "RESET_FORGOT_PASSWORD_CALL"

export function resetForgotPassword() {
    return {
        type: RESET_FORGOT_PASSWORD,
        payload: {
            resetActionNames: ["FORGOT_PASSWORD"]
        }
    }
}
export function resetForgotPasswordCall() {
    return {
        type: RESET_FORGOT_PASSWORD_CALL,
        payload: {
            resetActionNames: ["FORGOT_PASSWORD"]
        }
    }
}
export function forgotPassword(data) {
    switch (data.activeStep) {
        case 1:
            return {
                url: "users/forgot_password",
                type: FORGOT_PASSWORD,
                data,
                payload: { ...data },
                method: "POST",
            };
        case 2:
            return {
                url: "users/reset_password",
                type: FORGOT_PASSWORD,
                data,
                payload: { ...data },
                method: "POST",
            };
        default:
            return {
                type: FORGOT_PASSWORD
            }
    }
}