//import 'whatwg-fetch';
import axios from 'axios';
import { getSelectedAddressLatLng } from 'components/common/util/util';
import { SERVER_URL } from './util/api.config';
//import { getLocalStorageValue } from '../../components/common/util/util';
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  // if (response && response.headers) {
  //   if (response.headers["content-type"] === "application/octet-stream") {
  //     if (response.config.url.includes('api/v1/customer/avatar') || response.config.url.includes('api/v1/transactions/export') || response.config.url.includes('api/v1/transactions/export/single')) // for user profile image and export transaction
  //       return response.data && response.data();
  //     else
  //       response.data && response.data.text();
  //   }
  // }
  if (response && response.code === 401) {
    return {
      code: 401,
      error: response.error,
      message: "Session Expired"
    }
  }
  if (response && response.code === 419) {
    return {
      code: 419,
      message: "Session Expired"
    }
  }
  if (response.status === 402 || response.status === 400 || response.status === 500 || response.status === 404) {
    if (response.status === 500 && response.config) {
      if (response.config.actionType === "DOWNLOAD_INVOICE_RECEIPT") {
        return response.data.text().then((res) => {
          return JSON.parse(res)
        })
      }
      else if (response.data.error && response.data.error.message) {
        return response.data
      }
      else if (response.data.error && (typeof response.data.error === 'string' || response.data.error instanceof String)) {
        return {
          error: {
            message: response.data.error
          }
        }
      }
      else
        return {
          error: response.data
        }
    }
    else if (response.data.error && response.data.error.message) {
      return response.data
    }
    else if (response.data.error && (typeof response.data.error === 'string' || response.data.error instanceof String)) {
      return {
        error: {
          message: response.data.error
        }
      }
    }
    else
      return {
        error: response.data
      }
  }
  if (response.status === 500) {
    if (response && response.data) {
      return response.data;
    }
    else {
      return {
        error: {
          code: 500,
          description: "Internal Server Error"
        }
      }
    }
  }

  if (response && response.code === 204 || response && response.code === 205) {
    return null;
  }
  return response && response.data;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 500) {
    return response;
  }
  // if (response.status === 403) { // for un authorized messages 
  //   return {
  //     code: 401,
  //     message: "Session Expired"
  //   }
  // }

  if (response.status === 400 || response.status === 402 || response.status === 404) {
    return response;
  }

  if (response.status === 401 || response.status === 419 || response.status === 403) {
    return {
      code: 401,
      error: response.data.error,
      message: "Session Expired"
    }
  }
  if (response.status === 419) {
    return {
      code: 419,
      message: "Session Expired"
    }
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options, auth) {
  //const cancelTokenSource = axios.CancelToken.source();
  if (options.isLocal === true) {
    url = `${process.env.PUBLIC_URL}/${url}`;
  }
  else
    url = `${SERVER_URL}/${url}`;
  if(options.useGridFormService === true) {
    url = options.actionUrl;
  }
  if (options.body) {
    if (options.contentType === "multipart/form-data") {
      let data = {};
      data = new FormData();
      for (var item in options.body) {
        data.append(item, options.body[item])
      }
      options.data = data;
    }
    else {
      options.data = options.body;
    }
  }
  if (options.contentType == "multipart/form-data") {
    options.headers = {
      //'Authorization': `Bearer ${auth.get('token')}`
    };
  }
  else if (auth && auth.get('user') && auth.get('user').get('session_ktb_token')) { 
    let location = getSelectedAddressLatLng(auth);//LOGIN_CHANGES
    if (options.actionType && options.actionType === "DOWNLOAD_INVOICE_RECEIPT") // for user profile image and export transaction
      options.responseType = "blob";
    else
      options.responseType = "json";
    options.headers = {
      'X_LATITUDE': location && location.lat,
      'X_LONGITUDE': location && location.long,
      'X-LATITUDE': location && location.lat,
      'X-LONGITUDE': location && location.long,
      'content-type': 'application/json',
      //'Authorization': `Bearer ${auth.get('token')}`,
      'X-P3-mWallet-Api-Key': 'z-mwallet',
      //'X-P3-Api-Key': 'mktb',
      'x-device': "DESKTOP",
      'X-UDID': window.localStorage.getItem("visitorId") ? window.localStorage.getItem("visitorId") : 123,
      'X-FIREBASE-TOKEN': 4934,
      'X-P3-mWallet-Session-Token': auth.get('user').get('session_ktb_token')
    };
  }
  else {
    if (options.actionType && options.actionType === "LOGOUT") { // DONE because user not to feel any delay in logout
      options.headers = {
        'content-type': 'application/json',
        'Authorization': `Bearer ${options.data && options.data.get('token')}`
      };
      options.responseType = "json";
      options.data = {}
    }
    else {
      options.responseType = "json";
      options.headers = {
        'content-type': 'application/json',
        'X-P3-mWallet-Api-Key': 'z-mwallet',
        //'X-P3-Api-Key': 'mktb',
        'x-device': "DESKTOP",
        'X-UDID': window.localStorage.getItem("visitorId") ? window.localStorage.getItem("visitorId") : 123,
        'X-FIREBASE-TOKEN': 4934
      };
    }
  }
  options.url = url;
  options.timeout = 95000; // in miliseconds
  const resAxios = axios({
    ...options,
    validateStatus: function (status) {
      return status >= 200 && status <= 700;
    }
  }).then(checkStatus)
    .then(parseJSON)
    .then((response) => {
      return response;
    })
    .catch(error => {
      if (error && error.__CANCEL__ === true) {
        return {
          code: 1,
          message: "Request Cancelled"
        }
      }
      else {
        return {
          code: 0,
          message: "Connection Failed"
        }
      }
    });
  return resAxios;
  // return axios(({...options})
  //   .then(checkStatus)
  //   .then(parseJSON)
  //   .then((response) => {
  //     return response
  //   }))
}
