import React from 'react'
import { useStyle } from './category.box.panel.style'
import { Box, Typography, Button } from '@mui/material';
const CategoryBoxPanel = (props) => {
    const classes = useStyle()

    const { image, tag, title, buttonTitle, width, height } = props
    return (
        <div className="W-100 h-100"
            style={{
                backgroundImage: image ? `url(${image})` : "url(https://media.istockphoto.com/photos/attractive-young-man-with-poses-in-blank-black-cotton-tshirt-standing-picture-id1125110775?k=20&m=1125110775&s=612x612&w=0&h=x__eQGI9Ce9OL1EW6a8yGIdu94BlVg7tSnpjSqw5FFA=)",
                backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                backgroundRepeat: "no-repeat",
            }}
        >
            <Box
                sx={{ height: "500px" }}
                className="paper-root w-100"
            >
                <div className="p-3 d-flex justify-content-start">
                    <Typography
                        classes={{
                            root: classes.tagStyle
                        }}
                        variant="subtitle2" className={tag ? 'p-1 pl-2 pr-2 text-uppercase font-weight-bold mt-1' : ""}
                    >
                        {tag ? tag : ""}
                    </Typography>
                </div>
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <div className="text-center">
                        <Typography
                         classes={{
                            root: classes.titleStyle
                        }}
                         variant="h4" gutterBottom component="div" className="font-weight-bold pb-2 text-capitalize">
                            {title ? title : "Men"}
                        </Typography>
                        <Typography>
                            <Button variant="contained" size="large" className="text-uppercase"
                                classes={{
                                    root: classes.muibutton
                                }}
                            >
                                {buttonTitle ? buttonTitle : "Shop Now"}</Button>
                        </Typography>
                    </div>
                </div>
            </Box >
        </div >
    )
}

export default CategoryBoxPanel
