import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
// Import root app
import App from './containers/app';
import 'sanitize.css/sanitize.css';
import configureStore from './configureStore';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './service.worker';
import TagManager from 'react-gtm-module';
// Create redux store with history
import { initialState } from 'view.updater/reducers/init';
import { ENV } from './view.updater/middlewares/util/api.config';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  document.body.classList.remove('fontLoaded');
});
const tagManagerArgs = {
    gtmId: ENV === "PROD" ? "GTM-KW7NTHN" : "GTM-KK3PMVG"
}
TagManager.initialize(tagManagerArgs)

export const history = createBrowserHistory();
export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App store={store} history={history} />
        </ConnectedRouter>
      </Provider>
    </React.StrictMode>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/app'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();
serviceWorker.register();
