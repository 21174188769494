import React from 'react';
import TextField from '@mui/material/TextField'

export default function ProductPrice(props) {
  return (
    <TextField
      label="Enter Price"
      className="textbox-width"
    />
  )
}
