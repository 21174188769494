import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    card_background_image: {
        height: "100% !important ",
        backgroundColor: "#D9D9D9 !important",
        borderRadius: "0px",
    },
    card_background: {
        height: "auto",
        border: "none",
        boxShadow: "none"
    },
    button_group: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            m: 1,
        }
    },
    buttonGroupStyle: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        borderColor: "grey",
        '&:hover': {
            backgroundColor: "#D9D9D9",
        }
    },
    tagColorDetail: {
        background: theme.palette.error.main,
        color: theme.palette.background.paper
    },
    likeBtnDetail: {
        background: theme.palette.background.paper,
    },
    pricingStyle: {
        color: theme.palette.error.main,
        textDecoration:"line-through"
    }

}))
