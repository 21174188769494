import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme=>({

  muilabel: {
    backgroundColor: theme.palette.danger.main,
    color:theme.palette.background.paper,
    fontSize: "22px !important",
   
  }

}))