
import React, { useEffect, useState } from 'react'
import { TextField, Paper, ClickAwayListener, Button, CircularProgress } from '@mui/material'
import './search.panel.scss'
import { useStyles } from './search.panel.style';
import clsx from 'clsx';
import { regex } from 'components/common/util/regex'
import { IsMobileWidth } from 'components/common/util/util';

export default function SearchPanel(props) {
  const { placeholder, label, loading, reset, defaultValue } = props
  const mobileWidth = IsMobileWidth()
  const [state, setState] = useState({
    searchBarIsOpen: true,
    value: defaultValue ? defaultValue : "",
    showCrossButton: false
  })
  const classes = useStyles();

  const onChange = (event) => {
    if (regex.alphanumericWithSpace.test(event.target.value) || event.target.value === "") {
      setState({ ...state, value: event.target.value })
    }
  }

  const closeSearchBar = () => {
    if (!state.value) {
      setState({ ...state, searchBarIsOpen: true }) // was false
    }
  }

  const openSearchBar = () => {
    setState({ ...state, searchBarIsOpen: true })
  }

  const onSearch = (event) => {
    event.preventDefault()
    props.onSearch && props.onSearch(state.value && state.value.trim())
    if (state.value && state.value.trim()) {
      setState({ ...state, value: state.value && state.value.trim(), showCrossButton: true })
    }
    else
      setState({ ...state, value: state.value && state.value.trim(), showCrossButton: false })
  }
  const onClear = (event) => {
    event.preventDefault()
    setState({ ...state, value: "", searchBarIsOpen: true, showCrossButton: false }) // was false
    props.onSearch && props.onSearch("")
  }

  useEffect(() => {
    if (reset === true) {
      setState({ ...state, value: "", searchBarIsOpen: true, showCrossButton: false }) // was false
    }
  }, [reset])

  return (
    <div className={clsx("search unselect", mobileWidth && 'ml-2')}>
      <form>
        <ClickAwayListener onClickAway={closeSearchBar}>
          <div className="d-flex align-items-start">
            {
              state.searchBarIsOpen ? null : <div className="d-flex w-100 align-items-center cursor-pointer" onClick={() => openSearchBar(true)}>
                <div className={clsx(mobileWidth && 'width-max-content')}>{label}</div>
                <span id="showMe" className=" material-icons p-2 paper-root border-radius-100 icon"> search </span>
              </div>
            }
            <div className="w-100">
              <Paper
                elevation={1}
                className={clsx(state.searchBarIsOpen && "transition paper-root  d-flex align-items-center justify-content-between", !state.searchBarIsOpen && "noTransition")}
                classes={{ root: "paper" }}>
                {
                  state.searchBarIsOpen ?
                    <TextField
                      onChange={onChange}
                      value={state.value}
                      autoFocus={true}
                      InputProps={{
                        classes: {
                          inputAdornedStart: classes.paddingLeft,
                          notchedOutline: classes.noBorder,
                          input: classes.input,
                          focused: true
                        }
                      }} variant="outlined" className="w-100" placeholder={placeholder}>
                    </TextField> : null
                }
                {state.searchBarIsOpen ?
                  <React.Fragment>
                    {
                      state.showCrossButton == true ?
                        <Button classes={{
                          root: classes.btn
                        }} onClick={onClear} variant="text" color="secondary" className="pl-1 pr-1 icon" >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/close-icon-secondary.svg`}
                            width={20}
                            height={20}
                            alt='close-icon'

                          />
                        </Button> : null
                    }

                    <Button classes={{
                      root: classes.btn
                    }} onClick={onSearch} variant="text" color="secondary" className="pl-1 pr-1 mr-2 icon" type="submit">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/search-icon-secondary.svg`}
                        width="20"
                        height="20"
                        alt='search-icon'
                      />
                    </Button>
                    {
                      loading === true && state.value ?
                        <div className="pl-1 pr-2">
                          <CircularProgress size={19} />
                        </div> : null
                    }
                  </React.Fragment>
                  : null
                }
              </Paper>
            </div>
          </div>
        </ClickAwayListener>
      </form>
    </div>
  )
}