import React from 'react'
import ItemsCarousel from 'react-items-carousel';

const ImageNavigator = (props) => {
    return (
        <div style={{ "padding": "0 0px", "maxWidth": '100%', "margin": "0 auto" }}>
            <ItemsCarousel
                infiniteLoop={false}
                gutter={4}
                maxWidth="150px"
                activePosition={'center'}
                chevronWidth={19}
                // padding="1"
                padding="0px 0px"
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={3}
                slidesToScroll={2}
                outsideChevron={true}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={2}
                requestToChangeActive={value => console.log(value)}
                rightChevron={<i className="material-icons">chevron_right</i>}
                leftChevron={<i className="material-icons">chevron_left</i>}
            >
               {props.children}
            </ItemsCarousel>
        </div>
    )
}

export default ImageNavigator