import './product.add.style.scss';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import ProductName from './components/product.name';
import ProductCategory from './components/product.category';
import ProductColor from './components/product.color';
import ProductDescription from './components/product.description';
import ProductSize from './components/product.size';
import ProductPrice from './components/product.price';
import ProductBrand from './components/product.brand';

export default function AddProduct(props) {
  return (
    <div id="product-add">
      <Table className="mt-4">
        <TableHead>
          <TableRow>
            <TableCell className="max-table-column-width-0">Product Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <ProductName />
            </TableCell>
            <TableCell>
              <ProductCategory />
            </TableCell>
            <TableCell>
              <ProductBrand />
            </TableCell>
            <TableCell>
              <ProductColor />
            </TableCell>
            <TableCell>
              <ProductSize />
            </TableCell>
            <TableCell>
              <ProductPrice />
            </TableCell>
            <TableCell>
              <ProductDescription />
            </TableCell>
            <TableCell>
              <div className="d-flex">
                <Button
                  className="button-label button-upload"
                  variant="contained">Upload Image<i className="fa fa-upload  pl-1"></i>
                </Button>
                <Button
                  className="button-label  button-add ml-1"
                  variant="contained">Add<i className="fa fa-plus  pl-1"></i>
                </Button>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <ProductName />
            </TableCell>
            <TableCell>
              <ProductCategory />
            </TableCell>
            <TableCell>
              <ProductBrand />
            </TableCell>
            <TableCell>
              <ProductColor />
            </TableCell>
            <TableCell>
              <ProductSize />
            </TableCell>
            <TableCell>
              <ProductPrice />
            </TableCell>
            <TableCell>
              <ProductDescription />
            </TableCell>
            <TableCell>
              <div className="d-flex">
                <Button
                  className="button-label button-upload"
                  variant="contained">Upload Image<i
                    className="fa fa-upload  pl-1"></i>
                </Button>
                <Button
                  className="button-label button-add ml-1"
                  variant="contained">Add<i
                    className="fa fa-plus  pl-1"></i>
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
