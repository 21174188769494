import React from 'react'
import ServiceBox from 'components/common/service.box/service.box';
import { Box, Grid } from '@mui/material'

const ServiceBoxGrid = () => {
    return (

        <Box sx={{ flexGrow: 1 }} className="d-flex justify-content-center align-items-center h-100 w-100">
            <Grid container
                spacing={2}
                className="w-90 h-100 rounded border mt-3 p-2 pb-4">
                <Grid item lg={3} md={3} sm={6} xs={12} >
                    <ServiceBox icon="clean_hands" title="Free delivery" description="on order over 750 PKR" />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} >
                    <ServiceBox icon="event_available" title="free returns" description="return are free within 9 days" />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} >
                    <ServiceBox icon="payments" title="secure payment" description="payments are safe with us" />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} >
                    <ServiceBox icon="headset" title="zapp support 24/7" description="Contact us 24 hours a day" />
                </Grid>
            </Grid>

        </Box>
    )
}

export default ServiceBoxGrid
