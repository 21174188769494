import { put, select, call } from 'redux-saga/effects';
import { addUpdateAppLoadersStatus } from 'view.updater/actions/app.actions';
import { push } from 'connected-react-router';
import { LOGIN, LOGOUT, login, updateUserAuthData, CREATE_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from '../actions/auth.actions';
import { checkOutInvoice, CREATE_INVOICE, deleteAllCartProducts, PLACE_ORDER, GET_STORE_PRODUCTS_CATEGORIES, setSelectedStoreProductCategory, getProducts } from '../actions/products.actions';
import { selectProducts } from '../selectors/products.selectors';
import processIndexedDbQuery from './process.indexed.db.query';
import { selectAuthorization } from '../selectors/auth.selectors';
import { DO_SIGN_UP, doSignUp } from '../actions/sign.up.actions';

const getDefaultAddressId = (ath) => {
    if (ath?.get('auth')?.get('user')?.get('home_address')?.get('id')) {
        return ath?.get('auth')?.get('user')?.get('home_address')?.get('id')
    }
    else if (ath?.get('auth')?.get('user')?.get('work_address')?.get('id')) {
        return ath?.get('auth')?.get('user')?.get('work_address')?.get('id')
    }
    else if (ath?.get('auth')?.get('user')?.get('other_addresses') && ath?.get('auth')?.get('user')?.get('other_addresses').size > 0) {
        return ath?.get('auth')?.get('user')?.get('other_addresses').get(0) ? ath?.get('auth')?.get('user')?.get('other_addresses').get(0).get('id') : ""
    }
    else {
        return ""
    }
}
export default function* allAfterMiddleware(action) {
    if (action.useIndexedDb && action.url) {
        const indexedDbQueryResponse = yield call(processIndexedDbQuery, action);
        if (indexedDbQueryResponse) {
            yield put(addUpdateAppLoadersStatus(action.baseType, 'DONE', action.errorId));
            yield put(addUpdateAppLoadersStatus(action.baseType, 'FINNISH', action.errorId));
            yield put({ ...action, useIndexedDb: false, indexedDbQueryResponse })
        }
    }
    else {
        yield put(addUpdateAppLoadersStatus(action.baseType, 'DONE', action.errorId));
        yield put(addUpdateAppLoadersStatus(action.baseType, 'FINNISH', action.errorId));
    }
    switch (action.baseType) {
        case LOGIN: // LOGIN_CHANGES
            window.document.title = "Zapp PK"
            const ath = yield select((selectAuthorization));
            yield put(updateUserAuthData({
                selectedAddressId: getDefaultAddressId(ath)
            }));
            let industryId = ath && ath.get('auth') && ath.get('auth').get('industry_types') && ath.get('auth').get('industry_types')?.get(0) && ath.get('auth').get('industry_types')?.get(0).get('id');
            if (industryId) {
                yield put(push(`/products?industryId=${industryId}`));
            }
            else {
                yield put(push('/products'));
            }
            break;
        case LOGOUT:
            yield put(push('/home'));
            break;
        case CREATE_ADDRESS:
            const authState = yield select((selectAuthorization));
            if (authState) {
                let authData = authState?.get("auth")?.toJS();
                if (authData) {
                    window.localStorage.setItem("auth", JSON.stringify(authData));
                }
            }
            break;
        case UPDATE_ADDRESS:
            const authSt = yield select((selectAuthorization));
            if (authSt) {
                let authData = authSt?.get("auth")?.toJS();
                if (authData) {
                    window.localStorage.setItem("auth", JSON.stringify(authData));
                }
            }
            break;
        case DELETE_ADDRESS:
            const authS = yield select((selectAuthorization));
            if (authS) {
                let authData = authS?.get("auth")?.toJS();
                if (authData) {
                    window.localStorage.setItem("auth", JSON.stringify(authData));
                }
            }
            break;
        case GET_STORE_PRODUCTS_CATEGORIES:
            const storeProductCategories = yield select((selectProducts));
            const selectedStoreProductCategory = storeProductCategories.get('selectedStoreProductCategory');
            if (!selectedStoreProductCategory) {
                const ctegry = storeProductCategories.get('storeProductCategories').find((category) => category.get('storeQueryId') == action.payload.storeQueryId);
                let ctIndex = ctegry?.get('listCategory')?.get('categories').findIndex((cat) => cat.get('id') == action.payload.categoryId);
                if (ctIndex > -1) {
                    if (action?.response?.categories)
                        yield put(setSelectedStoreProductCategory({
                            ...action.response.categories[ctIndex],
                            storeQueryId: action?.payload?.storeQueryId,
                            queryStringName: action?.payload?.name
                        }))
                }
                else {
                    if (action?.response?.categories)
                        yield put(setSelectedStoreProductCategory({
                            ...action.response.categories[0],
                            storeQueryId: action?.payload?.storeQueryId,
                            queryStringName: action?.payload?.name
                        }))
                }
            }
            else {
                if (action.payload.name) { // for default query string search
                    yield put(getProducts({
                        storeQueryId: action.payload.storeQueryId,
                        categoryId: selectedStoreProductCategory.get('id'),
                        name: action.payload.name,
                        page: 1
                    }))
                }
                else {
                    for (let subCat of selectedStoreProductCategory.get('sub_categories')) {
                        yield put(getProducts({
                            storeQueryId: action.payload.storeQueryId,
                            categoryId: selectedStoreProductCategory.get('id'),
                            subCategoryId: subCat.get('id'),
                            page: 1
                        }))
                    }

                }

            }
            break;
        case CREATE_INVOICE:
            yield put(checkOutInvoice({
                invoiceId: action?.response?.invoice?.query_id
            }))
            break;
        case PLACE_ORDER:
            yield put(deleteAllCartProducts());
            break;
        case DO_SIGN_UP:
            if (action.payload && action.payload.activeStep == 3 && action.payload.pin && action.payload.mobileNumber) {
                yield put(login({
                    login_id: action.payload.mobileNumber?.replace(/\s/g, ''),
                    password: action.payload.pin
                }))
            }
            if (action.payload.activeStep == 1 && action.response && action.response.user && action.response.user.status == "pending") {//if user account is deleted and again signup
                yield put(doSignUp({
                    ...action.data,
                    activeStep: 1,
                    sendOtp: true,
                    userId: action.response.user.query_id
                }));
            }
            break;
        default:
            return;
    }
}