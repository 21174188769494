import { makeStyles } from '@mui/styles'
export const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.secondary.main,
  },
  nooutline: {
    outline: 'none',
  },
  noBorder: {
    border: 'none',
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },
  endAdn: {
    paddingRight: '3.5px'
  },
  startAdn: {
    paddingLeft: '0px'
  },
  // input: {
  //   height: 8,
  //   minWidth: '13%',
  //   textOverflow: "ellipsis"
  // },
  input: {
    textOverflow: "ellipsis",
    fontSize: 16,
    fontWeight: 400,
    boxShadow: 'none',
    fontFamily: 'Montserrat',
    color: 'rgba(0, 0, 0)',
    lineHeight: '20px',
    height: '44px',
    padding: '0px 0px 0px 16px !important',

  },
  mobileInput: {
    fontSize: 12,
    fontWeight: 300,
    boxShadow: 'none',
    fontFamily: 'Montserrat',
    textOverflow: "ellipsis",
    color: 'rgba(0, 0, 0)',
    lineHeight: '20px',
    height: '44px',
    padding: '0px 0px 0px 16px !important',
  },
  inputPassword: {
    width: "90%",
    minWidth: '13%',
    textOverflow: "ellipsis"
  },

  disabled: {
    color: "#000000"
  },
  paperRoot: {
    width: '100%',
    height: 'auto',
    border: '1px',
    position: "relative",
    boxShadow: 'none',
    outline: 'none',
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center'
  }
}))
