import {
    insertDataIntoIndexedDB, updateDataFromIndexedDB,
    deleteDataFromIndexedDB, checkExistingIndexDBKey,
    readDataFromIndexedDB, readAllDataFromIndexedDB,
    deleteAllDataFromIndexedDb
} from "components/common/util/indexed.db.util";

export default function processIndexedDbQuery(action) {
    if (action.indexedDbOperationMode === "INSERT_UPDATE") {
        return checkExistingIndexDBKey(`${action.payload.uniqueId}`).then((res) => {
            if (res.isExist === true) {
                return updateDataFromIndexedDB(`${action.payload.uniqueId}`, { ...action.payload, ...action.response }).then((res) => {
                    return res;
                })
            }
            else {
                return insertDataIntoIndexedDB(`${action.payload.uniqueId}`, { ...action.payload, ...action.response }).then((res) => {
                    return res;
                })
            }
        })
    }
    else if (action.indexedDbOperationMode === "DELETE") {
        return deleteDataFromIndexedDB(`${action.payload.uniqueId}`)
    }
    else if (action.indexedDbOperationMode === "GET") {
        return readDataFromIndexedDB(`${action.payload.uniqueId}`);
    }
    else if (action.indexedDbOperationMode === "GET_ALL") {
        return readAllDataFromIndexedDB();
    }
    else if (action.indexedDbOperationMode === "DELETE_ALL") {
        return deleteAllDataFromIndexedDb();
    }
}