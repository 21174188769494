import React, { useEffect } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getInjectors from './reducerInjectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) => (WrappedComponent) => {
  const ReducerInjector = (props) => {
    const injectors = getInjectors(props.store);
    useEffect(()=>{
      const { injectReducer } = injectors;
      injectReducer(key, reducer);
    },[]);
    return (<WrappedComponent {...props} />)
  }
  return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};
