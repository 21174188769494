import './popover.dropdown.scss';
import React, { useState } from 'react';
import Popover from '@mui/material/Popover'
import Paper from '@mui/material/Paper'
import { PhotoshopPicker } from 'react-color';

export default function PopOverColorDropdown(props) {

  const [state, setState] = useState({
    anchorEl: undefined,
    selectedColor: undefined
  })
  const onDropdownOpen = event => {
    setState({ ...state, anchorEl: event.currentTarget });
  }
  const onColorSelect = (color) => {
    setState({ ...state, selectedColor: color.hex })
    //props.onColorChange(color);
  }
  const open = Boolean(state.anchorEl);
  const { placeholder } = props;
  return (
    <div id="custom-popover-dropdown">
      <Paper
        tabIndex={0}
        className="paper p-3 d-flex justify-content-between align-items-center"
        onClick={onDropdownOpen}>
        <div className="d-flex justify-content-between">
          {state.selectedColor ?
            <div style={{ backgroundColor: state.selectedColor,
              width: "100px", height: "20px"}}></div>
            : placeholder}
        </div>
        <div className="d-flex align-items-center">
          <div className={open ? "fa fa-caret-up" : "fa fa-caret-down"}></div>
        </div>
      </Paper>
      <Popover
        open={open}
        anchorEl={state.anchorEl}
        onClose={() => {
          setState({ ...state, anchorEl: undefined })
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PhotoshopPicker
          header="Select Color"
          color={state.selectedColor}
          className="position-relative"
          onChangeComplete={onColorSelect}
          onAccept={() => setState({ ...state, anchorEl: undefined })}
          onCancel={() => setState({ ...state, anchorEl: undefined, color: undefined })}
        />
      </Popover>
    </div>
  )
}
