import "./sub.advertisement.panel.2.scss";
import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import { useStyle } from './sub.advertisement.panel.2.style'


const SubAdvertisementPanel2 = (props) => {
  const { image, buttonTitle } = props;
  const classes = useStyle()


  return (
    <Box
      sx={{ flexGrow: 1 }}
      className="d-flex justify-content-center h-100 w-100"
      id="sub-advertisment-panel-2"
    >
      <Grid
        container
        style={{
          backgroundImage: image ? `url(${image})` : "",
        }}
        className="w-90 h-100 rounded d-flex justify-content-center pt-3 pb-3"
        classes={{
          root: classes.panelBackground
      }}
      >
        <Grid lg={7} className="d-flex justify-content-end">
          <div>
            <Typography>Enjoy</Typography>
            <Typography variant="h4">
              Up to 15% discount on your first purchase!
            </Typography>
            <Typography variant="h5" className="text-center">
              Use discount code in checkout to get the discount
            </Typography>
          </div>
        </Grid>
        <Grid lg={5}>
          <div className="p-4">
            <Button size="small" className="btn">
              {buttonTitle ? buttonTitle : "FREE15FIRST"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubAdvertisementPanel2;
