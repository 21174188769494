import './simple.navigation.box.scss'
import React from 'react'
import { CardContent, Typography, CardMedia, Paper, Tooltip } from '@mui/material'
import { useStyle } from './simple.navigation.box.style'
const SimpleNavigationBox = (props) => {
    const classes = useStyle()
    const { image, title, selected, width, height } = props
    return (
        <div id="simple-navigation-box">
            <Paper
                onClick={props.onClick}
                classes={{ root: selected ? classes.selectedBox : classes.box }}
                style={{ width: width ? width : "100%", height: height ? height : "100%" }}
                className="text-center paper-root rounded p-1"
            >
                <CardContent className="w-100 d-flex justify-content-center h-75 p-0">
                    <CardMedia
                        className='p-0'
                        component="img"
                        image={image ? image : ""}
                        alt="Brand Logo"
                        height={'100%'}
                        width={'100%'}
                    />
                </CardContent>
                <Tooltip title={title}>
                    <Typography variant="caption" className="text-ellipsis-clamp-1 font-weight-bold text-capitalize text-center">
                        {title ? title : ""}
                    </Typography>
                </Tooltip>
            </Paper >
        </div>
    )
}
export default SimpleNavigationBox
