import React from 'react'
import Slider from "react-slick";
import CircularNavigationBox from '../circular.navigation.box/circular.navigation.box';
import { IsMobileWidth,IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx'
const Circularnavigationboxcarousel = () => {
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    const settings = {
        className: "center",
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 5,
        swipeToSlide: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
           
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="w-100 p-5">
            <Slider {...settings}>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="Alkaram Studio" image="https://whatsonsale.com.pk/sites/default/files/styles/large/public/alkaram.jpg?itok=h7eknj80" />
                    </div>
                </div>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="Bata" image="https://1.bp.blogspot.com/-WTankH4frZQ/YDQ6CBCqugI/AAAAAAAAVxY/OeeINW2EWGgeKhftkNRATJ1pSHdf8naNACLcBGAsYHQ/s2048/Bata%2BShoes2.png" />
                    </div>
                </div>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="the cambridge" brandName="Cambridge" bgColor="white" />
                    </div>
                </div>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="ethnic" brandName="ethnic" bgColor="orange" />
                    </div>
                </div>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="zellbury" image="https://trademarks.justia.com/media/og_image.php?serial=87236004" />
                    </div>
                </div>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="khaadi" image="https://vectorseek.com/wp-content/uploads/2020/12/Khaadi-logo-vector-01-730x730.jpg" />
                    </div>
                </div>
                <div>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "p-1 w-70",tabletWidth && "p-1 w-80",mobileWidth && "p-1 w-100")}>
                        <CircularNavigationBox title="brumano" brandName="brumano" bgColor="purple" />
                    </div>
                </div>

            </Slider>
        </div>
    )
}

export default Circularnavigationboxcarousel
