import "./footer.scss";
import React from "react";
import Grid from "@mui/material/Grid";
export const Footer = () => {

  return (
    <div id="footer">
      <Grid container className="footer">
        <Grid item xs={12}>
          <div className="mb-4 w-20">
            <img
              src={process.env.PUBLIC_URL + '/assets/images/zapp-logo.svg'}
              alt="zapp logo"
              width="90"
              height="90"
            />
          </div>
        </Grid>
        <Grid item sm={4} md={4} xs={12} lg={6} className="mb-4">
          <div className="d-flex">
            <div>
              <span className="material-icons mr-2">place</span>
            </div>
            <div>
              Mezz. Floor, 228, Boulevard Mall Block-20, <br />
              D.M.C.H.S, Karachi, Pakistan
            </div>
          </div>
          <div className="d-flex mt-3">
            <div>
              <span className="material-icons mr-2">phone_in_talk</span>
            </div>
            <div>021-3713-ZAPP</div>
          </div>
          <div className="mt-3">
            <span className="p-2 social-icons">
              <i class="fab fa-facebook-f"></i>
            </span>
            <span className="p-2 social-icons">
              <i class="fab fa-twitter"></i>
            </span>
            <span className="p-2 social-icons">
              <i class="fab fa-instagram"></i>
            </span>
          </div>
        </Grid>
        <Grid container sm={8} md={8} xs={12} lg={6}>
          <Grid item sm={4} xs={12}>
            <h5 className="mb-4">Information</h5>
            <p>Privacy Policy</p>
            <p>Terms & Conditions</p>
            <p>FAQ's</p>
          </Grid>
          <Grid item sm={4} xs={12}>
            <h5 className="mb-4">Helpful Links</h5>
            <p>Return & Refund</p>
            <p>Track Order</p>
            <p>Contact</p>
          </Grid>
          <Grid item sm={4} xs={12}>
            <h5 className="mb-4">Download Zapp App</h5>
            <div>
              <a href='https://apps.apple.com/pk/app/zapp-shop-anytime-anywhere/id1541819812' target='_blank'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/apple-store-logo.svg`}
                  height='53.33px'
                  width='160px'
                />
              </a>
            </div>
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.app.zapp" target='_blank'>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/google-playstore-logo.svg`}
                  height='48.07px'
                  width='160px'
                />
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className="d-flex justify-content-center pt-1 font-weight-bold">
        © 2022 Zapp All rights reserved
      </div>
    </div>
  );
};
export default Footer;
