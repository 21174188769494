import React from 'react'
import Slider from "react-slick";
import IconButton from '@mui/material/IconButton'
import InstagramFeed from 'components/common/instagram.feed/instagram.feed';
import './instagram.feed.carousel.scss';
const InstagramFeedCarousel = () => {

    function NextArrow(props) {
        const { className, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-n1 carousel6">
                    <i className="fa fa-arrow-right p-1" id="left_arrow_carousel6"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-2 carousel6" >
                    <i className='fa fa-arrow-left p-1' id="left_arrow_carousel6"></i>
                </IconButton>
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div
            className="p-1 w-100 h-100"
            id="instagram-feed-carousel"
        >
            <Slider className="h-100 w-95 m-auto" {...settings}>
                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed backgroundimage="https://img.freepik.com/free-photo/pretty-young-stylish-sexy-woman-pink-luxury-dress-summer-fashion-trend-chic-style-sunglasses-blue-studio-background-shopping-holding-paper-bags-talking-mobile-phone-shopaholic_285396-2957.jpg?size=626&ext=jpg" brandtag="https://upload.wikimedia.org/wikipedia/commons/0/08/Magnolia_dairy_logo.png" />
                    </div>
                </div>
                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed message="50% Sale on alkaram branded suits" backgroundcolor="#02cecc" />
                    </div>
                </div>

                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed backgroundimage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4amixx-a1OUKEFEnIwx8zsJZjbrrU_WO7cwMdpk5Ps9XiUlExXG-OvE0ijv4rtaIc6LQ&usqp=CAU" logoalignment="left" ZLwidth="100px" ZLheight="30px" brandtag="https://nation.com.pk/digital_images/medium/2019-06-04/khaadi-hosts-three-day-long-eid-celebrations-at-flagship-store-1559646846-3890.jpg " />
                    </div>
                </div>
                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed backgroundimage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiofWkXC7TUsayo84oEeWPAxP0KdoPn-flei1fErImKsUZHjhLtnRe-JOUqjU9_jugFfY&usqp=CAU" brandtag="https://e7.pngegg.com/pngimages/306/228/png-clipart-bahria-town-karachi-alkaram-studio-alkaram-textile-mills-retail-brand-chappal-text-retail.png" />
                    </div>
                </div>
                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed message="when i say it order online,it only mean order with zapp it" backgroundcolor="purple" />
                    </div>
                </div>
                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed backgroundimage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCzQj8_uvohTufH2MOqDbpB5If6Gu3q7SD3Uopc1vdd-71xLhfSV8ODWU5hp2KQ2tiNek&usqp=CAU" brandtag="https://upload.wikimedia.org/wikipedia/commons/b/b4/Zellbury_logo.png" logoalignment="right" zappLogoWidth="100px" zappLogoHeight="35px" />
                    </div>
                </div>
                <div>
                    <div className="p-1 carousel6-items">
                        <InstagramFeed message="stay home order online" backgroundcolor="pink" brandtag="https://zapp.world/assets/images/zapp-logo.svg" />
                    </div>
                </div>

                <div>
                    <div className="carousel6-items">
                        <InstagramFeed message="when i say it order online,it only mean order with zapp it" backgroundcolor="purple" />
                    </div>
                </div>
            </Slider >
        </div >
    )
}

export default InstagramFeedCarousel
