import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    subAdvertismentPanel: {
        backgroundColor: theme.palette.primary.main
    },
    btnStyling: {
        backgroundColor: theme.palette.secondary.main,
        color:theme.palette.background.paper,
        '&:hover': {
            backgroundColor:theme.palette.secondary.dark,
        }
    }
}))