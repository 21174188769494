
import React from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import PlaceOrderSuccessDetails from "./place.order.success.details";
import { Typography } from '@mui/material';

const PlaceOrderSuccessAlertDialog = (props) => {
    const { isPlaceOrderSuccessAlertDialogOpen } = props
    return (
        <div>
            <ContentDialog
                setHeaderBackgroundAsPrimary={true}
                open={isPlaceOrderSuccessAlertDialogOpen}
                maxWidth='md'
                handleClose={props.hidePlaceOrderSuccessAlertDialog}
                dividers={false}
                title={< Typography variant='h5' className="m-auto text-white m-auto font-weight-bold pl-3" >
                    Order Detail
                </Typography >}
            >
                <div className='mt-3'>
                    <PlaceOrderSuccessDetails {...props} />
                </div>
            </ContentDialog>
        </div>
    )
}

export default PlaceOrderSuccessAlertDialog