import React from 'react'
import { useStyle } from './tab.button.style'
import { Paper, Badge } from "@mui/material";

export default function TabButton(props) {
    const { title, selected, count } = props;
    const classes = useStyle()
    return (
        <div>
            <Badge badgeContent={count ? count : ''}
                color='primary'
                invisible={count ? false : true}>
                <Paper onClick={props.onClick}
                    classes={{ root: selected ? classes.selectedBtn : classes.btn }}
                    className="w-100 p-1 px-4 border-light">
                    {title}
                </Paper>
            </Badge>
        </div>
    )
}
