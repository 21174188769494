import './sub.advertisment.panel.scss';
import { Box, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx'
import { useStyle } from './sub.advertisment.panel.style'
const SubAdvertismentPanel = (props) => {
    const { image, description, buttonTitle, width, height } = props
    const mobileWidth = IsMobileWidth()
    const classes = useStyle()
    return (
        <Box
            sx={{ flexGrow: 1 }}
            className="d-flex justify-content-center h-100 w-100"
            id="sub-advertisment-panel"
        >
            <Grid container
                classes={{ root: classes.subAdvertismentPanel }}
                spacing={2}
                className="w-90 h-100 rounded"
            >
                <Grid item lg={4} md={4} sm={3} xs={2.5}>
                    <div className="W-100 h-100"
                        style={{
                            backgroundImage: image ? `url(${image})` : "url(https://freepngimg.com/thumb/man/2-2-man-transparent-thumb.png)",
                            backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                            backgroundRepeat: "no-repeat",
                        }}>
                    </div>
                </Grid>
                <Grid item lg={4} md={4} sm={5} xs={4} className="w-100 d-flex align-items-center justify-content-center">
                    <Box>
                        <Typography classes={{ root: classes.textStyling }} variant={clsx(!mobileWidth && "h6", mobileWidth && "body1")} component="div" className="text-right text-uppercase amazing-textstyle">
                            enjoy
                        </Typography>
                        <Typography classes={{ root: classes.textStyling }} variant={clsx(!mobileWidth && "h3", mobileWidth && "body1")} component="div" className="text-right text-uppercase amazing-textstyle">
                            Amazing
                        </Typography>
                        <Typography classes={{ root: classes.textStyling }} variant={clsx(!mobileWidth && "h3", mobileWidth && "body1")} component="div" className="text-right text-uppercase amazing-textstyle font-weight-light led">
                            offers
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={5.5} className="d-flex align-items-center justify-content-right" >
                    <div className="p-2 pl-2">
                        <Typography>
                            <Button classes={{ root: classes.btnStyling }} size={clsx(!mobileWidth && "large", mobileWidth && "small")} className={clsx(!mobileWidth && "font-weight-bold text-uppercase", mobileWidth && "text-uppercase font-weight-light")}
                            >{buttonTitle ? buttonTitle : "shop now"}
                            </Button>
                        </Typography>
                        <Typography classes={{ root: classes.textStyling }} variant={clsx(!mobileWidth && "subtitle1", mobileWidth && "body2")} component="div" className={clsx(!mobileWidth && "text-ellipsis-clamp-1 amazing-textstyle font-weight-light mt-2", mobileWidth && "text-ellipsis-clamp-3 amazing-textstyle font-weight-light w-100")}>
                            {description ? description : "checkout our amazing offers"}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SubAdvertismentPanel
