import React, { useState } from "react";
import { useStyle } from "./app.navbar.style";
// import CheckoutBasket from "components/common/checkout.basket/checkout.basket";
import { AppBar, Toolbar, IconButton, Typography, Container, Menu, MenuItem } from "@mui/material";
import { isB2bUser } from 'components/common/util/util';
// import SearchPanelV3 from "components/common/search.panel.v3/search.panel.v3";
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import CheckoutBasketV2 from "components/common/checkout.basket.v2/checkout.basket.v2";
import SearchPanel from 'components/common/search.panel/search.panel';
import { withRouter } from 'react-router'

const AppbarDesktop = (props) => {
  const { auth, cartProducts, totalPrice, execStatusSearchStores, resetSearchPanel } = props;
  // const mobileWidth = IsMobileWidth()
  // const tabletWidth = IsTabletWidth()
  const redirect = (url) => {
    handleClose();
    props.push(url);
  }

  const classes = useStyle()
  const logout = () => {
    handleClose();
    props.logout();
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onStoreSearch = (value) => {
    props.resetSearchStores();
    props.resetSearchPannelToDefault();
    props.history.push(`/products`)
    if (value) {
      props.searchStores({
        industryId: "-1",
        selectedIndustry: "",
        query: value,
        page_size: 20,
        page: 1,
        storeId: '',
        industryId: '',
        autocomplete: false
      })
    }
  }
  return (
    <AppBar position="sticky" className="w-100" classes={{ root: classes.appNavbar }} >
      <Container maxWidth="xl">
        <Toolbar classes={{ root: classes.toolbar }} className="pl-5 pr-5 d-flex justify-content-between w-100">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { md: "flex" } }}
          >
            <div onClick={() => redirect("/")} className="cursor-pointer">
              {
                isB2bUser(auth) ?
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/zapp-b2b-logo.png'}
                    width="110"
                    height="40"
                    alt='Zapp-logo-b2b'
                  /> :
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/zapp-logo-purple.svg'}
                    width="75px"
                    alt='Zapp-logo'
                    height="40px"
                  />
              }
            </div>
            <Typography
              variant="h6"
              className="cursor-pointer d-flex align-items-center pl-5"
              onClick={props.handleAddressDialog}
            >
              {
                props.getSelectedAddress()
              }
            </Typography>
          </Typography>
          {/* 
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { md: "flex" }
            }}
          >
            <img
              src={process.env.PUBLIC_URL + '/assets/images/zapp-logo.svg'}
              width="60"
              height="50"
            />
          </Typography>

          <Typography
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            className="justify-content-center text-capitalize"
          >
            <Button
              className="ml-2"
              onClick={handleCloseNavMenu}
              color="secondary"
              variant="none"
            >
              home
            </Button>

            <div
              className="ml-2 d-flex align-items-center"
              onClick={handleCloseNavMenu}
            >
              <NavigationDropdown name="Mens" />
            </div>
            <div
              className="ml-2 d-flex align-items-center"
              onClick={handleCloseNavMenu}
            >
              <NavigationDropdown name="Women" />
            </div>
            <Button
              className="ml-2"
              onClick={handleCloseNavMenu}
              color="secondary"
              variant="none"
            >
              kids
            </Button>
            <Button
              className="ml-2"
              onClick={handleCloseNavMenu}
              color="secondary"
              variant="none"
            >
              home & living
            </Button>
            <Button
              className="ml-2"
              onClick={handleCloseNavMenu}
              color="secondary"
              variant="none"
            >
              Blog
            </Button>

          </Typography> */}
          {/* <Typography className="mr-3" sx={{ display: { xs: "none", md: "flex" } }}> */}
          {/* <div className="m-1">
              <SearchBox />
            </div>
            <IconButton size="medium" className="material-icons">
              favorite_border
            </IconButton>
            <IconButton size="medium" className="material-icons">
              shopping_cart
            </IconButton> */}
          {/* <Button
              color="secondary"
              className="ml-3"
              variant="contained"
              size="medium"
            >
              Login
            </Button> */}
          {/* <Typography
            variant="h6"
            className="cursor-pointer d-flex justify-content-center align-items-center"
            onClick={props.handleAddressDialog}
          >
            {
              props.getSelectedAddress()
            }
          </Typography> */}
          {
            !auth || !auth.get('user') ?
              <div className="d-flex justify-content-end w-100">
                <div style={{
                  height: 'fit-content'
                }}>

                </div>
              </div> :
              <div className="d-flex justify-content-between">
                {/* <div className="d-flex align-items-center"> */}
                {/* {
                  cartProducts?.size > 0 ?
                    <CheckoutBasket
                      label={totalPrice}
                      {...props}
                      count={cartProducts?.size}
                    /> : null
                } */}
                <div className="d-flex justify-content-center align-items-center ml-3 mr-5">
                  <SearchPanel
                    reset={resetSearchPanel}
                    loading={execStatusSearchStores.status === "PENDING" ? true : false}
                    placeholder="Search"
                    onSearch={onStoreSearch}
                  />
                </div>
                {/* <div className="d-flex justify-content-center align-items-center ml-3 mr-5">
                  <SearchPanelV3
                    // defaultValue={name}
                    // reset={state.resetSearchPanel}
                    // loading={execStatusGetProducts.status === "PENDING" ? true : false}
                    placeholder="Search in Zapp"
                  // onSearch={onProductSearch}
                  />
                </div> */}
                <div>
                  <CheckoutBasketV2
                    label={totalPrice}
                    {...props}
                    count={cartProducts?.size}
                  />
                </div>
                <div style={{
                  height: 'fit-content'
                }}>
                  {/* <div className='d-flex align-items-center cursor-pointer'> */}

                  <IconButton className="mr-2" onClick={handleClick} size="medium">
                    <img
                      src={process.env.PUBLIC_URL + '/assets/icons/user@2x.png'}
                      height='32px'
                      width='32px'
                      alt='user-icon'
                    />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                    <MenuItem onClick={() => redirect('/order/history')}>Order history</MenuItem>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </Menu>
                  {/* <IconButton className="mr-2" onClick={props.showDrawer} size="medium">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/icon-Hamburger.svg`}
                      width='32px'
                      height='32px'
                    />
                  </IconButton> */}
                  {/* <Typography classes={{ root: classes.text1 }} className='m-auto pr-2 font-weight-normal'>
                        {auth && auth?.get('user') && auth?.get('user')?.get('full_name')}
                      </Typography> */}
                  {/* </div> */}
                </div>
                {/* </div> */}
              </div>

          }
        </Toolbar>
      </Container>
    </AppBar >
  );
};
export default withRouter(AppbarDesktop);

