import "./checkout.tip.zappman.scss";
import { Paper, Typography } from "@mui/material";
import React from "react";
import { useStyle } from "./checkout.tip.zappman.style";
import CustomButton from "components/common/custom.button/custom.button";
import SiteLabelTextfieldV5 from "components/common/site.label.textfield.v5/site.label.textfield.v5";

const CheckoutTipZappman = (props) => {
  const { tipZappMan } = props;
  const classes = useStyle();
  return (
    <div id="checkout-tip-zappman">
      <Paper elevation={0} className="w-100 p-2">
        <Typography classes={{ root: classes.text }} className="font-weight-bold">Tip ZappMan</Typography>
        <div className="d-flex flex-wrap pt-1">
          <div className="mt-n1">
            <SiteLabelTextfieldV5
              value={tipZappMan}
              onChange={(e) => props.onChange('tipZappMan', e.target.value)}
              placeholder="Enter Tip"
              validationType='NUMERIC'
            />
          </div>
          <div className="p-1 pt-1">
            <CustomButton onClick={() => props.onChange('tipZappMan', "20")} title="+Rs 20.00" selected={tipZappMan === "20" ? true : false} />
          </div>
          <div className="p-1 pt-1">
            <CustomButton onClick={() => props.onChange('tipZappMan', "50")} title="+Rs 50.00" selected={tipZappMan === "50" ? true : false} />
          </div>
          <div className="p-1 pt-1">
            <CustomButton
              title="+Rs 100.00"
              onClick={() => props.onChange('tipZappMan', "100")}
              selected={tipZappMan === "100" ? true : false} />
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default CheckoutTipZappman;