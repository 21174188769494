import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    imgSize: {
        boxShadow: "none !important",
        width: "50px",
        minHeight: "40px"
    },
    bgColor: {
        backgroundColor: theme.palette.background.paper,
    },
    btn2: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    btn: {
        color: theme.palette.greyColor.main,
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor:theme.palette.background.paper
        }
    },
}));