import React from 'react';
import PopoverDropdown from './common/popover.dropdown/popover.dropdown'

export default function ProductBrand(props) {
  return (
    <PopoverDropdown
      placeholder="Select Brand"
      items={[
        "Brand 1",
        "Brand 2",
        "Brand 3",
        "Brand 4",
        "Brand 5",
        "Brand 6",
        "Brand 7",
        "Brand 8"
      ]}
    />
  )
}
