import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    cart_header: {
        backgroundColor: theme.palette.bannerColor.main,
    },
    imgSize: {
        boxShadow: "none !important",
        width: "50px",
        minHeight: "40px"
    },
}));