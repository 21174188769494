import React, { useState } from 'react'
import LinkPhotos from './components/link.photos/link.photos';
import UploadPhotos from './components/upload.photos/upload.photos';
import AddProduct from './components/add.product/add.product';
import ProductDetail from './components/product.detail/product.detail';
import MaterialStepper from 'components/common/material.components/stepper/stepper';

const TestPage = () => {
    const [state, setState] = useState({
        activeStepIndex: 0
    })
    const onStepChange = (activeStepIndex) => {
        setState({ ...state, activeStepIndex })
    }
    return (
        <div>
            <MaterialStepper
                activeStep={state.activeStepIndex}
                onStepChange={onStepChange}
                steps={
                    ["Products", "Upload Photos", "Link Photos", "Product Detail"]
                }
            />
            {
                state.activeStepIndex === 0 ?
                    <div className="mt-5"><AddProduct /> </div> : state.activeStepIndex === 1 ?
                        <div className="mt-5"><UploadPhotos />  </div> : state.activeStepIndex === 2 ?
                            <div className="mt-5"><LinkPhotos />  </div> : state.activeStepIndex === 3 ?
                                <div className="mt-5"><ProductDetail />  </div> : null
            }
        </div>
    )
}
export default TestPage