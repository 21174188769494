import './best.deals.carousel.scss';
import React from 'react'
import Slider from "react-slick";
import IconButton from '@mui/material/IconButton'
import BestDeals from '../best.deals/best.deals';
const BestDealsCarousel = () => {
    function NextArrow(props) {
        const { className, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-n2 carousel-best-deals">
                    <i className="fa fa-arrow-right p-1" id="left_arrow_carousel-best-deals"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-3 carousel-best-deals" >
                    <i className='fa fa-arrow-left p-1' id="left_arrow_carousel-best-deals"></i>
                </IconButton>
            </div>
        );
    }
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };
    return (
        <div
            className="h-100 p-1 w-100"
            id="best-deals-carousel"
        >

            <Slider className="h-100 w-95 m-auto" {...settings}>
                <div>
                    <div className="p-3 carousel-best-deals-items">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel-best-deals-items" >
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel-best-deals-items" >
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel-best-deals-items" >
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel-best-deals-items" >
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel-best-deals-items" >
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
                <div>
                    <div className="p-3 carousel-best-deals-items">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default BestDealsCarousel
