import { regex } from "components/common/util/regex";

export function isValidLoginForm(data) {
    const isValidMobileNo = data?.mobileNumber?.length >= 15;
    const isMobileNoNotEmpty = regex.isNotEmpty.test(data?.mobileNumber);
    const isValidPin = data?.pin?.length === 6;

    return {
        isValidMobileNo: isValidMobileNo,
        isMobileNoNotEmpty: isMobileNoNotEmpty,
        isValidPin: isValidPin,
        allValid: isValidMobileNo && isMobileNoNotEmpty && isValidPin
    }
}
