import React, { useState, useEffect } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { Paper, Typography, Button } from '@mui/material';
import { IsMobileWidth, getAreaFromMap, getFlatNoFromMap, getStreetFromMap, getBuildingFromMap } from 'components/common/util/util'
import clsx from 'clsx';
import SiteLabelTextfield from "components/common/site.label.textfield/site.label.textfield";
import Geocode from "react-geocode";
import { isValidAddress } from './validator';

const NewAddressForm = (props) => {

    const mobileWidth = IsMobileWidth()
    const { isOpen, newAddressLat, newAddressLng, auth, execStatusUpdateAddress, execStatusCreateAddress, selectedAddress } = props;
    const getFlatNo = () => {
        if (selectedAddress && selectedAddress.get('floor') && selectedAddress.get('lat') == newAddressLat && selectedAddress.get('long') == newAddressLng) {
            return selectedAddress.get('floor')
        }
        else return ""
    }
    const getBuilding = () => {
        if (selectedAddress && selectedAddress.get('building') && selectedAddress.get('lat') == newAddressLat && selectedAddress.get('long') == newAddressLng) {
            return selectedAddress.get('building')
        }
        else return ""
    }
    const getStreet = () => {
        if (selectedAddress && selectedAddress.get('street') && selectedAddress.get('lat') == newAddressLat && selectedAddress.get('long') == newAddressLng) {
            return selectedAddress.get('street')
        }
        else return ""
    }
    const getNearestLandMark = () => {
        if (selectedAddress && selectedAddress.get('landmark') && selectedAddress.get('lat') == newAddressLat && selectedAddress.get('long') == newAddressLng) {
            return selectedAddress.get('landmark')
        }
        else return ""
    }
    const [state, setState] = useState({
        addressTitle: selectedAddress && selectedAddress.get('name') ? selectedAddress.get('name') : "",
        flatNo: getFlatNo(),
        building: getBuilding(),
        street: getStreet(),
        area: "",
        nearestLandmark: getNearestLandMark(),
        validationResult: {
            isValidAddressTitle: true,
            isValidFlatNo: true,
            isValidStreet: true,
            isValidArea: true,
            allValid: true
        }
    })

    const handleChange = (name, value) => {
        props.resetCreateAddress();
        setState({ ...state, [name]: value });
    };

    const getAddressType = () => {
        if (state.addressTitle && state.addressTitle.toLocaleLowerCase().trim() === "home") {
            if (auth?.get('user')?.get('home_address')?.get('id')) {
                return "others"
            }
            else {
                return "home"
            }
        }
        else if (state.addressTitle && state.addressTitle.toLocaleLowerCase().trim() === "work") {
            if (auth?.get('user')?.get('work_address')?.get('id')) {
                return "others"
            }
            else {
                return "work"
            }
        }
        else {
            return "others"
        }
    }
    const saveAddress = () => {
        const validationResult = isValidAddress({ ...state });
        setState({
            ...state, validationResult: validationResult
        })
        if (validationResult.allValid === true) {
            if (selectedAddress && selectedAddress.get('query_id')) {
                props.updateAddress({
                    query_id: selectedAddress && selectedAddress.get('query_id'),
                    address: {
                        name: state.addressTitle,
                        building: state.building,
                        street: state.street,
                        floor: state.flatNo,
                        room: state.flatNo,
                        landmark: state.nearestLandmark,
                        lat: newAddressLat,
                        long: newAddressLng,
                        district: state.area,
                        type: selectedAddress && selectedAddress.get('type'),
                        description: `${state.flatNo} ${state.area}`
                    }
                })
            }
            else {
                props.createAddress({
                    address: {
                        name: state.addressTitle,
                        building: state.building,
                        street: state.street,
                        floor: state.flatNo,
                        room: state.flatNo,
                        landmark: state.nearestLandmark,
                        lat: newAddressLat,
                        long: newAddressLng,
                        district: state.area,
                        type: getAddressType(),
                        description: `${state.flatNo} ${state.area}`
                    }
                })

            }

        }
    }
    useEffect(() => {
        Geocode.fromLatLng(newAddressLat, newAddressLng).then(
            (response) => {
                setState({
                    ...state,
                    area: getAreaFromMap(response),
                    flatNo: getFlatNo() ? getFlatNo() : getFlatNoFromMap(response),
                    street: getStreet() ? getStreet() : getStreetFromMap(response),
                    building: getBuilding() ? getBuilding() : getBuildingFromMap(response)
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [])

    return (
        <ContentDialog
            handleClose={props.handleAddressFormClose}
            open={isOpen}
            setHeaderBackgroundAsPrimary={true}
            maxWidth='lg'
            actions={
                <div className="p-3">
                    <Button color="secondary"
                        onClick={props.handleAddressFormClose}>Cancel</Button>
                    <LoaderButton
                        loading={execStatusCreateAddress.status === "PENDING" || execStatusUpdateAddress.status === "PENDING" ? true : false}
                        onClick={saveAddress}
                        classNames='width-max-content ml-3'
                        color="primary">
                        {
                            selectedAddress && selectedAddress.get('query_id') ?
                                "Update" : "Save"
                        }
                    </LoaderButton>
                    <div className="w-100">
                        <p>{execStatusCreateAddress.status === "ERROR" ?
                            <div className="d-flex pt-3 text-danger justify-content-center w-100">
                                {execStatusCreateAddress?.errorDetail?.get('message')}
                            </div> : null}</p>
                        <p>{execStatusUpdateAddress.status === "ERROR" ?
                            <div className="d-flex pt-3 text-danger justify-content-center w-100">
                                {execStatusUpdateAddress?.errorDetail?.get('message')}
                            </div> : null}</p>
                    </div>
                </div>
            }
            title={<Typography variant='h5'
                className="m-auto text-center font-weight-bold text-white">
                Confirm Address Details
            </Typography>}>
            <div className="d-flex w-100 justify-content-center">
                <div className={clsx("d-flex flex-column align-items-center justify-content-center", mobileWidth && "w-100", !mobileWidth && "w-80")}>
                    <div className="w-100">
                        <SiteLabelTextfield
                            disabled={selectedAddress && selectedAddress.get('query_id') ? true : false}
                            value={state.addressTitle}
                            onChange={(e) => handleChange("addressTitle", e.target.value)}
                            placeholder="E.g Home, Work"
                            error={!state.validationResult.isValidAddressTitle ? "This field is required" : null}
                            topAdornment={<b>Address Title*</b>} />
                    </div>
                    <div className="w-100 mt-1">
                        <SiteLabelTextfield
                            value={state.flatNo}
                            onChange={(e) => handleChange("flatNo", e.target.value)}
                            placeholder="E.g 204, 5th Fl"
                            error={!state.validationResult.isValidFlatNo ? "This field is required" : null}
                            topAdornment={<b>Flat/Villa No.*</b>} />
                    </div>
                    <div className="w-100 mt-1">
                        <SiteLabelTextfield
                            value={state.street}
                            onChange={(e) => handleChange("street", e.target.value)}
                            placeholder="E.g 21, 7"
                            error={!state.validationResult.isValidStreet ? "This field is required" : null}
                            topAdornment={<b>Street*</b>} />
                    </div>
                    <div className="w-100 mt-1">
                        <SiteLabelTextfield
                            value={state.building}
                            onChange={(e) => handleChange("building", e.target.value)}
                            placeholder="E.g Tower, Building"
                            topAdornment={<b>Building/Society</b>} />
                    </div>
                    <div className="w-100 mt-1">
                        <SiteLabelTextfield
                            value={state.area}
                            onChange={(e) => handleChange("area", e.target.value)}
                            placeholder="E.g Saddar"
                            error={!state.validationResult.isValidArea ? "This field is required" : null}
                            topAdornment={<b>Area*</b>} />
                    </div>
                    <div className="w-100 mt-1">
                        <SiteLabelTextfield
                            value={state.nearestLandmark}
                            onChange={(e) => handleChange("nearestLandmark", e.target.value)}
                            placeholder="E.g Petrol/Gas Station, Gym, Bakery"
                            topAdornment={<b>Nearest Landmark</b>} />
                    </div>
                </div>
            </div>
        </ContentDialog>
    )
}
export default NewAddressForm