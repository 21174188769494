import './upload.photos.scss';
import React, { useState } from 'react';
import { FileUploader } from 'components/common/file.uploader/file.uploader';
import BoxGroup from 'components/common/material.components/box.group/box.group';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';

const UploadPhotos = () => {

    const [state, setState] = useState({
        uploadedImages: []
    })

    const onFileUpload = (files) => {
        files && files.map((file, index) => {
            let reader = new FileReader();
            reader.onload = () => {
                state.uploadedImages.push(reader.result);
                setState({ ...state });
            }
            reader.readAsDataURL(file);
        })
    }
    const getImages = () => {
        const groupContent = [];
        state.uploadedImages.map((image, index) => {
            groupContent.push({
                content: <CardMedia
                    component="img"
                    height="100%"
                    className="image-rounded"
                    image={image}
                    title=""
                />,
                actions: [<IconButton
                    size="small"
                    style={{ color: "black" }}>
                    <i className="fa fa-trash"></i>
                </IconButton>]
            })
        })
        return groupContent;
    }
    return (
        <div id="upload-photos">
            <div className="d-flex justify-content-center">
                <FileUploader title="+ Upload Photos" classNames="w-50 p-3" onFileUpload={onFileUpload}
                    accept="image/jpeg, image/png" />
            </div>
            <div className="d-flex justify-content-center">
                <BoxGroup
                    classNames="d-flex justify-content-center"
                    height="160px"
                    imageRounded={true}
                    width="160px"
                    groupContent={getImages()}
                />
            </div>
        </div>
    )
}
export default UploadPhotos