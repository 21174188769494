import React from 'react'
import { Box, Grid } from '@mui/material';
import CircularNavigationBox from 'components/common/circular.navigation.box/circular.navigation.box';

const CircularNavigationBoxTemplateGrid = () => {
  
    return (
        <div>
            <Box>
                <Grid container spacing={1} className="p-1 d-flex justify-content-center" >
                    <Grid item  lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="Alkaram Studio" image="https://whatsonsale.com.pk/sites/default/files/styles/large/public/alkaram.jpg?itok=h7eknj80" />
                        </div>
                    </Grid>
                    <Grid item lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="Bata" image="https://1.bp.blogspot.com/-WTankH4frZQ/YDQ6CBCqugI/AAAAAAAAVxY/OeeINW2EWGgeKhftkNRATJ1pSHdf8naNACLcBGAsYHQ/s2048/Bata%2BShoes2.png" />
                        </div>
                    </Grid>
                    <Grid item lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="the cambridge" brandName="Cambridge" bgColor="white" />
                        </div>
                    </Grid>
                    <Grid item lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="ethnic" brandName="ethnic" bgColor="orange" />
                        </div>
                    </Grid>
                    <Grid item lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="zellbury" image="https://trademarks.justia.com/media/og_image.php?serial=87236004" />
                        </div>
                    </Grid>
                    <Grid item lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="khaadi" image="https://vectorseek.com/wp-content/uploads/2020/12/Khaadi-logo-vector-01-730x730.jpg" />
                        </div>
                    </Grid>
                    <Grid item lg={1.5} md={2} sm={3} xs={6}>
                        <div className="h-100 w-100">
                            <CircularNavigationBox title="brumano" brandName="brumano" bgColor="purple" />
                        </div>
                    </Grid>
                </Grid>
            </Box >
        </div>
    )
}

export default CircularNavigationBoxTemplateGrid
