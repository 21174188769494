import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme =>({
    card_background: {
        height: "auto",
        border: "none",
        boxShadow: "none"
    },
    tagColor:{
        background:theme.palette.error.main,
        color:theme.palette.background.paper
    },
    likeBtn:{
        background:theme.palette.background.paper,
    }

}))
