// The initial state of the App
import { fromJS } from 'immutable';
import { ADD_UPDATE_APP_LOADERS_STATUS,
  SAVE_RIDER_DETAILS,RESET_SAVE_RIDER_DETAILS,
   SAVE_MERCHANT_DETAILS, RESET_SAVE_MERCHANT_DETAILS, CHANGE_LANGUAGE, SET_APP_PROPERTIES, CLEAR_APP_LOADER_STATUS, SET_ANIMATION } from 'view.updater/actions/app.actions';
import { getLocalStorageValue } from 'components/common/util/util'
import { getActionName } from './util';

const initialState = fromJS({
  appLoadersStatus: [],
  appProperties: {
    sidebarOpen: false
  },
  animations: {},
  merchantDetailSuccessfullySaved: false,
  riderDetailSuccessfullySaved: false,
  language: getLocalStorageValue("language") ? getLocalStorageValue("language") : "en"
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case '@@INIT':
      return state;
    case getActionName(SAVE_MERCHANT_DETAILS):
      state = state.set("merchantDetailSuccessfullySaved", true)
      return state;
    case RESET_SAVE_MERCHANT_DETAILS:
      state = state.set("merchantDetailSuccessfullySaved", false)
      return state;
    case getActionName(SAVE_RIDER_DETAILS):
      state = state.set("riderDetailSuccessfullySaved", true)
      return state;
    case RESET_SAVE_RIDER_DETAILS:
      state = state.set("riderDetailSuccessfullySaved", false)
      return state;
    case SET_APP_PROPERTIES:
      return state.update('appProperties', (appProperties) => {
        return appProperties.merge(action.data)
      })

    case ADD_UPDATE_APP_LOADERS_STATUS:
      return state.update('appLoadersStatus', (loaderStatus) => {
        let index = loaderStatus.findIndex((status) => status.get('name') === action.name);
        if (index > -1) {
          if (action.status === "FINNISH") {
            return loaderStatus.filterNot((status) => status.get('name') === action.name);
          }
          else {
            return loaderStatus.updateIn([index], status => {
              return status.merge({
                status: action.status,
                errorId: action.errorId,
                error: action.error
              })
            })
          }
        }
        else
          return loaderStatus.push(fromJS({
            name: action.name,
            status: action.status
          }))
      })

    case CHANGE_LANGUAGE:
      return state.set("language", action.language)

    case SET_ANIMATION:
      return state.setIn(["animations", action.data.key], action.data.animation)

    case CLEAR_APP_LOADER_STATUS:
      return state.update('appLoadersStatus', (loaderStatus) => {
        if (action.payload) {
          return loaderStatus.map((status) => {
            if (action.payload && action.payload.findIndex((name) => name === status.get('name')) === -1) {
              return status;
            }
            else {
              return status.merge({
                status: `REQUEST_CLEAR`
              })
            }
          })
        }
        else
          return loaderStatus;
      })

    default:
      return state;
  }
}
export default appReducer;
