import React, { useState, useEffect, useContext } from 'react'
import { useStyle } from './login.style';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import { Paper, Typography, Divider, Button } from '@mui/material';
import clsx from 'clsx'
import SiteLabelTextFieldV3 from 'components/common/site.label.textfield.v3/site.label.textfield.v3';
import SiteLabelPasswordField from 'components/common/site.label.password.field.v1/site.label.password.field.v1';
// import AccountTypeSelectionDialog from './components/account.type.selection.dialog/account.type.selection.dialog';
// import BackgroundZappPink from 'components/common/layouts/backgrounds/background.zapp.pink/background.zapp.pink';
// import CountrySelectionDialog from './components/country.selection.dialog/country.selection.dialog';
import LoaderButtonV2 from 'components/common/loader.button.v2/loader.button.v2';
import { isValidLoginForm } from './login.validator';
import { AppContext } from '../main/container';
import { LOGIN } from 'view.updater/actions/auth.actions';
import { withRouter } from 'react-router'
import { checkExecutionStatus } from 'components/common/util/util';

const Login = (props) => {
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const classes = useStyle()
    const [state, setState] = useState({
        mobileNumber: '',
        pin: '',
        validationResult: {
            isValidMobileNo: true,
            isMobileNoNotEmpty: true,
            isValidPin: true,
            allValid: true
        },
        isAccountTypeSelectionDialogOpen: false,
        isCountrySelectionDialogOpen: false
    })

    const login = (e) => {
        e.preventDefault()
        const validationResult = isValidLoginForm(state)
        setState({ ...state, validationResult: validationResult })
        if (validationResult.allValid) {
            props.login({ //LOGIN_CHANGES
                login_id: state.mobileNumber?.replace(/\s/g, ''),
                password: state.pin
            })
        }
    }
    const onChange = (name, value) => {
        props.resetLogin();
        setState({ ...state, [name]: value })
    }
    const showAccountTypeSelectionDialog = () => {
        setState({ ...state, isAccountTypeSelectionDialogOpen: true })
    }

    const hideAccountTypeSelectionDialog = () => {
        setState({ ...state, isAccountTypeSelectionDialogOpen: false })
    }

    const showCountrySelectionDialog = (event) => {
        setState({ ...state, isCountrySelectionDialogOpen: true })
    }
    const hideCountrySelectionDialog = () => {
        setState({ ...state, isCountrySelectionDialogOpen: false })
    }
    useEffect(() => { //LOGIN_CHANGES , rename accessToken
        if (props.auth && props.auth.get('user') && props.auth.get('user').get('session_ktb_token')) {
            props.history.push('/products');
        }
    }, [])

    const context = useContext(AppContext);
    const execStatusLogin = checkExecutionStatus(context, LOGIN);
    const redirectTo = (route) => {
        props.history.push(route)
        props.hideLoginPageDialog()
    }

    return (
        <div>
            {/* <BackgroundZappPink> */}
            {/* <div className={clsx(!(mobileWidth || tabletWidth) && 'position-absolute w-100 h-100 d-flex justify-content-between pr-5',
                    (mobileWidth || tabletWidth) && 'position-absolute w-100 h-100 d-block justify-content-between')}> */}
            {/* <div className={clsx(!(mobileWidth || tabletWidth) && 'd-flex m-auto justify-content-start h-60 w-40',
                        (mobileWidth || tabletWidth) && 'd-flex text-center pb-2')}>
                        <div className={clsx(!(mobileWidth || tabletWidth) && '', tabletWidth && 'm-auto pt-5 pb-5', mobileWidth && 'm-auto pt-2')}>
                            <Typography>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/zapp-logo-white.svg`} width="85px" height='75px' />
                            </Typography>
                            <Typography classes={{ root: classes.textStyle }} variant={clsx(!(mobileWidth || tabletWidth) && 'h4', tabletWidth && 'h6', mobileWidth && 'subtitle1')}
                                className={clsx(!(mobileWidth || tabletWidth) && 'pt-5', tabletWidth && 'pt-2 pb-2', mobileWidth && 'pt-2')}>
                                Welcome
                            </Typography>
                            <Typography classes={{ root: classes.textStyle }} variant={clsx(!(mobileWidth || tabletWidth) && 'h4', tabletWidth && 'h6', mobileWidth && 'subtitle1')}
                                className=''>
                                This is ZAPP Marketplace
                            </Typography>
                        </div>
                    </div> */}
            {/* <div className={clsx(!(mobileWidth || tabletWidth) && 'd-flex justify-content-center align-items-center w-35', tabletWidth && 'd-flex justify-content-center align-items-center w-70 m-auto',
                        mobileWidth && 'd-flex w-90 m-auto')}> */}
            {/* <Paper classes={{ root: classes.formStyle }} elevation={0}
                            className={clsx(!(mobileWidth || tabletWidth) && 'w-100 mr-5 paper-root pt-2 d-flex justify-content-center align-items-center',
                                (mobileWidth || tabletWidth) && 'w-100 paper-root pt-2 pb-2 d-flex justify-content-center align-items-center')}> */}
            <div className='w-100'>
                <form>
                    <div className={clsx(!(mobileWidth || tabletWidth) && 'pl-4 pr-4', (mobileWidth || tabletWidth) && 'pl-2 pr-2', 'w-100 ')}>
                        <div className='mb-3'>
                            <Typography classes={{ root: classes.textColor2 }} variant='h6' className='font-weight-normal pt-2'>
                                Login
                            </Typography>
                            <Typography variant='body1' className='font-weight-normal text-muted'>
                                Login to your Zapp Account
                            </Typography>
                        </div>
                        <SiteLabelTextFieldV3
                            autoFocus={true}
                            startAdornment={<img src={`${process.env.PUBLIC_URL}/assets/icons/mobile-icon.svg`} width='15px' />}
                            value={state.mobileNumber}
                            onChange={(e) => onChange('mobileNumber', e.target.value)}
                            topAdornment='Enter Your Registered Number'
                            maxLength="15"
                            maskChar=""
                            mask="+\9\2\ 999 9999999"
                            placeholder='+92 3xx xxxxxxx'
                            onClick={showCountrySelectionDialog}
                            error={!state.validationResult.isMobileNoNotEmpty ? 'Please Enter Phone No' : !state.validationResult.isValidMobileNo ? "Phone No is not valid" : ''}
                        />
                        <SiteLabelPasswordField
                            value={state.pin}
                            onChange={(e) => onChange('pin', e.target.value)}
                            topAdornment='Enter your 6 digit pin or password'
                            placeholder="Enter your 6 digit PIN or password"
                            error={!state.validationResult.isValidPin ? 'Please Enter Your 6 Digit Pin or password' : ''}
                            startAdornment={<img src={`${process.env.PUBLIC_URL}/assets/icons/lock-icon.svg`} width='15px' />}
                            maxLength="30"
                            validationType='ALPHANUMERIC'
                        />
                        <div className='w-100 text-right cursor-pointer pt-1 pb-1'>
                            <Typography onClick={() => redirectTo('/forgot-pin')} variant='body3' classes={{ root: classes.linkStyle }}>
                                Forgot pin?
                            </Typography>
                        </div>
                        <Typography className='w-100 pb-1 pt-1'>
                            <LoaderButtonV2
                                type="submit"
                                fullWidth
                                onClick={login}
                                loading={execStatusLogin.status === "PENDING" ? true : false}
                                variant="contained"
                                classNames="text-capitalize font-weight-light">
                                Login
                            </LoaderButtonV2>
                        </Typography>
                        <div onClick={() => redirectTo('/signup/user')} className='d-flex align-items-center pt-1 pb-2 cursor-pointer'>
                            <Typography variant='body2' className='pr-2' classes={{ root: classes.darkColor }}>Don't have an account?</Typography>
                            <Typography variant='body2' classes={{ root: classes.linkStyle }} className='cursor-pointer'>
                                Sign up
                            </Typography>
                        </div>
                        <div className="w-100">
                            <p>{execStatusLogin.status === "ERROR" ?
                                <div className="d-flex text-danger justify-content-center w-100">
                                    {execStatusLogin?.errorDetail?.get('message')}
                                </div> : null}</p>
                        </div>
                    </div>
                </form>
                {/* <Divider variant='fullWidth' light={true} orientation='horizontal' /> */}
                {/* <div className='d-flex pt-2 pb-2 pl-4 align-items-center'>
                    <Typography variant='caption' className='pr-2' classes={{ root: classes.darkColor }}>Don't have an account?</Typography>
                    <Typography onClick={showAccountTypeSelectionDialog} variant='caption' classes={{ root: classes.linkStyle }}
                        className='cursor-pointer'>
                        Let's create one
                    </Typography>
                </div> */}
            </div>
            {/* </Paper > */}
            {/* </div> */}
            {/* </div > */}
            {/* <div className='w-100 h-100 d-flex align-items-end position-absolute'>
                    <div className='w-100 d-flex position-absolute'>
                        <Typography className="text-muted pl-5" variant='caption' color="primary">Version {packageJson.version}</Typography>
                        <Typography className="text-muted pl-3" variant='caption' color="primary" >.</Typography>
                        <Typography variant="caption" className='font-weight-normal text-capitalize text-muted pl-3'>
                            Copyright {(new Date().getFullYear())} | All Right Reserved
                        </Typography>
                    </div>
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/wave-shapes.svg`}
                        width="100%"
                    />
                </div> */}
            {/* {state.isAccountTypeSelectionDialogOpen ?
                <AccountTypeSelectionDialog
                    isAccountTypeSelectionDialogOpen={state.isAccountTypeSelectionDialogOpen}
                    hideAccountTypeSelectionDialog={hideAccountTypeSelectionDialog}
                    showAccountTypeSelectionDialog={showAccountTypeSelectionDialog}
                    {...props}
                /> : null
            } */}
            {/* {state.isCountrySelectionDialogOpen ?
                    <CountrySelectionDialog
                        isCountrySelectionDialogOpen={state.isCountrySelectionDialogOpen}
                        hideCountrySelectionDialog={hideCountrySelectionDialog}
                        {...props}

                    /> : null} */}
            {/* </BackgroundZappPink> */}
        </div>
    )
}

export default withRouter(Login)