import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  selectedBtn: {
    backgroundColor: theme.palette.lightBlueColor.main,
    color: theme.palette.darkBlueColor.main,
    boxShadow: "none !important",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: '600',
    borderRadius: '7px',
    border: 'none',
    "&:hover": {
      backgroundColor: theme.palette.lightBlueColor.main,
      border: "none",
      color: theme.palette.darkBlueColor.main,
    },
  },
  btn: {
    backgroundColor: "#f0f0f0",
    border: "none",
    color: theme.palette.greyColor.main,
    boxShadow: "none !important",
    cursor: "pointer",
    textAlign: "center",
    fontWeight: '600',
    borderRadius: '7px',
    '&:hover': {
      backgroundColor: theme.palette.lightBlueColor.main,
      color: "#6F6DC3",
      border: "none",
      boxShadow: "none !important",
      cursor: "pointer",
      textAlign: "center",
      fontWeight: '600',
      borderRadius: '7px',
    }
  },
  title: {
    "&:hover": {
      color: theme.palette.background.paper,
    },
  },
  selectedTitle: {
    color: theme.palette.background.paper,
  },
}));
