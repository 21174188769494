
import { SET_LOCALIZATION_MESSAGES }  from 'view.updater/actions/localization.actions';
import { fromJS } from 'immutable';
const initialState = fromJS({
    locale : '',
    messages : []
  });

function localizationReducer(state = initialState, action) {
    switch (action.type) {
      case SET_LOCALIZATION_MESSAGES:
      return state
        .set('locale', action.locale)
        .set('messages', action.messages)
      default:
        return state;
    }
  }
export default localizationReducer;
  