import React from 'react'
import { Box, Typography, IconButton } from '@mui/material';
import { useStyle } from './sale.item.box.style';
import './sale.item.box.scss';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx'
export const SaleItemBox = (props) => {
    const classes = useStyle()
    const { tag, title, description, image, width, height } = props
    const mobileWidth = IsMobileWidth()

    return (
        <div className="W-100 h-100"
            id="sale-item-box"
            style={{
                backgroundImage: image ? `url(${image})` : "",
                backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                backgroundRepeat: "no-repeat",
            }}
        >
            <Box
                className="paper-root2 w-100 h-100"
            >
                <div className="d-flex flex-column align-items-between justify-content-between h-100">
                    <div className={clsx(!mobileWidth && "d-flex justify-content-between p-3 pt-3", mobileWidth && "d-flex justify-content-between p-2 pt-3")}>
                        <Typography
                            classes={{
                                root: classes.tagColor
                            }}
                            variant="subtitle2" className={tag ? clsx(!mobileWidth && `tag-bg-color p-1 pl-2 pr-2 text-uppercase font-weight-bold mt-1`, mobileWidth && " tag-size p-1 text-uppercase mt-1") : ""} >
                            {tag}
                        </Typography>
                        <IconButton
                            classes={{
                                root: classes.likeBtn
                            }}
                            edge={false} size="medium" className="material-icons paper-root2 border-radius-100">
                            favorite_border
                        </IconButton>
                    </div>
                    <Typography className="p-4">
                        <span className="text-ellipsis-clamp-1 font-weight-bold text-uppercase">
                            {title ? title : ""}
                        </span>
                        <div className="text-ellipsis-clamp-1 text-capitalize">
                            {description ? description : ""}
                        </div>
                    </Typography>
                </div>
            </Box >
        </div >
    )
}
export default SaleItemBox