import React from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import { Typography, Button, Divider } from '@mui/material';
export default function DeleteDialog(props) {
    const { open, title, children, alertMessage, buttonText } = props;
    return (
        <div>
            <ContentDialog maxWidth="sm" open={open} handleClose={props.onClose}
                setHeaderBackgroundAsPrimary={true}
                title={<div className='text-center text-white'>{title}</div>}>
                <div>
                    {alertMessage ?
                        <Typography variant='h6' className='text-center pt-3'>
                            {alertMessage}
                        </Typography>
                        : null
                    }
                    <div className='text-muted text-center pt-3 pb-4'>
                        {children}
                    </div>
                    <Divider />
                    <div className='pt-3 w-90 m-auto'>
                        <Button variant='' color="primary" onClick={props.onDelete} className='text-capitalize w-100'>
                            {buttonText ? buttonText : ''}
                        </Button>
                        <Button onClick={props.onClose} variant='' color="secondary" className='text-capitalize w-100'>
                            No, Go Back
                        </Button>
                    </div>
                </div>
            </ContentDialog>
        </div>
    )
}
