// The initial state of the App
import { fromJS } from 'immutable';
import { DO_SIGN_UP, RESET_DO_SIGN_UP, GO_TO_BACK_STEP } from '../actions/sign.up.actions';
import { getActionName } from './util';

const initialState = fromJS({
    signUpActiveStep: 1,
    signUpResp: {
        userId: ''
    }
});

function signUpReducer(state = initialState, action) {
    switch (action.type) {
        case GO_TO_BACK_STEP:
            if (state.get('signUpActiveStep') === 3) {
                return state.set('signUpActiveStep', state.get('signUpActiveStep') - 2);
            }
            else
                return state.set('signUpActiveStep', state.get('signUpActiveStep') - 1);
        case DO_SIGN_UP:
            state = state.update('signUpResp', (user) => {
                return user.merge({
                    ...action.payload
                })
            })
            return state;
        case getActionName(DO_SIGN_UP):
            if (action.payload.activeStep == 1 && action.response && action.response.user && action.response.user.status == "pending") {
                return state;
            }
            else {
                state = state.set('signUpActiveStep', state.get('signUpActiveStep') + 1);
                state = state.update('signUpResp', (user) => {
                    return user.merge({
                        ...action.response,
                        ...action.payload
                    })
                })
                return state;
            }
        case RESET_DO_SIGN_UP:
            state = state.set('signUpActiveStep', 1);
            state = state.set('signUpResp', fromJS({
                userId: ''
            }))
            return state;
        default:
            return state;
    }
}
export default signUpReducer;
