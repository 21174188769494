import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme =>({

  muibutton: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "12px !important",
    outline: "none !important",
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}))