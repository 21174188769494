import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles({
    card_style: {
        border: "none !important",
        borderRadius: "none !important",
        boxShadow: "none !important"
    },
    box_style: {
        backgroundColor: "#f8f8f8 !important",
        boxShadow: "none !important"
    },

})
