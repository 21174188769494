import { makeStyles } from '@mui/styles';
import { primaryColor } from '../../../containers/main/material.theme/app.theme/app.material.theme.base';
export const useStyle = makeStyles((theme) => ({
    selectedBox: {
        border: `2px solid ${primaryColor}`,
        cursor: 'pointer'
    },
    box: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.lightBackgroundColor.main,
        }
    },
}))