import './summary.box.scss'
import React from 'react';
import { Box, Divider, Typography, Button } from '@mui/material';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ProgressBar from 'components/common/progress.bar/progress.bar';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { useStyle } from './summary.box.style';
import PlaceOrderDialog from '../place.order.dialog/place.order.dialog';
import { formatAmountV1 } from 'components/common/util/util';
// import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
export default function SummaryBox(props) {
    const { totalItems, orderTotalPrice, buttonTitle,
        buttonTitle2, cartProducts, execStatusCheckoutInvoice,
        execStatusCreateInvoice, execStatusPlaceOrder, checkOutInvoiceState
    } = props
    const classes = useStyle()

    const checkOut = () => {
        let invoiceItemAttributes = [];
        if (cartProducts) {
            for (let cartProd of cartProducts) {
                //COLOR_SIZE_CHANGES
                invoiceItemAttributes.push({
                    instruction: "",
                    product_query_id: cartProd?.get('data')?.get('query_id'),
                    quantity: cartProd?.get('data')?.get('quantity'),
                    item_description: "",
                    color: cartProd?.get('data')?.get('selectedColor') ? cartProd?.get('data')?.get('selectedColor') : {},
                    size : cartProd?.get('data')?.get('selectedSize') ? cartProd?.get('data')?.get('selectedSize') :  {}
                })
            }
            props.createInvoice({
                invoice: {
                    invoice_items_attributes: invoiceItemAttributes,
                    organization_query_id: cartProducts?.get(0)?.get('data')?.get('storeQueryId'),
                    //address_id: 234
                }
            })
        }
    }
    const closePlaceOrderDialog = () => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
    }
    return (
        <React.Fragment>
            {
                cartProducts && cartProducts?.size > 0 ?
                    <Box id="summary-box" sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} className='paper-root border p-2'>
                        <Typography variant="h6" component="div" className='font-weight-bold d-flex align-items-center p-2 pl-2 h-auto text-ellipsis-clamp-1'>
                            Summary
                        </Typography>
                        <Divider variant="middle" />
                        <div className='d-flex justify-content-between'>
                            <div>
                                <Typography variant="subtitle2" className='font-weight-light text-ellipsis-clamp-1 h-auto p-2'>
                                    Total Items
                                </Typography>
                                {/* <Typography variant="subtitle2" className='font-weight-light text-ellipsis-clamp-1 h-auto p-2'>
                             Item(s) Subtotal
                         </Typography> */}
                                <Typography variant="subtitle2" className='font-weight-light text-ellipsis-clamp-1 h-auto p-2'>
                                    Total
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="subtitle2" className='font-weight-light text-ellipsis-clamp-1 h-auto p-2'>
                                    {totalItems ? ` ${totalItems} items` : ""}
                                </Typography>
                                {/* <Typography variant="subtitle2" className='font-weight-light text-ellipsis-clamp-1 h-auto p-2'>
                             {itemsSubtotalprice ? `Rs. ${itemsSubtotalprice}` : ""}
                         </Typography> */}
                                <Typography variant="subtitle2" className='font-weight-bold text-ellipsis-clamp-1 h-auto p-2'>
                                    {orderTotalPrice ? `Rs. ${formatAmountV1(orderTotalPrice)}` : ""}
                                </Typography>
                            </div>
                        </div>
                        <Divider variant="middle" />
                        {/* <Accordion classes={{ root: classes.accordion }} className="w-100">
                     <AccordionSummary
                         expandIcon={<span className='material-icons'>expand_more</span>}
                         aria-controls="panel1a-content"
                         id="panel1a-header"
                     >
                         <Typography variant="subtitle2" className='font-weight-light p-2 ml-n3'>
                             Apply Discount Code
                         </Typography>
                     </AccordionSummary>
                     <AccordionDetails className='mt-n5'>
                         <SiteLabelTextField
                             placeholder="Enter voucher code"
                             maxLength="14"
                             validationType="NUMERIC"
                         />
                     </AccordionDetails>
                 </Accordion> */}
                        {/* <ProgressBar label="only 952.2 away,from free shipping" sliderValue="25"/> */}
                        <Typography variant="subtitle2" className='p-2 pt-5'>
                            <Button onClick={() => props.redirecto('/products')} color="secondary" variant="outlined" className="w-100"
                            >{buttonTitle ? buttonTitle : ""}
                            </Button>
                        </Typography>
                        <Typography gutterBottom variant="subtitle2" className='p-1 py-2'>
                            <LoaderButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={checkOut}
                                loading={execStatusCheckoutInvoice.status === "PENDING" ||
                                    execStatusCreateInvoice.status === "PENDING" ||
                                    execStatusPlaceOrder.status === "PENDING" ? true : false}
                            // loaderPosition="top"
                            >
                                {buttonTitle2 ? buttonTitle2 : ""}
                            </LoaderButton>
                            {/* <Button onClick={checkOut} classes={{ root: classes.btn2 }} className="w-100 font-weight-light text-uppercase"
                     >{buttonTitle2 ? buttonTitle2 : ""}
                     </Button> */}
                        </Typography>
                        {
                            execStatusCheckoutInvoice.status === "ERROR" ?
                                <div className="d-flex justify-content-start pl-2 text-danger w-100">
                                    {execStatusCheckoutInvoice?.errorDetail?.get('message')}
                                </div> : null
                        }
                        {
                            execStatusCreateInvoice.status === "ERROR" ?
                                <div className="d-flex justify-content-start pl-2 text-danger w-100">
                                    {execStatusCreateInvoice?.errorDetail?.get('message')}
                                </div> : null
                        }

                    </Box> : null
            }
            {
                checkOutInvoiceState && checkOutInvoiceState.size > 0 ?
                    <PlaceOrderDialog
                        {...props}
                        handleClose={closePlaceOrderDialog}
                        execStatusPlaceOrder={execStatusPlaceOrder}
                        isOpen={checkOutInvoiceState && checkOutInvoiceState.size > 0 ? true : false} /> :
                    null
            }
        </React.Fragment>

    );
}
