import React, { useState } from 'react'
import CheckoutDeliveryAddress from 'components/common/checkout.delivery.address/checkout.delivery.address'
import CheckoutAddVoucher from 'components/common/checkout.add.voucher/checkout.add.voucher'
import CheckoutDeliveryTime from 'components/common/checkout.delivery.time/checkout.delivery.time'
import CheckoutTipZappman from 'components/common/checkout.tip.zappman/checkout.tip.zappman'
import CustomButton from 'components/common/custom.button/custom.button'
import SiteLabelTextField from 'components/common/site.label.textfield/site.label.textfield';
import OrderDetail from 'components/common/order.detail/order.detail';
import { Paper, Typography } from '@mui/material';
import { useStyle } from './place.order.dialog.style'
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';
import AddressDialog from '../address.dialog/address.dialog'
import { getAddressDescription, isB2bUser } from 'components/common/util/util'

const OrderDetails = (props) => {
    const { checkOutInvoiceState, onChange, selectedAddressId, auth, notesZappMan, receiverName, contactPhone, isValidContactPhoneNo } = props;
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const [state, setState] = useState({
        addressDialogIsOpen: false
    })

    const openAddressDialog = () => {
        setState({ ...state, addressDialogIsOpen: true })
    }

    const closeAddressDialog = () => {
        setState({ ...state, addressDialogIsOpen: false })
    }

    let address = "Select Address";
    if (auth && auth?.get('user')?.get('home_address')?.get('id')) {
        if (auth?.get('user')?.get('home_address')?.get('id') == selectedAddressId) {
            address = getAddressDescription(auth?.get('user')?.get('home_address'))
        }
    }
    if (auth && auth?.get('user')?.get('work_address')?.get('id')) {
        if (auth?.get('user')?.get('work_address')?.get('id') == selectedAddressId) {
            address = getAddressDescription(auth?.get('user')?.get('work_address'))
        }
    }
    if (auth?.get('user')?.get('other_addresses')) {
        let add = auth?.get('user')?.get('other_addresses').find((address) => address.get('id') == selectedAddressId);
        if (add) {
            address = getAddressDescription(add)
        }
    }

    return (
        <div className={clsx(!mobileWidth && 'd-flex justify-content-between w-95 m-auto', mobileWidth && 'd-block justify-content-center')}>
            <div className={clsx(!mobileWidth && "w-60", mobileWidth && 'w-100')}>
                <Paper classes={{ root: classes.lightBorder }} elevation={0} className='paper-root m-auto p-2 pt-2 pb-2'>
                    <div className='d-flex justify-content-start'>
                        <div className='w-30 mr-2'>
                            <CustomButton title="Delivery"
                                selected={checkOutInvoiceState &&
                                    checkOutInvoiceState?.get('invoice')?.get('checkout_type') === "delivery" ? true : false} />
                        </div>
                        {/* <div className='w-25 ml-2'>
                            <CustomButton title="Pick Up" selected={checkOutInvoiceState &&
                                checkOutInvoiceState?.get('invoice')?.get('checkout_type') === "pickup" ? true : false} />
                        </div> */}
                    </div>
                    <div className='w-100'>
                        <CheckoutDeliveryAddress
                            openAddressDialog={openAddressDialog}
                            address={address}
                            closeAddressDialog={closeAddressDialog}
                        />
                    </div>
                </Paper>
                <Paper classes={{ root: classes.lightBorder }} elevation={0} className='paper-root mt-2 mb-2 p-2 pt-2 pb-2'>
                    <CheckoutDeliveryTime {...props} />
                </Paper>
                <Paper classes={{ root: classes.lightBorder }} elevation={0} className='paper-root mt-2 mb-2 p-2 pt-2 pb-2'>
                    <div className='w-100'>
                        <Typography classes={{ root: classes.textColor }} className="pt-2 pb-2">
                            <b>
                                Payment By
                            </b>
                        </Typography>
                    </div>
                    <div className='d-flex justify-content-start'>
                        <div className='w-25 mr-2'>
                            <CustomButton title="Cash" selected />
                        </div>
                        {/* <div className='w-25 ml-2'>
                            <CustomButton title="Zapp Pay" />
                        </div> */}
                    </div>
                </Paper>
                <Paper elevation={0} className='paper-root mt-2 mb-2 p-2 pt-1 pb-2'>
                    <CheckoutAddVoucher {...props} />
                </Paper>
                {
                    !isB2bUser(auth) ?
                        <Paper classes={{ root: classes.lightBorder }} elevation={0} className='paper-root mt-2 mb-2 p-2 pt-2 pb-2'>
                            <CheckoutTipZappman
                                {...props}
                                onChange={onChange} />
                        </Paper> : null
                }
                <Paper classes={{ root: classes.lightBorder }} elevation={0} className='paper-root mt-2 mb-2 p-2 pt-1 pb-2'>
                    <div className='w-100'>
                        <SiteLabelTextField
                            value={notesZappMan}
                            onChange={(e) => onChange('notesZappMan', e.target.value)}
                            topAdornment='Notes for ZappMan'
                            placeholder="Enter instructions for ZappMan"
                        />
                    </div>
                </Paper>
                <Paper classes={{ root: classes.lightBorder }} elevation={0} className='paper-root mt-2 mb-2 p-2 pt-1 pb-2'>
                    <div className='w-100'>
                        <SiteLabelTextField
                            value={receiverName}
                            onChange={(e) => onChange('receiverName', e.target.value)}
                            topAdornment="Reciever Name"
                            placeholder="Enter Reciever Name"
                        />
                        <SiteLabelTextField
                            value={contactPhone}
                            onChange={(e) => onChange('contactPhone', e.target.value)}
                            topAdornment="Contact Phone"
                            placeholder='333 2111222'
                            maskChar=""
                            mask="+\9\2\ 999 9999999"
                            maxLength={15}
                            error={!isValidContactPhoneNo ? "Contact Phone No is not valid" : null}
                        // onChange={(e) => onChange("phoneNo", e.target.value)}
                        // value={phoneNo}
                        // error={!isValidPhoneNo ? "Phone No is not valid" : null}
                        />
                    </div>
                </Paper>
                {/* <CheckoutAddVoucher /> */}

            </div>
            <div className={clsx(!mobileWidth && "w-35 ml-3", mobileWidth && 'w-100')}>
                <OrderDetail
                    deliveryFee={checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('delivery_fee')}
                    serviceFee={checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('service_fee')}
                    subTotal={checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('sub_total_amount')}
                    {...props}
                />
            </div>
            {
                state.addressDialogIsOpen === true ?
                    <AddressDialog
                        isAddressDialogOpen={state.addressDialogIsOpen}
                        closeAddressDialog={closeAddressDialog}
                        {...props} /> : null
            }
        </div>
    )
}

export default OrderDetails