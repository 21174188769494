import React, { useState } from "react";
import { useStyles } from "./site.label.password.field.v1.style";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import clsx from "clsx";
import { Typography } from "@mui/material";
import { regex } from "components/common/util/regex";

export default function PasswordTextField(props) {
    const { error, topAdornment, placeholder, startAdornment, maxLength, pattern, validationType, value, autoFocus } = props;
    const [state, setState] = useState({
        passwordVisibility: false,
    });
    const classes = useStyles();
    const toggleVisiblity = () => {
        setState({ ...state, passwordVisibility: !state.passwordVisibility });
    };
    const onChange = (event) => {
        if (validationType === "ALPHANUMERIC") {
            if (
                regex.alphanumericWithOutSpace.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        } else if (validationType === "ALPHANUMERIC_WITH_SPACE") {
            if (
                regex.alphanumericWithSpace.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        } else if (validationType === "NUMERIC") {
            if (
                regex.wholeNumber.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        } else {
            props.onChange(event);
        }
    };
    return (
        <div className="w-100">
            <div className="d-flex w-100 justify-content-between">
                {topAdornment ? (
                    <Typography classes={{ root: classes.textColor }} variant="caption" className="mt-2 max-width-content">
                        <label htmlFor="text-field" className="form-label">
                            {topAdornment}
                        </label>
                    </Typography>
                ) : (
                    ""
                )}
                {error ? <Typography variant="caption" className="text-danger mt-3">{error}</Typography> : null}
            </div>
            <Paper elevation={0} className={clsx(`w-100 border ${classes.root}`)}>
                {startAdornment ? (
                    <Typography color="secondary" className="h-100 d-flex justify-content-center align-items-center w-10 pl-3 pr-1" >
                        {startAdornment}
                    </Typography>
                ) : null
                }
                <InputBase
                    type={state.passwordVisibility ? "text" : "password"}
                    className={clsx(`${classes.input}`)}
                    // inputProps={{ "aria-label": "username" }}
                    onChange={onChange}
                    placeholder={placeholder}
                    tabIndex={2}
                    inputProps={{
                        maxLength: maxLength,
                        pattern: pattern,
                    }}
                    value={value}
                    autoFocus={autoFocus ? autoFocus : false}

                />
                <IconButton
                    onClick={toggleVisiblity}
                    color="default"
                    className={classes.iconButton}
                    size="small"
                >
                    <Icon
                        classes={{ root: classes.iconSize }}
                        color="default"
                        className={clsx(
                            !state.passwordVisibility && "fa fa-eye-slash w-auto",
                            state.passwordVisibility && "fa fa-eye w-auto"
                        )}
                    ></Icon>
                </IconButton>
            </Paper>
        </div>
    );
}