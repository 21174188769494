import { useStyle } from "./place.order.dialog.style";
import React, { useState } from "react";
import { IsMobileWidth, IsTabletWidth } from "components/common/util/util";
import LoaderButton from "components/common/material.components/loader.button/loader.button";
import {
    Container,
    Paper,
    Typography
} from "@mui/material";
import clsx from "clsx";
import PlaceOrderSuccessAlertDialog from './place.order.success.alert.dialog';

const PlaceOrderSuccessAlert = (props) => {
    const {
        checkOutInvoiceState,
        execStatusDownloadInvoiceReceipt
    } = props;
    const [state, setState] = useState({
        isPlaceOrderSuccessAlertDialogOpen: false
    })
    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const classes = useStyle();
    const downloadOrderReceipt = () => {
        props.downloadInvoiceReceipt({
            invoiceQueryId: checkOutInvoiceState.get('invoice').get("query_id")
        })
    };

    const showPlaceOrderSuccessAlertDialog = () => {
        setState({ ...state, isPlaceOrderSuccessAlertDialogOpen: true });
    }

    const hidePlaceOrderSuccessAlertDialog = () => {
        setState({ ...state, isPlaceOrderSuccessAlertDialogOpen: false });
    }
    const trackOrder = () => {
        props.history.push('/order/history');
    }
    return (
        <div className="w-100 h-100">
            <Container maxWidth='sm'
                classes={{ root: classes.paper }}
                className={clsx("w-100 h-100 p-2 d-flex justify-content-center align-items-center mt-2 mb-2")}>
                <Paper
                    className={clsx(
                        !mobileWidth && "w-100 p-2 m-2 h-auto d-flex align-items-center flex-column",
                        mobileWidth && "w-90 p-2 mt-2 mb-2 h-auto d-flex align-items-center flex-column"
                    )}>
                    <div>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/images/like_icon.svg"}
                            alt="zapp logo"
                            width="45"
                            height="45"
                        ></img>
                    </div>
                    <Typography
                        variant={clsx(!mobileWidth && "h6", mobileWidth && "h6")}
                        className="pt-3 font-weight-bold"
                        classes={{ root: classes.textPurple }}
                    >
                        Your Order
                    </Typography>
                    <Typography
                        variant={clsx(!mobileWidth && "h6", mobileWidth && "h6")}
                        className="font-weight-bold text-center mt-1"
                        classes={{ root: classes.textPurple }}
                    >
                        Has Been Placed
                    </Typography>
                    {/* <Typography variant="body2" color='primary' className='cursor-pointer font-weight-bold' onClick={showPlaceOrderSuccessAlertDialog}>
                        View Order Detail
                    </Typography> */}
                    <Typography
                        variant="subtitle1"
                        className="text-center pt-2 mt-1"
                        classes={{ root: classes.textPurple }}
                    >
                        Hi <span></span>
                        {checkOutInvoiceState &&
                            checkOutInvoiceState?.get("invoice")?.get("recipient_name")}
                        , <br className={clsx(mobileWidth && "d-none")} />
                        Your Order From
                        <span className="font-weight-bold pr-1 pl-1">
                            {checkOutInvoiceState?.get("invoice")?.get("merchant")?.get("name")}
                        </span>
                        Has been Placed <br className={clsx(mobileWidth && "d-none")} /> For
                        questions kindly contact us at
                        <br className={clsx(mobileWidth && "d-none")} />
                        support@zapp.world
                    </Typography>
                    <div
                        className={clsx(
                            !mobileWidth && "mt-5 mb-4 w-100 d-flex flex-row flex-wrap justify-content-center",
                            mobileWidth && "mt-4 w-100 d-flex flex-row flex-wrap"
                        )}>
                        <div className={clsx(!mobileWidth && 'w-25', mobileWidth && 'w-49')}>
                            <LoaderButton
                                classNames='cancel-btn text-capitalize'
                                type="submit"
                                variant='outlined'
                                onClick={props.handleClose}>
                                Close
                            </LoaderButton>
                        </div>
                        <div className={clsx(!mobileWidth && 'w-25 ml-2', mobileWidth && 'w-49 ml-1')}>
                            <LoaderButton
                                classNames='text-capitalize'
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={trackOrder}
                            // loaderPosition="top"
                            >
                                Track Order
                            </LoaderButton>
                        </div>
                        <div className={clsx((!mobileWidth || tabletWidth) && "w-40 ml-2", mobileWidth && 'w-100 mt-3')}>
                            <LoaderButton
                                classNames='text-capitalize'
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={downloadOrderReceipt}
                                classes={{ root: classes.backgroundColor }}
                                loading={execStatusDownloadInvoiceReceipt.status == "PENDING" ? true : false}
                            >
                                Download Receipt
                            </LoaderButton>
                        </div>
                    </div>
                    {execStatusDownloadInvoiceReceipt.status === "ERROR" ? (
                        <div className="w-100">
                            <div className="d-flex pt-2 font-weight-bold text-danger justify-content-center w-100">
                                {execStatusDownloadInvoiceReceipt?.errorDetail?.get("message")}
                            </div>
                        </div>
                    ) : null}
                </Paper>
                {
                    state.isPlaceOrderSuccessAlertDialogOpen === true ?
                        <PlaceOrderSuccessAlertDialog
                            isPlaceOrderSuccessAlertDialogOpen={state.isPlaceOrderSuccessAlertDialogOpen}
                            hidePlaceOrderSuccessAlertDialog={hidePlaceOrderSuccessAlertDialog}
                            {...props}
                        /> : null
                }
            </Container>
        </div >
    )
}

export default PlaceOrderSuccessAlert