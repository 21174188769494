import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    appNavbar: {
        background: theme.palette.gradientColor3.main,
        height: '72px',
        left: '0px',
        top: "0px",
        boxShadow: 'none',
        // maxWidth: '1440px',
        // borderBottom: `0.5px solid ${theme.palette.purpleColor.normal}`
    },
    appNavbarMobile: {
        background: theme.palette.gradientColor3.main,
        height: '56px',
        left: '0px',
        top: "0px",
        boxShadow: 'none',
    },
    appNavbarTablet: {
        background: theme.palette.gradientColor3.main,
        height: '72px',
        left: '0px',
        top: "0px",
        boxShadow: 'none',
    },
    toolbar: {
        padding: "0px"
    },
    container: {
        // padding: "4px 12px 4px 12px"

    },
    btn: {
        color: theme.palette.background.paper,
        textTransform: 'none',
        fontFamily: 'Montserrat-Light',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
    },
    btn2: {
        background: theme.palette.background.paper,
        color: '#5816CA',
        borderRadius: '5px',
        padding: '8px 16px 8px 8px',
        // width: '93px',
        height: '36px',
        padding: '8px 16px 8px 8px',
        gap: '4px',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '16px',
        border: '1px solid #5816CA',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.07)',
        letterSpacing: '-0.001em',
        '&:hover': {
            background: theme.palette.background.paper,
            color: theme.palette.primary.dark,
        }
    },
    btnText: {
        fontSize: '14px',
        fontFamily: 'Nunito',
        fontWeight: '500',
        textTransform: 'none',
        fontStyle: 'normal',
    },
    iconStyle: {
        border: `1px solid #5B3BD0`,
    }

}))
