import './app.navbar.scss';
import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Container, Menu, MenuItem } from "@mui/material";
import { useStyle } from "./app.navbar.style";
// import CheckoutBasket from "components/common/checkout.basket/checkout.basket";
import { isB2bUser } from 'components/common/util/util';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
// import clsx from 'clsx';
import CheckoutBasketV2 from 'components/common/checkout.basket.v2/checkout.basket.v2';
import SearchPanel from 'components/common/search.panel/search.panel';
import { withRouter } from 'react-router'

const AppbarMobile = (props) => {
  const { auth, cartProducts, totalPrice, execStatusSearchStores, resetSearchPanel } = props;

  const classes = useStyle()
  const mobileWidth = IsMobileWidth()
  const tabletWidth = IsTabletWidth()
  const redirect = (url) => {
    handleClose();
    props.push(url);
  }
  const logout = () => {
    handleClose();
    props.logout();
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onStoreSearch = (value) => {
    props.resetSearchStores();
    props.resetSearchPannelToDefault();
    props.history.push(`/products`)
    if (value) {
      props.searchStores({
        industryId: "-1",
        selectedIndustry: "",
        query: value,
        page_size: 20,
        page: 1,
        storeId: '',
        industryId: '',
        autocomplete: false
      })
    }
  }
  return (
    <AppBar id='app-navbar' position="sticky" className="paper-root" classes={{ root: classes.appNavbar }}>
      <Container maxWidth="xl" classes={{ root: classes.container }}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              display: { md: "flex" }
            }}>
            <div onClick={() => redirect("/")} className="cursor-pointer">
              {
                isB2bUser(auth) ?
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/zapp-b2b-logo.png'}
                    width="110"
                    height="45" /> :
                  <img
                    src={process.env.PUBLIC_URL + '/assets/images/zapp-logo.svg'}
                    width="50"
                    height="50"
                  />
              }
            </div>
          </Typography>
          <div className="d-flex justify-content-center align-items-center ml-3 mr-5">
            <SearchPanel
              reset={resetSearchPanel}
              loading={execStatusSearchStores.status === "PENDING" ? true : false}
              placeholder="Search"
              onSearch={onStoreSearch}
            />
          </div>
          {/* {
            cartProducts?.size > 0 ?
              <div className={clsx(tabletWidth ? 'top-145' : 'top-175', "d-flex justify-content-end w-100 position-absolute z-index")}>
                <CheckoutBasket
                  {...props}
                  label={totalPrice}
                  count={cartProducts?.size} />
              </div> : null

          } */}
          <Typography
            variant="h6"
            className="cursor-pointer w-100 d-flex justify-content-center align-items-center pb-1"
            onClick={props.handleAddressDialog}
          >
            {
              props.getSelectedAddress()
            }
          </Typography>

          <Box
            sx={{ flexGrow: 1, display: { xs: "flex" } }}
            className="justify-content-end"
          >

            <CheckoutBasketV2
              label={totalPrice}
              {...props}
              count={cartProducts?.size}
            />
            <IconButton className="mr-2" onClick={handleClick} size="medium">
              <img
                src={process.env.PUBLIC_URL + '/assets/icons/user@2x.png'}
                height='24px'
                width='24px'
              />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
              <MenuItem onClick={() => redirect('/order/history')}>Order history</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            {/* <IconButton className="mr-2" onClick={props.showDrawer} size="medium">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/icon-Hamburger.svg`}
                width='24px'
                height='24px'
              />
            </IconButton> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default withRouter(AppbarMobile);
