import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { SERVER_BASE_URL } from 'view.updater/middlewares/util/api.config';
import moment from 'moment';

export const isToShowSemiNavbar = (location) => {
    if (location.pathname === '/checkoutv2') {
        return false
    }
    else {
        return true
    }
}
function getHourText(hour) {
    if (hour > 1) {
        return "hours"
    }
    else {
        return "hour"
    }
}

export const isOutOfStock = (product) => {
    if (product.get('unlimited') === true) {
        if (product.get('unlimited_out_of_stock') === true) {
            return true
        }
        else {
            return false
        }
    }
    else {
        if (product.get('product_available_quantity') == 0) {
            return true
        }
        return false
    }
}

function getDayText(day) {
    if (day > 1) {
        return "days"
    }
    else {
        return "day"
    }
}
function getMinuteText(minute) {
    if (minute > 1) {
        return "mins"
    }
    else {
        return "min"
    }
}
export function getDeliveryTime(day, hour, minute) {
    if (day > 0 && hour > 0 && minute > 0) {
        return `${day} ${getDayText(day)} ${hour} ${getHourText(hour)} ${minute} ${getMinuteText(minute)}`
    }
    else if (day > 0 && hour > 0) {
        return `${day} ${getDayText(day)} ${hour} ${getHourText(hour)}`
    }
    else if (hour > 0 && minute > 0) {
        return `${hour} ${getHourText(hour)} ${minute} ${getMinuteText(minute)}`
    }
    else if (minute > 0) {
        return `${minute} ${getMinuteText(minute)}`
    }
    else if (day > 0) {
        return `${day} ${getDayText(day)}`
    }
    else if (hour > 0) {
        return `${hour} ${getHourText(hour)}`
    }
    else {
        return ''
    }
}
export function setLocalStorgeValue(key, value) {
    window.localStorage.setItem(key, value)
}
export function formatAmountV1(amount, offset = 3) {
    if (amount === undefined || amount === "")
        return "";
    var re = new RegExp("\\B(?=(\\d{" + offset + "})+(?!\\d))", "g");
    var amount = parseFloat(amount);
    return amount && amount.toFixed(2) && amount.toFixed(2).toString() && amount.toFixed(2).toString().replace(re, ",");
}
export function getLocalStorageValue(key) {
    return window.localStorage.getItem(key)
}
export const isVersionDifferent = (versionA, versionB) => {
    if (versionA === versionB)
        return false;
    else
        return true;
};

export const differenceBetweenDatesInMinutes = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const ms = Math.abs(date2 - date1);
    var diffMins = Math.round(((ms % 86400000) % 3600000) / 60000); // minutes
    return diffMins;
}

export function getRandomNumber() {
    let randomNumber = Math.floor(1000 + Math.random() * 9000); // generate a 4 digit random number
    return randomNumber
}
export const displayMinutes = (minutes) => {
    var num = minutes;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutesTemp = (hours - rhours) * 60;
    var rminutes = Math.round(minutesTemp);
    if (rhours === 0) {
        return rminutes + " mins";
    }
    else if (rminutes === 0) {
        return `${rhours} hour`;
    }
    else {
        return `${rhours} hour ${rminutes} mins`;
    }
}
export const renderHtml = (content) => {
    if (content && content.indexOf('<') === 0) {
        return <div dangerouslySetInnerHTML={{ __html: content }} />
    }
    return (
        <div>
            {content}
        </div>
    )
}
export function formatDate(date) {
    date = new Date(date);
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    return monthNames[monthIndex] + ` ` + day + ', ' + year;
}
export function format24HrDateTime(date) {
    date = new Date(date);
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    minutes = ('0' + minutes).slice(-2);
    return monthNames[monthIndex] + ` ` + day + ' ' + year + ' ' + hours + ':' + minutes;
}
//time in AM / PM format
export function format12HrTime(time) {
    return moment(time, 'hh:mm:ss A').format('LT')
}
//date and time in AM / PM
export function format12HrDateTime(date) {
    return moment(date, 'YYYY-MM-DD, hh:mm:ss A').format('lll')
}

export function downloadDocument(data) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(data.blob);
    link.download = data.fileName;
    document.body.appendChild(link);
    link.click();
}
export function checkExecutionStatus(context, action) {
    if (context && context.executions && context.executions.size > 0) {
        const execution = context.executions.find(
            (execution) => execution.get('name') === action
        )
        if (execution !== undefined && execution !== null) {
            return {
                status: execution.get('status'),
                error: execution.get('errorId'),
                errorDetail: execution.get('error')
            }
        }
        return {
            status: '',
            error: '',
            errorDetail: ''
        }
    }
    return {
        status: '',
        error: '',
        errorDetail: ''
    }
}

export function getCookieValue(key) {
    var b = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

export function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}
export const LANGUAGES = [
    'English', 'Arabic', 'French', 'German', 'Chinese/Cantonese', 'Chinese/Mandarin'
]

export const IsMobileWidth = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.between('xs', 'sm'));
}

export const IsDesktopWidth = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.between('lg', 'xl'));
}

export const IsTabletWidth = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.between('sm', 'lg'));
}

export const IsDesktopSmallWidth = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.between('md', 'xl'));
}

export const getAddressDescription = (address) => {
    //${address.get('name')} 
    if (address) {
        return `${address.get('room')} ${address.get('building')} ${address.get('street')} ${address.get('district')} ${address.get('landmark')}`
    }
    else {
        return ``
    }
}
export const getOrderHistoryTime = (statusAndTime, status) => {
    const selectedTime = statusAndTime && statusAndTime.find((st) => st.get('status') === status);
    return `${selectedTime && selectedTime.get('time')}`
}
export const getOrderStatusDescription = (status) => {
    switch (status) {
        case "order_pending":
            return "Order Pending"
        case "order_confirmed":
            return "Order Confirmed"
        case "order_dispatched":
            return "Order Dispatched"
        case "order_delivered":
            return "Order Delivered"
        default:
            return ""
    }
}
export const isB2bUser = (auth) => {
    if (auth && auth.get('user') && auth.get('user').get('trade_types') && auth.get('user').get('trade_types').size > 0) {
        let trdIndex = auth.get('user').get('trade_types').findIndex((trd) => trd === "Retail");
        if (trdIndex > -1) {
            return false
        }
        else {
            return true;
        }
    }
    else
        return false
}
export function getImageUrl(image, local = false) {
    if (local === true) {
        return `${process.env.PUBLIC_URL}/${image}`
    }
    else {
        if (image == undefined || image == '' || image === "/images/large/missing.png") {
            return `${process.env.PUBLIC_URL}/assets/images/noimage.png`
        }
        else {
            return `${SERVER_BASE_URL}/${image}`
        }
    }
}

export const getSelectedAddressLatLng = (auth) => {
    if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('home_address')?.get('id') == auth.get("selectedAddressId")) {
        return {
            lat: auth?.get('user')?.get('home_address').get('lat'),
            long: auth?.get('user')?.get('home_address').get('long')
        }
    }
    else if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('work_address')?.get('id') == auth.get("selectedAddressId")) {
        return {
            lat: auth?.get('user')?.get('work_address').get('lat'),
            long: auth?.get('user')?.get('work_address').get('long')
        }
    }
    else if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('other_addresses')) {
        let add = auth?.get('user')?.get('other_addresses').find((address) => address.get('id') == auth.get("selectedAddressId"));
        if (add) {
            return {
                lat: add.get('lat'),
                long: add.get('long')
            }
        }
        else {
            return {
                lat: '',
                long: ''
            }
        }
    }
    else {
        return {
            lat: '',
            long: ''
        }
    }
}
// Converts numeric degrees to radians
function toRad(Value) {
    return Value * Math.PI / 180;
}
export const calcDistance = (lat1, lon1, lat2, lon2) => { //haversine formula, return distance in km
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

export const storeIsInRadius = (lat1, lon1, lat2, lon2, radiusLimit) => {
    if (lat1 && lon1 && lat2 && lon2 && radiusLimit) {
        let distance = calcDistance(lat1, lon1, lat2, lon2);
        if (distance <= radiusLimit) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true
    }
}



export const scrollToRefElement = (ref) => window.scrollTo({
    top: ref?.current?.offsetTop + 5,
    behavior: 'smooth',
});

export const scrollToTop = () => window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
});


// ref?.current?.scrollIntoView({
//     behavior: "smooth",
//     block: "nearest",
//     inline: "start"
// });
export function getTotalDeliveryTime(storeDetailInfo) {

    let deliveryTime = storeDetailInfo && storeDetailInfo?.get('delivery_time') && storeDetailInfo?.get('delivery_time')
    let preparationTime = storeDetailInfo && storeDetailInfo?.get('preparation_time') && storeDetailInfo?.get('preparation_time')
    let deliveryTimeDays = deliveryTime && deliveryTime.get('day') * 24 * 60 * 60 ? deliveryTime.get('day') * 24 * 60 * 60 : 0
    let deliveryTimeHours = deliveryTime && (deliveryTime.get('hour') * 60) * 60 ? (deliveryTime.get('hour') * 60) * 60 : 0
    let deliveryTimeMinutes = deliveryTime && deliveryTime?.get('minute') * 60 ? deliveryTime?.get('minute') * 60 : 0

    let totalDeliveryTimeInSeconds = deliveryTimeDays + deliveryTimeHours + deliveryTimeMinutes
    let preparationTimeDays = preparationTime && preparationTime.get('day') * 24 * 60 * 60 ? preparationTime.get('day') * 24 * 60 * 60 : 0
    let preparationTimeHours = preparationTime && (preparationTime.get('hour') * 60) * 60 ? (preparationTime.get('hour') * 60) * 60 : 0
    let preparationTimeMinutes = preparationTime && preparationTime.get('minute') * 60 ? preparationTime.get('minute') * 60 : 0

    let totalpreparationTimeInSeconds = preparationTimeDays + preparationTimeHours + preparationTimeMinutes

    let totalTimeInseconds = totalDeliveryTimeInSeconds + totalpreparationTimeInSeconds;

    return secondsToDateHourMinutes(totalTimeInseconds)
}

export function secondsToDateHourMinutes(seconds) {
    seconds = Number(seconds);
    var days = Math.floor(seconds / (3600 * 24));
    var hours = Math.floor(seconds % (3600 * 24) / 3600);
    var minutes = Math.floor(seconds % 3600 / 60);

    var daysDisplay = days > 0 ? days + (days == 1 ? " day " : " days ") : "";
    var hoursDisplay = hours > 0 ? hours + (hours == 1 ? " hour " : " hours ") : "";
    var minutesDisplay = minutes > 0 ? minutes + (minutes == 1 ? " min " : " mins ") : "";
    return daysDisplay + hoursDisplay + minutesDisplay
}

export const getFlatNoFromMap = (address) => {
    let flatNo = '';
    let addressTypes;
    addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('street_address') == true);
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('premise') == true);
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('street_number') == true);
    }
    if (addressTypes !== undefined) {
        let adComponents;
        adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('street_address') == true)
        if (!adComponents) {
            adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('premise') == true)
        }
        if (!adComponents) {
            adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('street_number') == true)
        }
        if (adComponents !== undefined) {
            flatNo = adComponents && adComponents.long_name;
        }
        else {
            flatNo = ''
        }
    }
    else {
        flatNo = ''
    }
    return flatNo
}

export const getBuildingFromMap = (address) => {
    let blding = '';
    let addressTypes;
    addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('street_address') == true)
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('point_of_interest') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('route') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('sublocality_level_3') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('sublocality_level_2') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('sublocality_level_1') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('sublocality') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('political') == true)
    }
    if (addressTypes !== undefined) {
        let adComponents;
        adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('sublocality_level_3') == true)
        if (!adComponents) {
            adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('sublocality_level_2') == true)
        }
        if (!adComponents) {
            adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('sublocality_level_1') == true)
        }
        if (!adComponents) {
            adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('sublocality') == true)
        }
        if (!adComponents) {
            adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) => add.types.join(',').includes('political') == true)
        }
        if (adComponents !== undefined) {
            blding = adComponents && adComponents.long_name;
        }
        else {
            blding = ''
        }
    }
    else {
        blding = ''
    }
    return blding
}

export const getStreetFromMap = (address) => {
    let strt = '';
    let addressTypes;
    addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('street_address') == true)
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('route') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('premise') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('point_of_interest') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('establishment') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('train_station') == true)
    }
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('transit_station') == true)
    }
    if (addressTypes !== undefined) {
        let adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) =>
            add.types.join(',').includes('route') == true)
        if (adComponents !== undefined) {
            strt = adComponents && adComponents.long_name === "Unnamed Road" ? "" : adComponents && adComponents.long_name;
        }
        else {
            strt = ''
        }
    }
    else {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('route') == true)
        if (addressTypes !== undefined) {
            let adComponents = addressTypes && addressTypes.address_components && addressTypes.address_components.find((add) =>
                add.types.join(',').includes('route') == true)
            if (adComponents !== undefined) {
                strt = adComponents && adComponents.long_name === "Unnamed Road" ? "" : adComponents && adComponents.long_name;
            }
            else {
                strt = ''
            }
        }
        else
            strt = ''
    }
    return strt
}

export const getAreaFromMap = (address) => {
    let area = '';
    let addressTypes;
    addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('street_address') == true)
    if (!addressTypes) {
        addressTypes = address && address.results && address.results.find((add) => add.types.join(',').includes('route') == true)
    }
    //add.types.join(',').includes('establishment') == true ||
    //add.types.join(',').includes('point_of_interest') == true ||
    //add.types.join(',').includes('store') == true ||
    //add.types.join(',').includes('jewelry_store') == true ||
    if (addressTypes !== undefined) {
        area = addressTypes.formatted_address;
        return area;
    }
}

export const getMerchantTimeSlots = (storeDetailInfo) => {
    if (storeDetailInfo) {
        return storeDetailInfo && storeDetailInfo.get('working_days') && storeDetailInfo.get('working_days').map((days, index) => {
            let timeSlot = storeDetailInfo && storeDetailInfo.get('time_slots').get(index) && storeDetailInfo.get('time_slots').get(index);
            return <div className='w-100 d-flex justify-content-between align-items-center' key={index}>
                <div>
                    {`${days}`}
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='ml-3'>
                        {`${timeSlot && timeSlot.get('start_time') && format12HrTime(timeSlot.get('start_time'))}`}
                    </div>
                    <div className='ml-3'>
                        {` ${timeSlot && timeSlot.get('end_time') && format12HrTime(timeSlot.get('end_time'))}`}
                    </div>
                </div>
            </div>
        })


    }
}

export const getDeliveryFee = (store, auth) => {
    if (store) {
        if (store.get('override_delivery_fee') === false) {
            let deliveryFee = auth && auth.get('global_system_configuration').find((config) => config.get('identifier') === "delivery_fee");
            if (deliveryFee && deliveryFee.get('value') && Number(deliveryFee.get('value')) > 0) {
                if (deliveryFee.get("value"))
                    return `Delivery Fee ${deliveryFee.get("value")} RS`
                else return ""
            }
            else {
                return "Free Delivery"
            }
        }
        else {
            if (store.get('delivery_fee_value') === 0) {
                return "Free Delivery"
            }
            else
                if (store.get("delivery_fee_value"))
                    return `RS ${store.get("delivery_fee_value")} Delivery Fee`
                else return ""
        }
    }
}

export const FAQ_RIDERS = [
    {
        "label": "What are the requirements for being a rider?",
        "description": "To apply for a Zapp Rider, the prospective candidate should be aged 18 and above and should have a Valid CNIC, a motorbike driving license and should own a motorbike."
    },
    {
        "label": "How can I sign up as a Zapp rider?",
        "description": "Fill in the online application on our website or send your resumes at our email addresses or contact our support center."
    },
    {
        "label": "How can I change my password in the Zapp rider app?",
        "description": "Kindly tap on the forgot password option at the signup screen and follow the process for changing the password."
    },
    {
        "label": "What are the benefits as a Zapp rider?",
        "description": "We offer competitive pay with performance bonus, plus mobility support and allowances. We make sure you are fairily compensated and provide you with the necessary tools, covid hygiene kits, gloves and inoculations to deliver your orders. In addition, you will also get plenty of support and training from our rider managers."
    },
    // {
    //     "label": "What insurance do I need?",
    //     "description": ""
    // }
]

export const FAQ_MERCHANTS = [
    {
        "label": "How can I sign up to be a Zapp merchant partner?",
        "description": "Fill in the online application on our website or email us your at support@zapp.world or contact us at our helpline. Our team will then get in touch with you to complete the onboarding process."
    },
    {
        "label": "What should I do when I need support?",
        "description": "Kindly contact us on our helpline or send us an email at support@zapp.world. You can also call your concerned Sales Representative for any issues or queries."
    },
    {
        "label": "How can I change my merchant profile information?",
        "description": "Kindly communicate the required changes through email / contact center or you can subsequently contact your concerned sales representative."
    },
    {
        "label": "What kind of products can I sell on Zapp?",
        "description": "You can sell all kinds of products which are allowed by your local laws and regulations. Also, we have a list of products which we do not allow you to sell. For more details visit zapp.world/policy."
    },
    {
        "label": "Are there any hidden charges or taxes that I must pay as a merchant?",
        "description": "Zapp does not charge any hidden charges or taxes. Merchant is liable to pay only the amount which has been agreed upon between Zapp and the merchant in the master agreement."
    }
]
export const FAQ_ABOUT_ZAPP = [
    {
        "label": "What is Zapp?",
        "description": "Zapp is One App for Everything! It is more than a digital marketplace where credible sellers connect with their buyers. We are on a mission to give you the best buying experience ever! You can order from your favourite stores and get delivered to home, office or what ever is most convenient for you."
    },
    {
        "label": "How does Zapp work?",
        "description": "Zapp delivers product from your favorite merchant to your doorstep. Just simply order from Zapp App, our rider will pick the product from the merchant and deliver it to you."
    },
    {
        "label": "How can I signup as a Customer on Zapp?",
        "description": "Download the Zapp App on your mobile. Open the app and click sign up. Enter your Mobile Number and set a password.  Your Zapp account has been successfully made. Let’s Zapp!"
    },
    {
        "label": "How can I change my password on Zapp as a Customer?",
        "description": "Go on to your profile setting and go to change password. You can change your password through here. In case you have forgotten your password click forgot password and follow instructions."
    },
    {
        "label": "How can I change my profile information as a Customer?",
        "description": "Go on to your profile setting and click the information you want to edit."
    },
    {
        "label": "What kind of products can I order from Zapp?",
        "description": "Zapp will offer products in more than 20 categories! Currently you can order in the categories of Beauty and Care, Grocery, Pharmacy, Fashion, Artisanal, Organic, Bakery, Electronics, Toys and Games, Home lifestyle, Pet Care and Art / Office Supplies."
    },
    {
        "label": "How can I place an order on Zapp?",
        "description": "Zapp has a list of favorite credible merchants from whom you can order.Just simply click the merchant you want to order from, select the products, check out and simply order after confirming your shipping details!"
    },
    {
        "label": "Can I track my order?",
        "description": "Zapp gives you live tracking of your order from the moment an order has been placed till it is delivered to you."
    },
    {
        "label": "How can I pay for my order?",
        "description": "You can pay for your order through Zapp Pay, Cash on delivery or Debit / Credit card."
    },
    {
        "label": "How can I apply for return /refund/exchange for a product ?",
        "description": "For return, refund and exchange please visit our link for more details zapp.world / privacy - policy"
    },
    {
        "label": "During Covid - 19 is it safe to order ? Is Zapp taking any safety measures ?",
        "description": "Yes, it’s safe to order during covid - 19. There is no evidence indicating packages or their contents carry any risks.Our team is following standards and guidelines setup by World Health Organization and the local laws of your signup location for the safety of all our stakeholders."
    },
    {
        "label": "Are there any hidden charges or taxes that I must pay as a Customer ?",
        "description": "No.All products on Zapp are delivered at no additional cost to you since the stated price at checkout includes all taxes.You are not expected to pay any additional charges."
    },

]

export const SWIPPER_RIDERS = [
    {
        "title": "Become a zapp rider",
        "label": "Make people’s day",
        "image": "assets/images/riders-page-banner.png",
        "buttonText": 'Apply now'
    },
    // {
    //     "title": "Become a zapp rider",
    //     "description": "Make people’s day",
    //     "image": "assets/images/careers-page-banner-1.png"
    // },
    // {
    //     "title": "Become a zapp rider",
    //     "description": "Make people’s day",
    //     "image": "assets/images/merchant-page-image-1.png",
    //     "buttonText": 'Get started'
    // },
    // {
    //     "title": "Become a zapp rider",
    //     "description": "Make people’s day",
    //     "image": "assets/images/riders-page-banner.png"
    // },

]
export const SWIPPER_MERCHANTS = [
    {
        "label": "Take your business to the next level",
        "title": "Become a merchant hero",
        "image": "assets/images/merchant-page-image-1.png",
        "buttonText": 'Apply now'
    },
]


export const SWIPPER_CAREERS = [
    {
        "title": "welcome!",
        "description": "Start your career at Zapp, and join a team that recognizes your potential and pushes you to grow in ways you can only imagine. At Zapp, you’ll gain priceless international experience by working on projects that impact millions of users in Asia.",
        "image": "assets/images/careers-page-banner-1.png",
        "buttonText": 'Apply now'
    },
]

export const SWIPPER_ABOUT_US = [
    {
        "label": "Lorem ipsum text",
        "title": "about us",
        "image": "assets/images/zapp-rider-bg.png",
    },
]

export const CAREERS_PAGE_QUOTES = [
    {
        "name": "Shiraz",
        "title": "Chief Operating Officer",
        "image": "assets/images/careers-grid-image-1.png",
        "description": "“Zapp is a forerunner in building the first super app in Pakistan and creating a massive impact on millions, which means you’ll constantly be facing new – and exciting – challenges. Zapp gives us the freedom and flexibility to solve them and trusts us to do the rest.” ",
    },
    {
        "name": "Farhad",
        "title": "Partner Onboarding Executive",
        "image": "assets/images/careers-grid-image-2.png",
        "description": "“My motivation comes from the products that better the lives of people, society, and environment. Zapp is a company that not only gives you a purpose, but it also gives you satisfaction that you are doing something substantial which brings real change in peoples´ lives.” ",
    },
    {
        "name": "Waqar",
        "title": "Manager Finance",
        "image": "assets/images/careers-grid-image-3.png",
        "description": "“Zapp has a great working environment! All employees care deeply about the core values that have been set, and everyone’s input is respected and taken into careful consideration. This is a wonderful company to work at and grow your career. I am so glad to be a part of Zapp”",
    }
]

export const USER_ADDRESSES = [
    {
        "title": "Home",
        'address': 'Hydrabad, latifabad unit 6, block 11 house no 9, 2nd floor.'
    },
    {
        "title": "Work",
        'address': 'Hydrabad, latifabad unit 6, block 11 house no 9, 2nd floor.'
    },
    {
        "title": "Home",
        'address': 'Hydrabad, latifabad unit 6, block 11 house no 9, 2nd floor.'
    },
    {
        "title": "Work",
        'address': 'Hydrabad, latifabad unit 6, block 11 house no 9, 2nd floor.'
    },

]


