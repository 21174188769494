import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { IconButton, MenuItem, Typography } from '@mui/material';
import { isB2bUser, IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { useStyle } from './app.sidebar.style';

export default function AppSitebar(props) {
    const { auth, isDrawerOpen } = props
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const classes = useStyle()
    const redirect = (url) => {
        props.push(url);
        props.hideDrawer();
    }
    const logout = () => {
        props.logout();
    }
    return (
        <div>
            <React.Fragment>
                <Drawer
                    id='sidebarv1'
                    anchor='right'
                    open={isDrawerOpen}
                    onClose={props.hideDrawer}
                >
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                    >
                        <div className='d-flex justify-content-between'>
                            <div onClick={() => redirect("/")} className="cursor-pointer p-2">
                                {
                                    isB2bUser(auth) ?
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/images/zapp-b2b-logo.png'}
                                            width="110"
                                            height="40"
                                        /> :
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/images/zapp-logo.svg'}
                                            width="50"
                                            height="50"
                                        />
                                }
                            </div>
                            <div onClick={props.hideDrawer} className='cursor-pointer mt-2'>
                                <IconButton>
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/icons/close.svg'}
                                        width="20px"
                                    />
                                </IconButton>
                            </div>
                        </div>
                        <Divider />
                        {/* <MenuItem onClick={() => redirect('/about-us')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')} onClick={() => redirect('/about-us')}
                                className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                About us
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/riders')} className='mt-3'>
                            <Typography variant={clsx((!mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')}
                                className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                Riders
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/merchants')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')}
                                className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                Merchant partners
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/careers')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')}
                                className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                Careers
                            </Typography>
                        </MenuItem>
                        <MenuItem onClick={() => redirect('/news')} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')}
                                className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                News
                            </Typography>
                        </MenuItem> */}
                        <MenuItem onClick={() => redirect('/order/history')} className='mt-3'>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/view_list.svg`}
                                width="25px"
                            />
                            <div className='ml-3' style={{ height: 'fit-content' }}>
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')}
                                    className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                    My Orders
                                </Typography>
                            </div>
                        </MenuItem>
                        {/* <MenuItem onClick={logout} className='mt-3'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle12', mobileWidth && 'subtitle20M4')}
                                className={clsx(classes.textStyle2, `cursor-pointer text-center`)}>
                                Logout
                            </Typography>
                        </MenuItem> */}
                        {/* <MenuItem onClick={() => redirect('/order/history')} className='mt-3'>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/view_list.svg`}
                                width="25px"
                            />
                            <div className='ml-3' style={{ height: 'fit-content' }}>
                                <Typography color='dark.main' className={`font-weight-normal cursor-pointer text-center`}>
                                    Order History
                                </Typography>
                            </div>
                        </MenuItem>
                        <MenuItem onClick={logout}>
                            <img
                                className='pl-1'
                                src={`${process.env.PUBLIC_URL}/assets/icons/sign-out-icon.svg`}
                                width="26px"
                            />
                            <span className='ml-3'>Logout</span>
                        </MenuItem> */}
                    </Box>
                </Drawer>
            </React.Fragment>
        </div >
    );
}
