import "./best.deals.swipper.carousel.scss";
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Pagination,
    Navigation,
    Mousewheel,
    Keyboard,
} from "swiper";
import { Container } from "@mui/material";
import BestDeals from 'components/common/best.deals/best.deals'
// install Swiper modules
// import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
// import clsx from 'clsx';
export const SwipperCarousel = () => {
    SwiperCore.use([Pagination, Navigation, Mousewheel, Keyboard]);
    // const mobileWidth = IsMobileWidth()
    // const tabletWidth = IsTabletWidth()
    return (
        <div id="best-deals-swipper-carousel">
            <Container>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={30}
                    mousewheel={true}
                    navigation={true}
                    keyboard={true}
                    freeMode={true}
                    pagination={{
                        "clickable": true
                    }}
                    className="swiper"
                >
                    <SwiperSlide className="swiper-slide">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide">
                        <BestDeals brandName="Alkaram" image="https://images.unsplash.com/photo-1620012253295-c15cc3e65df4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8c2hpcnR8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" discountTag="20" />
                    </SwiperSlide>

                </Swiper>
            </Container>
        </div >
    );
};
export default SwipperCarousel;
