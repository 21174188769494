import React from 'react'
import { Typography } from '@mui/material';
import { useStyle } from './sub.category.bar.style';

const SubCategoryBar = (props) => {
    const { title, image, name, buttonText, storeQueryId, category,
        industryId,
        selectedStoreProductCategory } = props;

    const classes = useStyle()
    const viewAll = () => {
        props.redirectTo(`/products/product-list/${storeQueryId}/${selectedStoreProductCategory.get('id')}/${category.get('id')}/${industryId}`)
    }
    return (
        <div className={classes.bgColor}>
            <div className='d-flex justify-content-between align-items-center' >
                <div className="d-flex align-items-center">
                    <Typography className=" h-100 mr-2">
                        {image ?
                            <div className="h-100 w-100 ">
                                <img className={`${classes.imgSize} rounded-circle`} src={image} />
                            </div> : ""
                        }
                    </Typography>
                    <Typography variant='subtitle2' color="dark.main" className="text-ellipsis-clamp-1 font-weight-bold text-capitalize">
                        {title ? title : ""}
                    </Typography>
                    <Typography color="bannerColor.main" variant='subtitle2' className="text-ellipsis-clamp-1 font-weight-bold text-capitalize pl-1">
                        {name ? ` - ${name}` : ""}
                    </Typography>
                </div>
                {
                    category && category.get('id') === "SEARCH_BY_STORE_QUERY_ID" ?
                        null :
                        <Typography classes={{ root: classes.btn }} onClick={viewAll} variant='subtitle2' component='div' className='d-flex align-items-center cursor-pointer'>
                            <div className="text-capitalize pt-1">
                                {buttonText ? buttonText : ""}
                            </div>
                            <span class="material-icons">
                                chevron_right
                            </span>
                        </Typography>
                }
            </div>
        </div>
    )
}

export default SubCategoryBar