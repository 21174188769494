import { makeStyles } from '@mui/styles'
export const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.secondary.main,
  },
  nooutline: {
    outline: 'none',
  },
  noBorder: {
    border: 'none',
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },
  endAdn: {
    paddingRight: '3.5px'
  },
  startAdn: {
    paddingLeft: '0px'
  },
  // input: {
  //   height: 8,
  //   minWidth: '13%',
  //   textOverflow: "ellipsis",
  //   fontSize: '14px'
  // },
  input: {
    // minWidth: '13%',
    textOverflow: "ellipsis",
    height: 8,
    fontSize: 14,
    fontWeight: 300,
    boxShadow:'none',
    fontFamily: 'Nunito',
  },
  mobileInput: {
    height: 8,
    fontSize: 12,
    fontWeight: 300,
    boxShadow:'none',
    fontFamily: 'Nunito',
    textOverflow: "ellipsis",
  },
  inputPassword: {
    width: "90%",
    minWidth: '13%',
    textOverflow: "ellipsis"
  },

  disabled: {
    color: "#000000"
  },
  paperRoot: {
    width: '100%',
    height: 'auto',
    borderLeft: `1.5px solid ${theme.palette.darkBlueColor.main} !important`,
    borderRadius: '8px',
    position: "relative",
    boxShadow: 'none',
    outline: 'none',
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center'
  },
  textColor: {
    color: theme.palette.greyColor.dark
  }
}))
