export const ADD_UPDATE_APP_LOADERS_STATUS = "ADD_UPDATE_APP_LOADERS_STATUS";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_APP_PROPERTIES = "SET_APP_PROPERTIES";
export const SET_ANIMATION = "SET_ANIMATION";
export const RESET_PRELOGIN = "RESET_PRELOGIN";
export const CLEAR_APP_LOADER_STATUS = "CLEAR_APP_LOADER_STATUS";
export const CANCEL_REQUEST = "CANCEL_REQUEST";
export const CANCEL_ALL_REQUEST = "CANCEL_ALL_REQUEST";
export const SAVE_MERCHANT_DETAILS = "SAVE_MERCHANT_DETAILS";
export const RESET_SAVE_MERCHANT_DETAILS = "RESET_SAVE_MERCHANT_DETAILS";
export const SAVE_RIDER_DETAILS = "SAVE_RIDER_DETAILS";
export const RESET_SAVE_RIDER_DETAILS = "RESET_SAVE_RIDER_DETAILS"

// url : "https://getform.io/f/bd649114-132d-4a50-87f7-f343682396f2" TEST FORM GET FORM SERVICE
export function saveMerchantDetails(data) {
  return {
    type: SAVE_MERCHANT_DETAILS,
    data,
    useGridFormService: true,
    contentType: "multipart/form-data",
    method: "POST",
    url: `https://getform.io/f/7856ee98-eca8-4b04-86d2-c824d90f1963`,
  }
}
export function resetSaveMerchantDetails() {
  return {
    type: RESET_SAVE_MERCHANT_DETAILS,
    payload: {
      resetActionNames: ["SAVE_MERCHANT_DETAILS"]
    }
  }
}

export function saveRiderDetails(data) {
  return {
    type: SAVE_RIDER_DETAILS,
    data,
    useGridFormService: true,
    contentType: "multipart/form-data",
    method: "POST",
    url: `https://getform.io/f/39fd87df-c3ad-45ec-98bf-8e0b70f4c52b`,
  }
}
export function resetSaveRiderDetails() {
  return {
    type: RESET_SAVE_RIDER_DETAILS,
    payload: {
      resetActionNames: ["SAVE_RIDER_DETAILS"]
    }
  }
}

export function cancelAllRequest() {
  return {
    type: CANCEL_ALL_REQUEST
  }
}
export function clearAppLoaderStatus(data) {
  return {
    type: CLEAR_APP_LOADER_STATUS,
    payload: data
  };
}

export function cancelRequest(data) {
  return {
    type: CANCEL_REQUEST,
    payload: data
  }
}

export function addUpdateAppLoadersStatus(name, status, errorId, error = {}) {
  return {
    type: ADD_UPDATE_APP_LOADERS_STATUS,
    name,
    status,
    errorId,
    error
  };
}

export function changeLanguage(language) {
  return {
    type: CHANGE_LANGUAGE,
    language
  }
}

export function setAppProperties(data) {
  return {
    type: SET_APP_PROPERTIES,
    data
  }
}

export function setAnimation(data) {
  return {
    type: SET_ANIMATION,
    data
  }
}

//DEMO CODE
// export function resetResendOtp() {
//   return {
//     type: RESET_RESEND_OTP,
//     payload: {
//       resetActionNames: ["RESEND_OTP"]
//     }
//   }
// }