import React, { useEffect, useState } from "react";
import { Container, Dialog } from "@mui/material";
import AddVoucherDialogStep1 from "./components/add.voucher.dialog.step1";
import AddVoucherDialogStep2 from "./components/add.voucher.dialog.step2";
import { isValidVoucher } from './add.voucher.dialog.validation';
import PageHeading from 'components/common/page.heading/page.heading';
import { Box, Typography, Paper } from "@mui/material";
// import StepperV1 from 'components/common/stepper.v1/stepper.v1'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import OrderDetail from 'components/common/order.detail/order.detail';
import ContentDialog from "components/common/material.components/content.dialog/content.dialog";
import LoaderButton from 'components/common/material.components/loader.button/loader.button';

const AddVoucherDialog = (props) => {
  const tabletWidth = IsTabletWidth()
  const mobileWidth = IsMobileWidth()
  const { open, checkOutInvoiceState, voucherInfo, execStatusValidateVoucherStatus } = props
  const [state, setState] = useState({
    voucherCode: "",
    isValidVoucherCode: true
  })
  useEffect(() => {
    props.resetValidateVoucher();
  }, [])
  const onChange = (name, value) => {
    if (name === "voucherCode") {
      props.resetValidateVoucher();
    }
    setState({ ...state, [name]: value })
  }
  const handleNext = (e) => {
    e.preventDefault()
    const validationResult = isValidVoucher({ ...state })
    setState({ ...state, ...validationResult })
    if (validationResult.isValidVoucherCode) {
      props.validateVoucher({
        invoice: {
          voucher_code: state.voucherCode
        }
      }, checkOutInvoiceState?.get('invoice')?.get('query_id'))
    }
  };

  const isVoucherApplied = () => {
    if (voucherInfo && voucherInfo?.get('voucher')?.get('discount_amount')) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div className="h-100">
      <ContentDialog
        open={open}
        setHeaderBackgroundAsPrimary={true}
        title={<div
          className="w-100 m-auto d-flex align-items-center flex-column justify-content-center rounded-3">
          <div>
            {/* <img
                        width={52}
                        height={48}
                        src={`${process.env.PUBLIC_URL}/assets/icons/download-icon.svg`}
                    /> */}
          </div>
          <Typography
            className="text-white pt-2 pb-2 font-weight-bolder">
            <b>
              {
                isVoucherApplied() ?
                  "Voucher Applied" : "Add Voucher"
              }
            </b>
          </Typography>
        </div>}
        // fullScreen={true}
        maxWidth='sm'
        actions={
          <div className="w-100 p-2 m-auto pt-3 pb-3">
            <div className='text-center mt-2 w-100 mb-2'>
              <div className="d-flex w-100 justify-content-end">
                {
                  isVoucherApplied() ?
                    <div className={clsx(!mobileWidth && "w-25 mr-2", mobileWidth && 'w-60 m-auto')}>
                      <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        classNames="mt-3"
                        onClick={props.closeVoucherDialog}>
                        Continue
                      </LoaderButton>
                    </div> :
                    <div className="w-100 justify-content-end d-flex">
                      <div className={clsx(!mobileWidth && "w-25 mr-3", mobileWidth && 'w-35 pr-1')}>
                        <LoaderButton
                          fullWidth
                          type="submit"
                          variant="outlined"
                          color="primary"
                          classNames="mt-3"
                          onClick={props.closeVoucherDialog}>
                          Cancel
                        </LoaderButton>
                      </div>
                      <div className={clsx(!mobileWidth && "w-35", mobileWidth && 'w-65')}>
                        <LoaderButton
                          fullWidth
                          type="submit"
                          disabled={state.voucherCode && state.voucherCode.length > 0 ? false : true}
                          variant="contained"
                          color="primary"
                          loading={execStatusValidateVoucherStatus.status === "PENDING" ? true : false}
                          classNames="mt-3"
                          onClick={handleNext}>
                          Add Voucher
                        </LoaderButton>
                      </div>
                    </div>
                }
              </div>
            </div>
            {
              execStatusValidateVoucherStatus.status === "ERROR" ?
                <div className="d-flex font-weight-bold justify-content-end text-danger w-100">
                  {execStatusValidateVoucherStatus?.errorDetail?.get('message')}
                </div> : null
            }
          </div>
        }
        handleClose={props.closeVoucherDialog}
        className='h-100'>
        <div className="">
          {/* <div className="d-flex justify-content-between">
            <Box className='d-flex justify-content-start align-items-center cursor-pointer pb-1 pt-1 rounded ml-4'>
              <PageHeading redirect={props.closeVoucherDialog} name="Add Voucher" />
            </Box>
          </div> */}
          <div className={clsx(!mobileWidth && "d-flex w-100 pl-4 pr-4", mobileWidth && 'd-block w-100')}>
            {/* <div className={clsx(!mobileWidth && "w-50", mobileWidth && 'w-100')}>
              <div className={clsx(!mobileWidth && "w-100 m-auto", mobileWidth && 'w-95 m-auto')}>
                <OrderDetail
                  deliveryFee={checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('delivery_fee')}
                  serviceFee={checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('service_fee')}
                  subTotal={checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('sub_total_amount')}
                  {...props}
                />
              </div>
            </div> */}
            <Container maxWidth='sm' className={clsx(!mobileWidth && "w-100 m-auto", mobileWidth && 'w-100 m-auto')}>
              <Box className='w-100'>
                {
                  isVoucherApplied() ?
                    null :
                    <div className="d-flex justify-content-center">
                      <img src={`${process.env.PUBLIC_URL}/assets/images/voucher.png`} width='65px' />
                    </div>
                }
                <Typography variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && "body1")} className="p-2 text-center mb-2 mt-2">
                  {voucherInfo && voucherInfo?.get('voucher')?.get('discount_amount') ? 'Voucher Applied Successfully' : 'Promotion Vouchers'}
                </Typography>
              </Box>
              <div className="w-100">
                {
                  isVoucherApplied() ?
                    <AddVoucherDialogStep2
                      {...props} /> :
                    <AddVoucherDialogStep1
                      handleNext={handleNext}
                      onStateChange={onChange}
                      {...state}
                      {...props} />
                }
              </div>
            </Container>
          </div>
        </div>
      </ContentDialog>
    </div>
  );
};

export default AddVoucherDialog;