export const LOGIN = "LOGIN";
export const RESET_AUTH = "RESET_AUTH";
export const LOGOUT = "LOGOUT";
export const RESET_LOGIN = "RESET_LOGIN";
export const UPDATE_USER_AUTH_DATA = "UPDATE_USER_AUTH_DATA";
export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const RESET_CREATE_ADDRESS = "RESET_CREATE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const RESET_DELETE_ADDRESS = "RESET_DELETE_ADDRESS"

export function login(data) {
    return {
        url : 'users/authenticate',
        type : LOGIN,
        data,
        method : 'POST'
    }
}

export function deleteAddress(data,addressDetails) {
    return {
        url : 'users/delete_address',
        type : DELETE_ADDRESS,
        data,
        method : 'DELETE',
        payload: {...data,...addressDetails}
    }
}

export function createAddress(data) {
    return {
        url : 'users/create_address',
        type : CREATE_ADDRESS,
        data,
        method : 'POST',
        payload: {...data}
    }
}

export function updateAddress(data) {
    return {
        url : 'users/update_address',
        type : UPDATE_ADDRESS,
        data,
        method : 'PUT',
        payload: {...data}
    }
}


export function resetLogin() {
    return {
        type: RESET_LOGIN,
        payload: {
            resetActionNames: ["LOGIN"]
        }
    }
}

export function resetCreateAddress() {
    return {
        type: RESET_CREATE_ADDRESS,
        payload: {
            resetActionNames: ["CREATE_ADDRESS","UPDATE_ADDRESS"]
        }
    }
}

export function resetDeleteAddress() {
    return {
        type: RESET_DELETE_ADDRESS,
        payload: {
            resetActionNames: ["DELETE_ADDRESS"]
        }
    }
}

export function logout() {
    return {
        type : LOGOUT,
        //method : 'POST'
    }
}
export function resetAuth() {
    return {
        type : RESET_AUTH
    }
}

export function updateUserAuthData(data) {
    return {
        type : UPDATE_USER_AUTH_DATA,
        payload: data
    }
}