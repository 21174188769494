import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    textColor: {
        color: theme.palette.dark.main,
    },
    lightBorder: {
        border: `0.7px solid ${theme.palette.lightBackgroundColor.main}`
    },
    oldPriceStyle: {
        color: theme.palette.greyColor.main,
        textDecoration: "line-through"
    }
}));