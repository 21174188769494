import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    formStyle: {
        zIndex: 1,
        // height: '360px'
    },
    textStyle: {
        color: theme.palette.purpleColor.normal,
        fontWeight: '500'
    },
    linkStyle: {
        textDecoration: 'none',
        color: theme.palette.darkBlueColor.main,
        '&:hover': {
            color: theme.palette.primary.extraDark,
            textDecoration: 'underline',
        }
    },
    btn: {
        backgroundColor: theme.palette.purpleColor.normal,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    textColor2: {
        color: theme.palette.primary.extraDark,
    },
    darkColor: {
        color: theme.palette.dark.main,
    }
}))
