import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useStyle } from './semi.app.navbar.style';
import clsx from 'clsx';
import { IsMobileWidth, scrollToTop, IsTabletWidth } from 'components/common/util/util';
import { getQueryStringValue } from 'components/common/util/util';
import { getSelectedAddressLatLng } from "components/common/util/util";
import { withRouter } from 'react-router'
//import SearchPanel from 'components/common/search.panel/search.panel';
import { SEARCH_STORES } from 'view.updater/actions/products.actions';
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from 'containers/main/container';

const SemiAppNavbar = (props) => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const { auth, industryStores } = props;
    const [state, setState] = useState({
        selectedIndustry: '',
        resetSearchPanel: false
    })

    const onSelectedIndustryChange = (industry) => {
        props.history.push(`/products?industryId=${industry?.get('id')}`)
        scrollToTop()
        setState({ ...state, selectedIndustry: industry, resetSearchPanel: true })
        props.resetSearchStores();
        props.resetIndustryStores();
        props.resetTheSearchPannel();
        props.updateSelctedStorePage(1);
        let location = getSelectedAddressLatLng(auth);
        props.getIndustryStores({
            industryId: industry?.get('id'),
            selectedIndustry: industry,
            page: 1,
            lat: location.lat,
            long: location.long
        })
    }
    const industryId = getQueryStringValue("industryId");
    useEffect(() => {
        if (industryId) {
            let selectedIndustry = auth && auth.get('industry_types')?.find((ty) => ty.get('id') == industryId);
            setState({ ...state, selectedIndustry: selectedIndustry })
            let location = getSelectedAddressLatLng(auth);
            props.getIndustryStores({
                industryId: selectedIndustry?.get('id'),
                selectedIndustry: selectedIndustry,
                page: 1,
                lat: location.lat,
                long: location.long
            })
        }
        else {
            setState({ ...state, selectedIndustry: auth && auth.get('industry_types') && auth.get('industry_types')?.get(0) })
            let location = getSelectedAddressLatLng(auth);
            props.getIndustryStores({
                industryId: auth.get('industry_types')?.get(0)?.get('id'),
                selectedIndustry: auth && auth.get('industry_types') && auth.get('industry_types')?.get(0),
                page: 1,
                lat: location.lat,
                long: location.long
            })
        }
        return () => {
            props.resetIndustryStores();
        }
    }, [])
    useEffect(() => {
        if (auth && auth.get("selectedAddressId")) {
            if (industryId) {
                let location = getSelectedAddressLatLng(auth);
                let selectedIndustry = auth && auth.get('industry_types')?.find((ty) => ty.get('id') == industryId);
                props.getIndustryStores({
                    industryId: selectedIndustry?.get('id'),
                    selectedIndustry: selectedIndustry,
                    page: 1,
                    lat: location.lat,
                    long: location.long
                })
            }
            else {
                let location = getSelectedAddressLatLng(auth);
                props.getIndustryStores({
                    industryId: auth.get('industry_types')?.get(0)?.get('id'),
                    selectedIndustry: auth && auth.get('industry_types') && auth.get('industry_types')?.get(0),
                    page: 1,
                    lat: location.lat,
                    long: location.long
                })
            }
        }
    }, [auth && auth.get("selectedAddressId")])

    const onStoreSearch = (value) => {
        props.resetSearchStores();
        props.history.push(`/products`)
        setState({ ...state, resetSearchPanel: false })
        if (value) {
            props.searchStores({
                industryId: "-1",
                selectedIndustry: "",
                query: value,
                page_size: 20,
                page: 1,
                storeId: '',
                industryId: '',
                autocomplete: false
            })
        }
    }
    const isSelected = (industry) => {
        let selectedIndustryStores = industryStores && industryStores.find((industry) => industry.get('industryId') == "-1")
        if (selectedIndustryStores) {
            return false
        }
        else if(execStatusSearchStores.status === "PENDING") {
            return false
        }
        else if (state.selectedIndustry && state.selectedIndustry.get('id') == industry.get('id') && window.location.pathname === "/products") {
            return true;
        }
        else if (state.selectedIndustry && state.selectedIndustry.get('id') == industry.get('id') && window.location.pathname === "/") {
            return true;
        }
        else {
            return false;
        }
    }

    const context = useContext(AppContext);
    const execStatusSearchStores = checkExecutionStatus(context, SEARCH_STORES);
    return (
        <AppBar position="sticky" className='top-63 d-flex align-items-center' color='primary' classes={{ root: classes.semiAppNavbar }} >
            <Container maxWidth="xl" className={clsx((mobileWidth || tabletWidth) && 'pl-0 pr-0', 'h-100')}>
                <Toolbar className={clsx(!(mobileWidth || tabletWidth) && 'd-flex flex-row', (mobileWidth || tabletWidth) && 'd-flex flex-column pl-0 pr-0', 'h-100 pt-2 pb-2')}>
                    <div className={clsx(!(mobileWidth || tabletWidth) && "w-30", (mobileWidth || tabletWidth) && 'w-95 m-auto pt-1 pb-1')}>

                    </div>
                    <Box className={clsx(!(mobileWidth || tabletWidth) && "w-70", (mobileWidth || tabletWidth) && 'w-100', 'd-flex flex-wrap')}>
                        {
                            auth && auth.get('industry_types') && auth.get('industry_types').map((industry, index) => {
                                return <>
                                    <div className={clsx((!mobileWidth || tabletWidth) && "pl-1 pr-1 pt-1 pb-1", mobileWidth && 'pl-1')}
                                        onClick={() => onSelectedIndustryChange(industry)}>
                                        <Typography key={index} variant={clsx(!(mobileWidth || tabletWidth) && 'subtitle16N5', (mobileWidth || tabletWidth) && 'caption')}
                                            classes={{ root: isSelected(industry) ? classes.textStyle1 : classes.textStyle2 }}
                                            className={clsx(!(mobileWidth || tabletWidth) && 'pl-3 pr-3', tabletWidth && 'pl-2 pr-2', mobileWidth && 'pl-1 pr-1', 'text-capitalized cursor-pointer pt-1 pb-1 rounded')}>
                                            {industry.get('name')}
                                        </Typography>
                                    </div>
                                </>
                            })
                        }
                        <div className="d-flex flex-grow-1 pb-1 justify-content-end align-items-end">
                            {/* <SearchPanel
                                reset={state.resetSearchPanel}
                                loading={execStatusSearchStores.status === "PENDING" ? true : false}
                                placeholder="Search"
                                onSearch={onStoreSearch}
                            /> */}
                        </div>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default withRouter(SemiAppNavbar);
