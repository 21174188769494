import './address.dialog.scss';
import React, { useState, useEffect, useContext } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { Paper, Typography, FormControlLabel, Radio, IconButton, RadioGroup, Button } from '@mui/material';
import { useStyle } from './address.dialog.style'
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';
import Geocode from "react-geocode";
import { GOOGLE_MAPS_API_KEY } from "view.updater/middlewares/util/api.config";
import ConfirmationDialog from 'components/common/confirmation.dialog/confirmation.dialog'
import AddressMap from './components/address.map/address.map';
import NewAddressMapDialog from './components/new.address.map.dialog/new.address.map.dialog';
import { getAddressDescription } from 'components/common/util/util';
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from '../../../../../main/container';
import { CREATE_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from 'view.updater/actions/auth.actions';
import { } from '../../../../../../view.updater/actions/auth.actions';

// Geocode.fromLatLng(state.newAddressLat, state.newAddressLng).then(
//     (response) => {
//         setState({
//             ...state,
//             newAddress: response?.plus_code?.compound_code,
//         });
//     },
//     (error) => {
//         console.error(error);
//     }
// );
const AddressDialog = (props) => {
    const mobileWidth = IsMobileWidth()
    const context = useContext(AppContext);
    const execStatusCreateAddress = checkExecutionStatus(context, CREATE_ADDRESS);
    const execStatusUpdateAddress = checkExecutionStatus(context, UPDATE_ADDRESS);
    const execStatusDeleteAddress = checkExecutionStatus(context, DELETE_ADDRESS);
    const { auth, isAddressDialogOpen, addressUpdated, addressDeleted } = props;
    const classes = useStyle()
    Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
    Geocode.enableDebug();

    const [state, setState] = useState({
        deleteAddressDialogIsOpen: false,
        selectedAddress: '',
        addNewAddressDialogIsOpen: false
    })

    const handleChange = (name, value) => {
        setState({ ...state, [name]: value });
    };

    const onAddressChange = (value) => {
        props.updateUserAuthData({ selectedAddressId: value })
        handleCloseAddAddressDialog();
        props.closeAddressDialog();
    }

    const handleDeleteAddress = (address) => {
        props.resetDeleteAddress();
        setState({
            ...state, deleteAddressDialogIsOpen: true,
            selectedAddress: address
        })
    }
    const handleEditAddress = (address) => {
        setState({ ...state, addNewAddressDialogIsOpen: true, selectedAddress: address });
    }

    const handleDeleteAddressDialogClose = (e, reason) => {
        if (reason !== "backdropClick") {
            props.resetDeleteAddress();
            setState({ ...state, deleteAddressDialogIsOpen: false })
        }
    }

    const handleCloseAddAddressDialog = () => {
        setState({ ...state, addNewAddressDialogIsOpen: false, selectedAddress: '' })
    }
    const deleteTheAddress = () => {
        props.deleteAddress({
            query_id: state.selectedAddress && state.selectedAddress.get("query_id")
        }, {
            addressDetails: state.selectedAddress && state.selectedAddress.toJS()
        })
    }

    useEffect(() => {
        if (addressUpdated === true) {
            handleCloseAddAddressDialog();
            props.closeAddressDialog();
            props.resetCreateAddress();
        }
    }, [addressUpdated])

    useEffect(() => {
        if (addressDeleted === true) {
            props.resetDeleteAddress();
            setState({ ...state, deleteAddressDialogIsOpen: false })
        }
    }, [addressDeleted])

    const isAnyAddressFound = () => {
        if (auth && auth?.get('user')?.get('home_address')?.get('id')) {
            return true
        }
        else if (auth && auth?.get('user')?.get('work_address')?.get('id')) {
            return true
        }
        else if (auth && auth?.get('user')?.get('other_addresses')?.size > 0) {
            return true
        }
        else
            return false
    }

    return (
        <>
            <ConfirmationDialog
                open={state.deleteAddressDialogIsOpen}
                handleClose={handleDeleteAddressDialogClose}
                successButtonText="Yes"
                deleteButtonText="No"
                onSuccess={deleteTheAddress}
                title={<b>Delete Address</b>}
                loading={execStatusDeleteAddress.status === "PENDING" ? true : false}>
                <div className="pb-3 text-center">
                    <div>
                        <span className='font-weight-bold'>
                            {`Are You Sure You Want To Delete ${state.selectedAddress && state.selectedAddress.get('name')} Address?`}
                        </span>
                        <div className='pt-3'>
                            <p>{execStatusDeleteAddress.status === "ERROR" ?
                                <div className="d-flex pt-3 text-danger justify-content-center w-100">
                                    {execStatusDeleteAddress?.errorDetail?.get('message')}
                                </div> : null}</p>
                        </div>
                    </div>
                </div>
            </ConfirmationDialog>
            <ContentDialog
                // fullScreen={true}
                setHeaderBackgroundAsPrimary={true}
                maxWidth='lg'
                handleClose={props.closeAddressDialog}
                open={isAddressDialogOpen}
                title={<Typography variant='h5' className="m-auto text-center text-white font-weight-bold">Address</Typography>}>
                <div className={clsx('w-100 d-flex h-100 pt-3 pb-3', mobileWidth && "flex-column")}>
                    <div className={clsx("d-flex flex-column align-items-center mr-3",
                        mobileWidth && "w-100", !mobileWidth && "w-50")}>
                        <div className={clsx("d-flex flex-column address-content paper-root align-items-center w-100")}>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={auth && auth.get("selectedAddressId")}
                                onChange={(event) => onAddressChange(event.target.value)}>
                                <div className="pl-3">
                                    {
                                        auth && auth?.get('user')?.get('home_address')?.get('id') ?
                                            <FormControlLabel
                                                value={auth?.get('user')?.get('home_address')?.get('id')}
                                                control={<Radio />}
                                                classes={{ root: classes.label, label: classes.label }}
                                                label={
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className={clsx(mobileWidth && "w-70", !mobileWidth && "w-80")}>
                                                            <Typography className="font-weight-bold text-dark">
                                                                <IconButton color="primary">
                                                                    <img
                                                                        src={`${process.env.PUBLIC_URL}/assets/icons/home_icon_primary.svg`}
                                                                        width='24px'
                                                                        height='24px'
                                                                    />
                                                                </IconButton>
                                                                Home</Typography>
                                                            <Typography className="d-flex pl-2 text-muted" variant="subtitle2">
                                                                {auth && getAddressDescription(auth?.get('user')?.get('home_address'))}
                                                            </Typography>
                                                        </div>
                                                        <div className={clsx("d-flex justify-content-center align-items-center pl-1", mobileWidth && "w-30", !mobileWidth && "w-20")}>
                                                            <IconButton color='primary' onClick={() => handleEditAddress(auth?.get('user')?.get('home_address'))}>
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/edit_icon_primary.svg`}
                                                                    width='24px'
                                                                    height='24px'
                                                                />
                                                            </IconButton>
                                                            <IconButton color='secondary' onClick={() => handleDeleteAddress(auth?.get('user')?.get('home_address'))}>
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/delete_icon_secondary.svg`}
                                                                    width='24px'
                                                                    height='24px'
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                }
                                            /> : null
                                    }
                                </div>
                                <div className="pl-3">
                                    {
                                        auth && auth?.get('user')?.get('work_address')?.get('id') ?
                                            <FormControlLabel
                                                value={auth?.get('user')?.get('work_address')?.get('id')}
                                                control={<Radio />}
                                                classes={{ root: classes.label, label: classes.label }}
                                                label={
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className={clsx(mobileWidth && "w-70", !mobileWidth && "w-80")}>
                                                            <Typography className="font-weight-bold text-dark">
                                                                <IconButton color='primary'>
                                                                    <img
                                                                        src={`${process.env.PUBLIC_URL}/assets/icons/work_icon_primary.svg`}
                                                                        width='24px'
                                                                        height='24px'
                                                                    />
                                                                </IconButton>
                                                                Work</Typography>
                                                            <Typography className="d-flex pl-2 text-muted" variant="subtitle2">
                                                                {auth && getAddressDescription(auth?.get('user')?.get('work_address'))}
                                                            </Typography>
                                                        </div>
                                                        <div className={clsx("d-flex justify-content-center align-items-center pl-1", mobileWidth && "w-30", !mobileWidth && "w-20")}>
                                                            <IconButton color='primary' onClick={() => handleEditAddress(auth?.get('user')?.get('work_address'))}>
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/edit_icon_primary.svg`}
                                                                    width='24px'
                                                                    height='24px'
                                                                />
                                                            </IconButton>
                                                            <IconButton color='secondary' onClick={() => handleDeleteAddress(auth?.get('user')?.get('work_address'))}>
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/delete_icon_secondary.svg`}
                                                                    width='24px'
                                                                    height='24px'
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                }
                                            /> : null
                                    }
                                </div>
                                {
                                    auth && auth?.get('user')?.get('other_addresses') && auth?.get('user')?.get('other_addresses').map((address, index) => {
                                        return <div className="pl-3"> <FormControlLabel
                                            value={address.get('id')}
                                            control={<Radio />}
                                            classes={{ root: classes.label, label: classes.label }}
                                            label={
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className={clsx(mobileWidth && "w-70", !mobileWidth && "w-80")}>
                                                        <Typography className="font-weight-bold text-dark">
                                                            <IconButton color='primary'>
                                                                <i className="material-icons ">cast</i>
                                                            </IconButton>
                                                            {address?.get('name')}</Typography>
                                                        <Typography className="d-flex pl-2 text-muted" variant="subtitle2">
                                                            {getAddressDescription(address)}
                                                        </Typography>
                                                    </div>
                                                    <div className={clsx("d-flex justify-content-center align-items-center pl-1", mobileWidth && "w-30", !mobileWidth && "w-20")}>
                                                        <IconButton color='primary' onClick={() => handleEditAddress(address)}>
                                                            <i className="material-icons">edit</i>
                                                        </IconButton>
                                                        <IconButton color='secondary' onClick={() => handleDeleteAddress(address)}>
                                                            <i className="material-icons">delete</i>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        </div>
                                    })
                                }
                            </RadioGroup>
                            {
                                isAnyAddressFound() ?
                                    null :
                                    <div className="p-5 text-danger">
                                        No Address Found
                                    </div>
                            }
                        </div>
                        {
                            auth && auth.get("selectedAddressId") ?
                                <div className="w-100 d-flex justify-content-end pt-3">
                                    <Button onClick={props.closeAddressDialog} variant="outlined" className="mr-3" color="secondary">
                                        Close
                                    </Button>
                                    <Button onClick={() => handleChange("addNewAddressDialogIsOpen", true)}
                                        color="primary">
                                        Add New Address
                                    </Button>
                                </div> :
                                <div className="w-100 d-flex justify-content-end pt-3 ">
                                    <Button onClick={props.closeAddressDialog} variant="outlined" className="mr-3" color="secondary">
                                        Go Back
                                    </Button>
                                    <Button onClick={() => handleChange("addNewAddressDialogIsOpen", true)}
                                        color="primary">
                                        Add New Address
                                    </Button>
                                </div>
                        }
                    </div>
                    <div className={clsx(mobileWidth && "w-100 pt-3", !mobileWidth && "w-50")}>
                        <AddressMap
                            {...state}
                            {...props}
                            handleChange={handleChange}
                            onAddressChange={onAddressChange}
                        />
                    </div>
                </div>
            </ContentDialog>
            {
                state.addNewAddressDialogIsOpen ?
                    <NewAddressMapDialog
                        execStatusCreateAddress={execStatusCreateAddress}
                        execStatusUpdateAddress={execStatusUpdateAddress}
                        handleClose={handleCloseAddAddressDialog}
                        handleChange={handleChange}
                        {...state} {...props} />
                    : null
            }

        </>
    )
}
export default AddressDialog