import React from 'react'
import { useStyle } from '../category.page.advertisment.panel2/category.page.advertisment.panel2.style';
import { CardMedia, Grid, Typography } from '@mui/material'
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx'
const CategoryPageAdvertismentPanel2 = () => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    return (
        <Grid classes={{ root: classes.CategoryPageAdvertismentPanel2 }} container spacing={2} className='w-90 m-auto rounded d-flex align-items-center'>
            <Grid item xs={7} sm={6} lg={7} className="h-100 w-100 d-flex align-items-center justify-content-center m-auto" >
                <div className=''>
                    <Typography variant={clsx(!mobileWidth && "h6", mobileWidth && "subtitle2")} component="div" className="text-ellipsis-clamp-1 font-weight-bold text-uppercase h-auto">
                        the zapp app
                    </Typography>
                    <Typography gutterBottom variant={clsx(!mobileWidth && "h3", mobileWidth && "h6")} component="div" className="text-ellipsis-clamp-3 font-weight-light mb-4">
                        Instantly <b>connect</b> with your favourite <b>brands</b>
                    </Typography>
                    <Typography variant="subtitle2" component="div" className="text-ellipsis-clamp-1 h-100 font-weight-bold text-uppercase">
                        Download Now!
                    </Typography>
                    <div className={clsx(!mobileWidth && 'd-flex w-50', mobileWidth && 'w-100 d-flex mb-2')}>
                        <div className='w-100'>
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/app-store-logo.png'}
                                alt="app store logo"
                                className='h-100 w-100'
                            />
                        </div>
                        <div className='w-100 ml-2'>
                            <img
                                src={process.env.PUBLIC_URL + '/assets/images/play-store-logo.png'}
                                alt="playstore logo"
                                className='h-100 w-100'
                            />
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={5} sm={6} lg={4} className={clsx(!mobileWidth && 'h-100 w-100 pt-5 pl-5 pr-5', mobileWidth && 'h-100 w-100 p-1 mt-n5')}>
                <CardMedia
                    className='h-100'
                    component="img"
                    image={process.env.PUBLIC_URL + '/assets/images/zapp-app-logo.PNG'}
                    alt="zapp-app-logo"
                />
            </Grid>
        </Grid>
    )
}
export default CategoryPageAdvertismentPanel2
