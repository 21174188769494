import React, { useEffect } from 'react';
import { createStateLink, useStateLink } from '@hookstate/core';

const CountDownTimer = (props) => {
    const StateLinkSecondsRemaing = createStateLink(props.secondsRemaining);
    const secondsRemaining = useStateLink(StateLinkSecondsRemaing);
    let timer = undefined;

    useEffect(() => {
        timer = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    const secondsToString = (seconds) => {
        var numdays = Math.floor(seconds / 86400);
        var numhours = Math.floor((seconds % 86400) / 3600);
        var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
        var numseconds = ((seconds % 86400) % 3600) % 60;
        if (numdays > 0) {
            return <div>
                {`${numdays} days ${numhours} hours ${numminutes} mins ${numseconds} secs`}
               
            </div>
        }
        else if (numhours > 0)
            return `${numhours} hours ${numminutes} mins ${numseconds} secs`;
        else if (numminutes > 0)
            return `${numminutes} mins ${numseconds} secs`
        else if (numseconds > 0)
            return `${numseconds} secs`;
        else
            return "";
    }
    const tick = () => {
        if (secondsRemaining.get() <= 0) {
            props.timeUp();
            clearInterval(timer);
        } else {
            secondsRemaining.set(seconds => seconds - 1);
        }
    }
    const remainingTime = secondsToString(secondsRemaining.get());
    return (
        <div className={props.className}>
            {remainingTime ? remainingTime : null}
        </div>
    )
}
export default CountDownTimer;
{/* <CountDownTimer
secondsRemaining={parseInt(120)}
timeUp={() => { }} /> */}