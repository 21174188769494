import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({

    header: {
        backgroundColor: theme.palette.primary.main,
        // borderBottomLeftRadius: '25px',
        // borderBottomRightRadius: '25px',
    },
    headerText: {
        color: theme.palette.background.paper,
    },
    textColor: {
        color: theme.palette.dark.main,
    },
    btn2: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    lightBorder: {
        border: `0.7px solid ${theme.palette.lightBackgroundColor.main}`
    },
    label: {
        width: "100%",
    },
}));