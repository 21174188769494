import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { useStyle } from '../add.voucher.dialog.style';
import SiteLabelTextField from "components/common/site.label.textfield/site.label.textfield";
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import DiscountDisplayBox from 'components/common/discount.display.box/discount.display.box'
import { format24HrDateTime } from "components/common/util/util";

const AddVoucherDialogStep1 = (props) => {
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const classes = useStyle()
    const { voucherCode, isValidVoucherCode, execStatusValidateVoucherStatus, userVouchers } = props
    return (
        <div className="h-100">
            <div className={clsx(!mobileWidth &&
                "d-flex align-items-center h-100 justify-content-center w-100 p-3 mr-5 ml-5 m-auto",
                mobileWidth && 'p-2 d-flex align-items-center h-100 w-100 m-auto')}>
                <div className="w-100 m-auto">
                    <div className="w-100 mt-1">
                        <SiteLabelTextField
                            onChange={(e) => props.onStateChange("voucherCode", e.target.value)}
                            placeholder="Enter voucher code if you have it"
                            maxLength="50"
                            validationType="ALPHANUMERIC"
                            value={voucherCode}
                            error={!isValidVoucherCode ? "Enter Voucher Code" : ""}
                        />
                    </div>
                    {
                        userVouchers && userVouchers.get('available_vouchers') && userVouchers.get('available_vouchers').size > 0 ?
                            <React.Fragment>
                                <div className="w-100 font-weight-bold text-primary d-flex justify-content-center mt-1">
                                    OR
                                </div>
                                <div className="w-100 mt-1">
                                    <Typography className="text-muted d-flex justify-content-center" classes={{ root: classes.text }} variant={clsx(!(mobileWidth || tabletWidth) && "subtitle", (mobileWidth || tabletWidth) && "body2")}>
                                        Select Zapp Voucher from Below
                                    </Typography>
                                </div>
                                <div>
                                    {
                                        userVouchers.get('available_vouchers').map((voucher, index) => {
                                            return <DiscountDisplayBox
                                                selected={voucher.get('code') == voucherCode ? true : false}
                                                onClick={(e) => props.onStateChange("voucherCode", voucher?.get('code'))}
                                                key={index}
                                                title={voucher?.get('name')}
                                                description={`Valid till ${format24HrDateTime(voucher?.get('expires_at'))}`} >
                                                {voucher?.get('code')}
                                            </DiscountDisplayBox>
                                        })
                                    }
                                </div>
                            </React.Fragment> : null
                    }
                    {/* <div className="w-100 mt-4">
                        <Typography classes={{ root: classes.text }} variant="h6">Zapp Vouchers</Typography>
                    </div>

                    <Typography classes={{ root: classes.text }} variant="subtitle1" className="w-100 mt-2">
                        Sorry, there aren't any vouchers to display, if you have any special
                        voucher please enter it in the textfield above.
                    </Typography> */}
                </div>
            </div>

        </div>
    )
};

export default AddVoucherDialogStep1;
