import React, { useState } from 'react'
import { Button, ButtonGroup, CircularProgress } from "@mui/material";
import QuantityExceedDialog from './quantity.exceeds.dialog';

const QuantityButtonCounter = (props) => {
    const { value, maxQuantity = 99 } = props;
    const [state, setState] = useState({
        showQuantityExceedsDialog: false
    })
    const handleCloseQuantiyExceedsDialog = (e) => {
        e && e.stopPropagation();
        e && e.preventDefault();
        setState({
            ...state,
            showQuantityExceedsDialog: false
        })
    }
    const openQuantiyExceedsDialog = () => {
        setState({
            ...state,
            showQuantityExceedsDialog: true
        })
    }
    //const [count, setCount] = useState(value);

    const handleIncrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAddingToCartInProgress() === false) {
            if (value < maxQuantity) {
                props.onQuantityChange(value + 1)
            }
            else {
                openQuantiyExceedsDialog();
            }
        }
    };
    const handleDecrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAddingToCartInProgress() === false) {
            if (value >= 1)
                props.onQuantityChange(value - 1)
        }
    };
    return (
        <div className="bg-white">
            <ButtonGroup size="medium" color="secondary" variant="outlined" aria-label="outlined button group">
                {
                    <Button color="secondary"
                        variant="contained" onClick={handleDecrement}>
                        -
                    </Button>}
                {<Button color="secondary" variant={props.isAddingToCartInProgress() === true ? "outlined" : "contained"} >
                    {props.isAddingToCartInProgress() === true ?
                        <CircularProgress color="primary" size={10} /> : value}
                </Button>}
                <Button color="secondary" variant='contained' onClick={handleIncrement}>+</Button>
            </ButtonGroup>
            {
                state.showQuantityExceedsDialog === true ?
                    <QuantityExceedDialog
                        open={state.showQuantityExceedsDialog}
                        handleClose={handleCloseQuantiyExceedsDialog}>
                        <div className="pb-3 font-weight-bold text-center">
                            <div>
                                <div className='pt-3'>
                                    Selected Product exceeds the total remaining units
                                </div>
                            </div>
                        </div>
                    </QuantityExceedDialog> : null
            }
        </div>
    )
}
export default QuantityButtonCounter