import { createSelector } from 'reselect';

const selectUser = (state) => {
    return state.user;
}
const makeSelectUserVouchers = () => createSelector(
    selectUser,
    (userState) => userState.get('userVouchers')
);
const resendOtpSelector = () => createSelector(
    selectUser,
    (userState) => userState.get('resendOtpData')
);

export {
    selectUser,
    resendOtpSelector,
    makeSelectUserVouchers
};
