// Async Indexed DB functions 

export function insertDataIntoIndexedDB(key, data) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore'], 'readwrite')
                .objectStore('siteIndexedDBStore')
                .add({ id: key, data });
            request.onsuccess = function (_event) {
                resolve({
                    insertedSuccessfully: true
                })
            };
            request.onerror = function (event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}

export function readDataFromIndexedDB(key) {
    if (window.siteIndexedDB) {
        let objectStore = window.siteIndexedDB.transaction(['siteIndexedDBStore']).objectStore('siteIndexedDBStore');
        return new Promise((resolve, _reject) => {
            let request = objectStore.get(key);
            request.onsuccess = function (_event) {
                if (request.result) {
                    resolve(request.result);
                    // console.log(request.result);
                } else {
                    // console.log('No data record');
                }
            };
            request.onerror = function (event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}
export function readAllDataFromIndexedDB() {
    if (window.siteIndexedDB) {
        let objectStore = window.siteIndexedDB.transaction(['siteIndexedDBStore']).objectStore('siteIndexedDBStore');
        return new Promise((resolve, _reject) => {
            objectStore.getAll().onsuccess = function (event) {
                resolve({
                    response: event.target.result
                })
            };
            objectStore.getAll().onerror = function (event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            };
        })
    }
}
export function deleteAllDataFromIndexedDb() {
    if (window.siteIndexedDB) {
        var DBOpenRequest = window.indexedDB.open("zapp-indexed-db", 1);
        return new Promise((resolve, _reject) => {
            DBOpenRequest.onsuccess = function (event) {
                let db = DBOpenRequest.result;
                var transaction = db.transaction(["siteIndexedDBStore"], "readwrite");
                let objectStore = transaction.objectStore('siteIndexedDBStore');
                transaction.oncomplete = function (_event) {

                }
                var objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = function (_event) {
                    resolve({
                        allDeletedSuccessfully: true
                    })
                };
                objectStoreRequest.onerror = function (_event) {
                    resolve({
                        error: {
                            message : "IndexedDb Operation failed"
                        }
                    })
                };
            };
            DBOpenRequest.onerror = function (event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}


export function deleteDataFromIndexedDB(key) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore'], 'readwrite')
                .objectStore('siteIndexedDBStore')
                .delete(key);
            request.onsuccess = function (_event) {
                resolve({
                    deletedSuccessfully: true
                })
                // console.log(key);
                // console.log('The data has been deleted successfully');
            };
            request.onerror = function (_event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            };
            // request.onerror = function (event) {
            //     error();
            //     // console.log('The data has been updated failed');
            // }
        })
    }
}

export function updateDataFromIndexedDB(key, data) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore'], 'readwrite')
                .objectStore('siteIndexedDBStore')
                .put({ id: key, data });
            request.onsuccess = function () {
                resolve({
                    updatedSuccessfully: true
                })
            };
            request.onerror = function (event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}

export function checkExistingIndexDBKey(key, _resolve, _reject) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore']).objectStore('siteIndexedDBStore').getKey(key);
            request.onsuccess = function (_event) {
                // console.log('Data Found');
                if (request.result) {
                    // console.log(request.result);
                    resolve({
                        isExist: true
                    });
                } else {
                    // console.log('Does not exist');
                    resolve({
                        isExist: false
                    });
                }
            };
            request.onerror = function (_event) {
                resolve({
                    error: {
                        message : "IndexedDb Operation failed"
                    }
                })
            };
        })
    }
}

export function initializeIndexedDb() {
    let request = window.indexedDB.open('zapp-indexed-db', 1);  // new database created with version 1 
    request.onsuccess = function (event) {
        // console.log('onsuccess');
        window.siteIndexedDB = event.target.result; // === request.result
    };
    request.onerror = function (_event) {
        // console.log('onerror');
        window.siteIndexedDB = null;
    };
    request.onupgradeneeded = function (event) {
        // console.log('onupgradeneeded');
        window.siteIndexedDB = event.target.result;
        let objectStore = window.siteIndexedDB.createObjectStore('siteIndexedDBStore', { keyPath: 'id' });
        objectStore.createIndex('id', '', { unique: false });
    };
}