import "./order.panel.scss";
import React from "react";
import { Grid, Typography } from "@mui/material";
import ShoppingCardItemCheckout from "components/common/shopping.card.item.checkout/shopping.card.item.checkout";

const OrderPanel = (props) => {
  const { discount, voucher, serviceFee } = props;
  return (
    <div className="p-3 border-outline" id="order-panel">
      <Grid container>
        <Typography variant="h6">Your Order</Typography>

        <span className="divider mt-3 mb-1"></span>

        <ShoppingCardItemCheckout
          name="Coffe Brown Sneakers x2"
          color="Black"
          size="Large"
          price="4,397.00"
          image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6"
        />
        <ShoppingCardItemCheckout
          name="White Sneakers x1"
          color="Black"
          size="Large"
          price="1,000.00"
          image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6"
        />

        <span className="divider mb-4"></span>

        <Grid container className="">
          <Grid className="d-flex justify-content-between mb-2 w-100">
            <Typography>Shipping</Typography>
            <Typography>Free</Typography>
          </Grid>
          <Grid className="d-flex justify-content-between mb-2 w-100">
            <Typography>Item(s) Subtotal</Typography>
            <Typography>Rs. 5,398.3</Typography>
          </Grid>
          {discount ? (
            <Grid className="d-flex justify-content-between mb-2 w-100">
              <Typography>Discount</Typography>
              <Typography>{discount}</Typography>
            </Grid>
          ) : (
            ""
          )}
          {voucher ? (
            <Grid className="d-flex justify-content-between mb-2 w-100">
              <Typography>Voucher</Typography>
              <Typography>{voucher}</Typography>
            </Grid>
          ) : (
            ""
          )}
          {serviceFee ? (
            <Grid className="d-flex justify-content-between mb-2 w-100">
              <Typography>Service Fee</Typography>
              <Typography>{serviceFee}</Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid className="d-flex justify-content-between w-100">
            <Typography className="fw-bold">Order Total</Typography>
            <Typography variant="h6">Rs. 5,398.3</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderPanel;
