import './sale.item.box.detail.scss';
import React from 'react'
import { Box, Typography, IconButton, CardContent, Rating, Stack, Button, ButtonGroup, Paper } from '@mui/material';
import { useStyle } from '../sale.item.box.detail/sale.item.box.detail.style';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx'
export const DetailSaleItemBox = (props) => {
    const classes = useStyle()
    const { tag, description, image, price, stars, brandName, oldPrice, width, height, showbuttons } = props
    const mobileWidth = IsMobileWidth()
    return (

        <div className="W-100 h-100" >
            <div className="w-100" style={{ height: "400px" }}
                classes={{
                    root: classes.card_background
                }}
                id="sale-item-box-detail"
            >
                <Box
                    classes={{
                        root: classes.card_background_image
                    }}
                    style={{
                        backgroundImage: image ? `url(${image})` : "",
                        backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "#D9D9D9"
                    }}

                    className="w-100 paper-root2 h-70"
                >
                    <div className="d-flex flex-column align-items-between justify-content-between h-100 w-100">
                        <div className={clsx(!mobileWidth && "d-flex justify-content-between p-3 pt-4 ", mobileWidth && "d-flex justify-content-between p-2 pt-3 ")}>
                            <Typography
                                classes={{
                                    root: classes.tagColorDetail
                                }}
                                variant="subtitle2" className={clsx(!mobileWidth && `tag-bg-color_detail p-1 pl-2 pr-2 text-uppercase font-weight-bold mt-1`, mobileWidth && "tag-size_detail p-1 text-uppercase mt-1")} >
                                {tag ? tag : "Sale 50%"}
                            </Typography>
                            <IconButton
                                classes={{
                                    root: classes.likeBtnDetail
                                }}
                                className={clsx(!mobileWidth && "material-icons paper-root2 border-radius-100 p-2", mobileWidth && "material-icons paper-root2 border-radius-100 p-1")}>
                                favorite_border
                            </IconButton>
                        </div>
                        {showbuttons ?
                            <Paper
                                classes={{
                                    root: classes.button_group
                                }}
                            >
                                <ButtonGroup
                                    className="position-absolute mt-n5 paper-root"
                                    aria-label="outlined button group"
                                    variant="text"
                                >
                                    <Button
                                        classes={{
                                            root: classes.buttonGroupStyle
                                        }}
                                        className="material-icons">
                                        shopping_basket
                                    </Button>
                                    <Button
                                        classes={{
                                            root: classes.buttonGroupStyle
                                        }}
                                        className="material-icons">
                                        attachment
                                    </Button>
                                    <Button
                                        classes={{
                                            root: classes.buttonGroupStyle
                                        }}
                                        className="material-icons">
                                        visibility
                                    </Button>
                                    <Button
                                        classes={{
                                            root: classes.buttonGroupStyle
                                        }}
                                        className="material-icons">
                                        import_export
                                    </Button>
                                </ButtonGroup>
                            </Paper>
                            :
                            null
                        }
                    </div>

                </Box >
                <CardContent className="h-30 p-0 pt-3">
                    <div className="p-0 h-100">
                        <div className="p-0">
                            <Stack p={0}>
                                <Rating name="half-rating-read" defaultValue={2.5} precision={stars} readOnly />
                            </Stack>
                        </div>
                        <div className="text-ellipsis-clamp-1 text-styling_detail font-weight-light text-uppercase p-1">
                            {brandName ? brandName : ""}
                        </div>
                        <Typography variant="body1" className="text-ellipsis-clamp-1 lead text-styling_detail text-capitalize pl-1">
                            {description ? description : ""}
                        </Typography>
                         <Typography variant="body1" className="text-ellipsis-clamp-1 text-styling_detail font-weight-bold text-uppercase p-1">
                            {price ? price : ""}
                            <Typography
                            classes={{
                                root:classes.pricingStyle
                            }}
                            variant="caption" className="p-1">
                                {oldPrice ? oldPrice : ""}
                            </Typography>
                        </Typography>
                    </div>
                </CardContent>
            </div>
        </div >
    )
}
export default DetailSaleItemBox