import './simple.navigation.box.carousel.scss';
import React from 'react'
import Slider from "react-slick";
import IconButton from '@mui/material/IconButton';
import SimpleNavigationBox from 'components/common/simple.navigation.box/simple.navigation.box';
import { IsMobileWidth } from 'components/common/util/util';
const SquareNavigationBoxCarousel = (props) => {
    const mobileWidth = IsMobileWidth()

    const { showArrows } = props
    function NextArrow(props) {
        const { className, style, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, marginTop: "-100px", marginRight: !mobileWidth ? "7%" : "-10%" }}
            >
                <IconButton className="paper-root bg-white" >
                    <i className="fa fa-arrow-right arrow-style"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}
                style={{ ...style, marginTop: "-100px", marginLeft:!mobileWidth? "87%":"90%" }}>
                <IconButton >
                    <i className='fa fa-arrow-left arrow-style'></i>
                </IconButton>
            </div>
        );
    }


    var settings = {
        arrows: showArrows == "true" ? true : showArrows == "false" ? false : false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        variableWidth: true,
        adaptiveHeight: true,
    }

    return (
        <div id="simple-navigation-box-carousel" className="w-100 p-1">

            <Slider className="w-100 p-3 m-auto" {...settings}>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox selected title="bag" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSewogE2G_xVRhdzcExXLpTo8ydkfzqu5ZAxywxbMc7KoqkHnZo-b6uRWKtazq1c4dVVLs&usqp=CAU" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="hats" image="https://freepngimg.com/thumb/hat/149789-hat-cowboy-png-free-photo.png" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="watch" image="https://4.imimg.com/data4/YS/GJ/NSDMERP-22991038/menwatches-500x500.png" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="shoes" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2i9FoKftkE12yc3-MVHDnFKmaFClZ5UwwbXhAsZZyN5W9f3enNX-8pWJwp-mcQeTlUwA&usqp=CAU" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="t-shirt" image="https://p.kindpng.com/picc/s/121-1213912_polo-shirt-png-free-download-fau-t-shirt.png" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="jeans" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvoZUGyn9JY0-txq6ipBSrnm30doqVTGBcNA&usqp=CAU" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="shirt" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPO8p3K7thVVe5dmQf3cBAlxHfY02Nk-3NOXJ9zQgyJRFkBW1hRn6OnojGdeGFye5tzKI&usqp=CAU" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="glasses" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_qwgwpGQ_8qjykQv1-EiiCkmV6D5sgeWq3VXOAlDxHeiXRN_MCd0bTJDn14axq-gzmB4&usqp=CAU" />
                    </div>
                </div>
                <div className="p-2">
                    <div className="boxwidth">
                        <SimpleNavigationBox title="wallet" image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE_eLjIORg2J6BtTE6v92p_uG4bcTuUZ38CbmDm1bkp4zA4GDx--gr1Z4A1gtfkTanKfQ&usqp=CAU" />
                    </div>
                </div>
            </Slider>
        </div>
    )
}
//TODO
export default SquareNavigationBoxCarousel
